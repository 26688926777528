import React from 'react';
import {Button, Form, Grid, Header, Message, Modal,} from 'semantic-ui-react';
import {connect} from 'react-redux';
import size from 'lodash.size';

import Card from 'components/ui/Card';
import cs from 'classnames';
import './styles.scss';
import {validatePromotionCode} from "libraries/api-v2/stripe-service";

const isCurrentSubscription = (planItem, currentPlan) => (
  planItem.interval === currentPlan.interval);

export class AvailablePlan extends React.Component {
  state = {
    isSubscribing: false,
    isConfirmOpen: false,
    promoCode: '',
    isPromoValidating: false,
    promotionCode: null,
  }

  closeConfirm = () => this.setState({
    isConfirmOpen: false,
    promotionCode: null,
    promoCode: '',
  });

  onOpenConfirm = (planItemName, planItemId) => {
    const {current, hasPaymentMethod} = this.props.subscription;
    if (size(current) && current.nickname.includes('Free trial') && !hasPaymentMethod && !planItemName.includes('Premium annually')) {
      this.onSubscribe(planItemName, planItemId);
    } else {
      this.setState({isConfirmOpen: true});
    }
  }

  handleInputChange = (e) => {
    this.setState({
      promoCode: e.target.value
    })
  }

  onSubscribe = (planItemName, planItemId) => {
    const {onChangeSubscription} = this.props;
    let couponId = '';
    if (this.state.promotionCode && this.state.promotionCode.status) {
      couponId = this.state.promotionCode.data.coupon.id
    }
    this.setState({isSubscribing: true},
      async () => {
        await onChangeSubscription(planItemName, planItemId, couponId);
        this.setState({isSubscribing: false, isConfirmOpen: false});
      });
  }

  validatePromotionCode = () => {
    this.setState({isPromoValidating: true},
      async () => {
        const promotion = await validatePromotionCode(this.state.promoCode);
        this.setState({
          promotionCode: promotion,
          isPromoValidating: false
        });
      }
    )
  }

  render() {
    const {planItem, subscription} = this.props;
    const {isSubscribing} = this.state;
    return (
      <>
        {
          this.state.isConfirmOpen && (
            <Modal open size="tiny" closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header
                content="CHANGE SUBSCRIPTION"
                style={{backgroundColor: '#3a5695', color: 'white'}}
              />
              <Modal.Content>
                <span className="Subscription__Confirm_Text">Are you sure you want to change your subscription to&nbsp;<b>{planItem.name}</b>?</span>
                <br/>
                {
                  planItem.name.includes('Premium annually') && subscription.hasPaymentMethod &&
                  <Form
                    success={this.state.promotionCode && this.state.promotionCode.status}
                    error={this.state.promotionCode && !this.state.promotionCode.status}>
                    <br />
                    <Form.Group>
                      <Form.Input
                        placeholder='Do you have a promo code? Enter code'
                        defaultValue={this.state.promoCode}
                        width={13}
                        onChange={this.handleInputChange}
                      />
                      <Form.Button
                        content='Apply'
                        width={2}
                        color="blue"
                        className="Subscription__Promo__Button"
                        loading={this.state.isPromoValidating}
                        disabled={this.state.isPromoValidating || !this.state.promoCode}
                        onClick={this.validatePromotionCode}
                      />
                    </Form.Group>
                    {
                      this.state.promotionCode &&
                      <Message
                        success={this.state.promotionCode && this.state.promotionCode.status}
                        error={this.state.promotionCode && !this.state.promotionCode.status}
                        content={
                          this.state.promotionCode && this.state.promotionCode.status ?
                            'Promo Code success. You will receive a 50% discount for your first year and will only be charged $60. Thank you for using SynkBooks.'
                            :'Invalid promo code. Please verify your code and try again.'
                        }
                      />
                    }
                  </Form>
                }
              </Modal.Content>
              <Modal.Actions>
                <Button
                  primary
                  content="Continue"
                  onClick={() => this.onSubscribe(planItem.name, planItem.plan_id)}
                  loading={this.state.isSubscribing}
                />
                <Button
                  content="Cancel"
                  onClick={this.closeConfirm}
                />
              </Modal.Actions>
            </Modal>
          )
        }
        <Card autoHide>
          <div className="Available-Plan">
            <Grid container columns="2">
              <Grid.Column computer="10" tablet="10" mobile="16">
                <Header className="Available-Plan__Subtitle" as="h4" content={planItem.name}/>
                <b>{`$${(planItem.amount / 100).toString()} per ${planItem.interval.toUpperCase()}`}</b>
                <ul className="Available-Plan__Benefits-List">
                  {
                    Object.values(planItem.benefits)
                      .map((benefit, idx) => (<li key={`benefits_${benefit}_${idx}`}>{benefit}</li>))
                  }
                </ul>
              </Grid.Column>
              <Grid.Column computer="6" tablet="6" mobile="16" className="Available-Plan__Action">
                <Button
                  className={cs({
                    'Available-Plan__Subscribe': !isCurrentSubscription(planItem, subscription.current) || subscription.cancelAtPeriodEnd,
                    'Available-Plan__Current': isCurrentSubscription(planItem, subscription.current) && !subscription.cancelAtPeriodEnd,
                  })}
                  onClick={() => this.onOpenConfirm(planItem.name, planItem.plan_id)}
                  loading={isSubscribing}
                >
                  {
                    isCurrentSubscription(planItem, subscription.current) && !subscription.cancelAtPeriodEnd ? 'Current' : 'Subscribe'
                  }
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({
                           subscription,
                         }) => ({
  subscription,
});

export default connect(mapStateToProps, null)(AvailablePlan);
