import uuidv4 from 'uuid/v4';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { ENDPOINT_V1 } from 'libraries/config';
import { getInstitutionByIdV2 } from 'libraries/api-v2/institution-service';
import { setupUserAccountV2, storePlaidItemV2 } from 'libraries/api-v2/customer-service';
import { updateAccountStatusV2, updateAccountStatusAsProfessionalV2 } from 'libraries/api-v2/accounts-service';
import { updateClassificationsV2 } from 'libraries/api-v2/transactions-service';

/** provisional mock data
 * Should be replaced when provided a working endpoint
 */
import mockedCategories from 'libraries/categories.json';

const apiName = ENDPOINT_V1;
const apiRequest = (method, url, params) =>
    API[method](apiName, url, {
        ...params,
        response: true,
    });

/** PUBLIC */

export const signup = (fullName, email, password, accountType) =>
    Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            'custom:account_type': accountType,
            'custom:fullName': fullName,
            'custom:onboardingDone': 'False',
            'custom:accountStatus': 'True',
            'custom:channelType': 'web',
        },
    });

export const sendConfirmEmail = email => Auth.resendSignUp(email);

export const sendPasswordRecoveryEmail = email => Auth.forgotPassword(email);

export const resetPassword = (usr, code, pwd) => Auth.forgotPasswordSubmit(usr, code, pwd);

// export const logout = () => Auth.signOut({ global: true });
export const logout = () => Auth.signOut();

export const confirmSignUp = (email, code) => Auth.confirmSignUp(email, code);

/** ONBOARDING */

export const setupUserAccount = setupUserAccountV2;

/** TRANSACTIONS */

export const updateCategories = updateClassificationsV2;

export const getTransactions = (username, token, startDate, endDate, offset = 0) => {
    const queryStringParameters = startDate && endDate ? { startDate, endDate, offset } : { offset };
    const myInit = { queryStringParameters };
    return apiRequest('get', '/transactions/get_transactions', myInit).then(res => res.data.transactions);
};

export const markAsSeen = (username, token, transactions) =>
    apiRequest('put', '/transactions/mark_as_seen', { body: { transactions } });

/** PLAID */

export const getInstitutionById = getInstitutionByIdV2;

export const storePlaidItem = storePlaidItemV2;

export const updateAccountStatus = updateAccountStatusV2;

export const updateAccountStatusAsProfessional = updateAccountStatusAsProfessionalV2;

/**
 Endpoint: https://z9u7ryc3z6.execute-api.us-east-1.amazonaws.com/sandbox/plaid/force_login
 Note:     Not used in any feature. Used to test invalidation of plaid accounts.
 Use:      Body: { "item_id": "XXXXXX" }
           Corresponding to a bank item (bank account). This will require that the user logs in
           again in this account in order to revalidate it and receive new transactions for
           that account.
*/
/** DATA */
export const exportData = (username, token, transaction_ids = []) =>
    apiRequest('post', '/data_manipulation/export_data', { body: { transaction_ids } });

export const exportActivityLog = () => apiRequest('get', '/data_manipulation/export_audit_trail');

export const exportPNLTransactions = (username, token, type, transactions) =>
    apiRequest('post', '/data_manipulation/export_pnl_transactions', {
        body: {
            transactions_type: type,
            transaction_ids: transactions,
        },
    });

export const exportPnlPdf = (username, password, startDate, endDate) =>
    apiRequest('post', '/data_manipulation/export_profit_and_loss', {
        body: {
            start_date: startDate,
            end_date: endDate,
        },
    }).then(res => res.data);

export const getCategories = formName => Promise.resolve(mockedCategories[formName] || []);




