import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Segment, Image } from 'semantic-ui-react';
import { TaxInput, TaxDropDown } from 'taxUI';
import BusinessTypeSelector from 'components/ui/BusinessTypeSelector';

import { setUserProfile } from 'store/actions/session';
import { updateProfile, getAvatarUploadUrl, deleteAvatar } from 'libraries/api-v2/user-service';
import SchedulesDescriptions from '../../../OnboardingReportForm/descriptions';
import './styles.scss';

class ProfileDetailsEdit extends Component {
  state = {
    fullName: this.props.fullName,
    email: this.props.email,
    details: this.props.businessDetails,
    form: this.props.form,
    forms: [],
    businessType: this.props.businessType,
    businessCode: this.props.businessCode,
    imageFile: null,
    avatar: this.props.avatar
  };

  constructor (props) {
    super(props);
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const forms = SchedulesDescriptions.map(({ id, title }) => ({
      key: `${title}-${id}`,
      text: title,
      value: title,
    }));
    this.setState({ forms });
  }

  handleInputChange = (event, { name, value }) => this.setState({ [name]: value });

  onBusinessTypeChange = ({ name: businessType, code: businessCode }) => {
    this.setState({ businessType, businessCode });
  };

  onSaveProfile = async () => {
    const { username, token, onSuccess } = this.props;
    const {
      fullName, email, form, businessType, businessCode, details, avatar, imageFile
    } = this.state;

    let avatarUrl = null;
    if (avatar) {
      const data = await getAvatarUploadUrl( {type: imageFile.type });

      const result = await window.fetch(data.uploadUrl, {
        method: 'PUT',
        headers: {
          "Content-Type": imageFile.type,
        },
        body: imageFile
      })
      console.log({result});

      if (result.status === 200) {
        this.setState({ avatar: data.avatar });
        avatarUrl = data.avatar;
      }
    } else {
      const response = await deleteAvatar();
      console.log({ response });
      this.setState({ avatar: null });
    }

    const data = {
      fullName,
      selectedForm: form,
      businessCode,
      businessDetails: details,
      avatar: avatarUrl
    };
    await updateProfile(data);
    const prevState = {
      fullName,
      email,
      form,
      businessType,
      businessCode,
      businessDetails: details,
      avatar
    };
    this.props.setUserProfile(prevState);
    onSuccess && onSuccess(prevState);
    
  };

  handleSelectImage = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    this.setState({
      imageFile: file,
      avatar: url
    });
  };

  render() {
    const { onCancel, imgName } = this.props;
    const {
      fullName, email, details, form, forms, businessType, avatar
    } = this.state;
    const isDisabled = !fullName || !email || !form || !businessType;
    return (
      <div className="ProfileDetailsEdit margin">
        <Segment
          circular
          className="ProfileDetailsReadOnly-ImgName"
          textAlign="center"
          onClick={() => this.imageRef.current.click()}
        >
          {avatar ? <Image src={avatar} size='tiny' circular /> : 
          <div>{imgName}</div>
        }
        </Segment>
        <input
          type="file"
          accept="image/*"
          hidden
          ref={this.imageRef}
          onChange={this.handleSelectImage}
            />
        <br />
        <div className="ProfileDetailsEdit__accountType">Account type</div>
        <div>Taxpayer account</div>
        <br />
        <TaxInput
          fluid
          name="fullName"
          label="Full Name"
          placeholder="Full Name"
          value={fullName}
          onChange={this.handleInputChange}
          required
        />
        <TaxInput
          fluid
          name="email"
          label="Email"
          placeholder="Email"
          disabled
          value={email}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <h6>Business information</h6>
        <TaxDropDown
          fluid
          name="form"
          onChange={this.handleInputChange}
          value={form}
          disabled
          label="Form"
          placeholder="Form"
          options={forms}
          selection
          scrolling
          deburr
        />
        <BusinessTypeSelector
          form={form}
          value={businessType}
          label="Business Type"
          onChangeValue={this.onBusinessTypeChange}
        />
        <TaxInput
          fluid
          name="details"
          label="Add details"
          placeholder="Add details"
          value={details}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <div style={{ textAlign: 'center' }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={this.onSaveProfile} primary disabled={isDisabled}>
            Save Changes
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session: { token, username } }) => ({
  token,
  username,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setUserProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDetailsEdit);
