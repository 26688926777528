import React, { Component } from 'react';
import {
  Grid, Button, Container,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Routes from 'routes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.scss';
import { setBusinessType, setBusinessTypeDetails } from 'store/actions/onboarding';
import { TaxInput } from 'taxUI';
import BusinessTypeSelector from 'components/ui/BusinessTypeSelector';

export class OnboardingBusinessType extends Component {
  state = {
    value: this.props.businessType,
  };

  timeout = null;

  onBusinessTypeChange = (businessTypeObj) => {
    this.setState({ value: businessTypeObj.name }, () => {
      this.props.setBusinessType(businessTypeObj);
    });
  }

  handleInputChange = (event, { value }) => this.props.setBusinessTypeDetails(value);

  render() {
    const { value } = this.state;
    const { businessType, businessDetails, selectedForm } = this.props;
    const isDisabled = businessType === '';
    return (
      <Grid container className="BusinessType">
        <Grid.Row centered>
          <Container className="BusinessType__Text">
            <p>
              Select your type of business so you can receive
              a personalized experience
            </p>
          </Container>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div style={{ maxWidth: 500, margin: '0px auto' }}>
              <BusinessTypeSelector form={selectedForm} value={value} label="Search Business Type" onChangeValue={this.onBusinessTypeChange} />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div style={{ maxWidth: 500, margin: '0px auto' }}>
              <TaxInput
                className="BusinessType__Details-Input"
                label="Add details"
                placeholder="Add details"
                value={businessDetails}
                onChange={this.handleInputChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2} className="BusinessType__Buttons">
          <Grid.Column textAlign="right">
            <Button
              as={Link}
              to={Routes.OnboardingReportForm}
              className="BusinessType__Buttons-Back"
            >
            GO BACK
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button
              as={Link}
              to={Routes.OnboardingLinkPlaid}
              primary
              disabled={isDisabled}
              className="BusinessType__Button-Continue"
            >
            CONTINUE
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { token, username },
  onboarding: { selectedForm, businessType, businessDetails },
}) => ({
  token,
  username,
  selectedForm,
  businessType,
  businessDetails,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setBusinessType,
  setBusinessTypeDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingBusinessType);
