import {
  ADD_SELECTED_CLIENT_TRANSACTION,
  REMOVE_SELECTED_CLIENT_TRANSACTION,
  RESET_SELECTED_CLIENT_TRANSACTIONS,
  ADD_SELECTED_CLIENT_PAGE,
  REMOVE_SELECTED_CLIENT_PAGE,
  RESET_SELECTED_CLIENT_PAGES,
  SET_CLIENT_TRANSACTIONS,
  SET_CLIENT_TRANSACTION_IDS,
  CLEAR_CLIENT_TRANSACTIONS,
  MARK_AS_SEEN_CLIENT_TRANSACTIONS,
  UPDATE_CLIENT_TRANSACTIONS,
  UPDATE_DUPLICATE_STATUS_TRANSACTIONS
} from '../ActionTypes';

export const addSelectedTransaction = id => ({
  type: ADD_SELECTED_CLIENT_TRANSACTION,
  payload: id,
});

export const removeSelectedTransaction = id => ({
  type: REMOVE_SELECTED_CLIENT_TRANSACTION,
  payload: id,
});

export const resetSelectedTransactions = () => ({
  type: RESET_SELECTED_CLIENT_TRANSACTIONS,
});

export const addSelectedPage = (page, ids) => ({
  type: ADD_SELECTED_CLIENT_PAGE,
  payload: {
    page,
    ids,
  },
});

export const removeSelectedPage = (page, ids) => ({
  type: REMOVE_SELECTED_CLIENT_PAGE,
  payload: {
    page,
    ids,
  },
});

export const resetSelectedPages = () => ({
  type: RESET_SELECTED_CLIENT_PAGES,
});

export const setTransactions = transactions => ({
  type: SET_CLIENT_TRANSACTIONS,
  payload: transactions,
});

export const setTransactionIds = transactionIds => ({
  type: SET_CLIENT_TRANSACTION_IDS,
  payload: transactionIds,
});

export const updateTransactions = transactions => ({
  type: UPDATE_CLIENT_TRANSACTIONS,
  payload: {
    newTransactions: transactions,
  },
});

export const clearTransactions = () => ({
  type: CLEAR_CLIENT_TRANSACTIONS,
});

export const markAsSeenTransactions = selected => ({
  type: MARK_AS_SEEN_CLIENT_TRANSACTIONS,
  payload: {
    transactions: selected,
  },
});

export const updateDuplicateStatusTransactions = (selected, duplicateStatus) => ({
  type: UPDATE_DUPLICATE_STATUS_TRANSACTIONS,
  payload: {
    transactions: selected,
    duplicateStatus
  },
});
