import camelcaseKeys from 'camelcase-keys';
import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

const createCustomer = createCustomerDto => apiRequestV2('post', `${basePath}/customers`, {
  body: createCustomerDto,
}).then(res => camelcaseKeys(res.data));

const createProfessionalCustomer = createProfessionalCustomerDto => apiRequestV2('post', `${basePath}/customers/create-professional`, {
  body: createProfessionalCustomerDto,
}).then(res => camelcaseKeys(res.data));

const addInstitution = institutionLinkingInfo => apiRequestV2('post', `${basePath}/institutions`, {
  body: institutionLinkingInfo,
}).then(res => camelcaseKeys(res.data));

const getHistoricalSyncStatus = () => apiRequestV2('get', `${basePath}/institutions/historical-sync-status`)
  .then(res => camelcaseKeys(res.data));

const getHistoricalSyncStatusAsProfessional = clientEmail => apiRequestV2('post', `${basePath}/institutions/historical-sync-status/as-professional`, {
  body: {
    clientEmail
  },
}).then(res => camelcaseKeys(res.data));

const startHistoricalSyncProcess = (itemId = null) => apiRequestV2('post', `${basePath}/institutions/historical-sync`, {
  body: {
    itemId
  }
})
  .then(res => camelcaseKeys(res.data));

// Test API
const triggerHistoricalSyncProcess = email => apiRequestV2('post', `${basePath}/institutions/trigger-sync`, {
  body: {
    email
  }
}).then(res => camelcaseKeys(res.data));

const startHistoricalSyncProcessAsProfessional = (clientEmail, itemId = null) => apiRequestV2('post', `${basePath}/institutions/historical-sync/as-professional`, {
  body: {
    clientEmail, 
    itemId
  },
})
.then(res => camelcaseKeys(res.data));

const getProfessionalProfile = () => apiRequestV2('get', `${basePath}/customers/get-professional-profile`)
  .then(res => camelcaseKeys(res.data));

/**
 * V2 wrapper for backwards compatibility
 */
const setupUserAccountV2 = (
  publicTokens,
  taxForm,
  businessTypeCode,
  businessTypeDetails,
  planId,
  bookStartDate
) => {
  const createCustomerDto = {
    public_tokens: publicTokens,
    customer_profile: {
      tax_form_name: taxForm,
      business_type_code: String(businessTypeCode),
      business_type_details: businessTypeDetails,
      onboarding_plan_id: planId,
    },
    bookkeeping_start_date: bookStartDate
  };
  return createCustomer(createCustomerDto);
};

/**
 * V2 wrapper for backwards compatibility
 */
const storePlaidItemV2 = (publicToken, accounts, bookStartDate) => {
  const institutionLinkingInfo = {
    public_access_token: publicToken,
    accounts,
    bookStartDate
  };

  // V1 expects a stored_item wrapped in a data object
  return addInstitution(institutionLinkingInfo).then(institution => ({
    data: {
      ...institution,
      stored_item: institution.plaidItemId,
    },
  }));
};

export {
  setupUserAccountV2,
  storePlaidItemV2,
  getHistoricalSyncStatus as getHistoricalSyncStatusV2,
  getHistoricalSyncStatusAsProfessional,
  startHistoricalSyncProcess,
  startHistoricalSyncProcessAsProfessional,
  createProfessionalCustomer,
  getProfessionalProfile,
  triggerHistoricalSyncProcess
};
