import React from "react";
import { Icon, Input } from "semantic-ui-react";
import "./styles.scss";
import CurrencyInput from "react-currency-input-field";
import SelectTax from "components/ui/SelectAction/SelectTax";

class LineItem extends React.Component {
  handleChange = (type, value) => {
    if (type != "description" && (value == "" || value == undefined)) value = 0;
    let description = this.props.item.description;
    let quantity = this.props.item.quantity;
    let unitPrice = this.props.item.unitPrice;
    let amount = this.props.item.amount;
    if (type == "description") description = value;
    if (type == "quantity") {
      quantity = `${parseInt(value)}`;
      amount = `${Number(quantity) * Number(unitPrice)}`;
    }
    if (type == "unitPrice") {
      unitPrice = value;
      amount = `${Number(quantity) * Number(unitPrice)}`;
    }
    if (type == "amount") amount = Number(value);
    this.props.setItem(description, quantity, unitPrice, amount, this.props.index);
  };

  render() {
    const {
      item,
      selected,
      selectRow,
      invoiceTemplate,
      error,
      taxRateData,
      onTax
    } = this.props;

    return (
      <div>
        <table className="Create--table" onClick={selectRow}>
          <tbody
            style={{
              borderLeft: selected ? "5px solid steelblue" : ""
            }}
          >
            <tr style={{ height: "40px" }}>
              <td
                style={{
                  width: invoiceTemplate == 1 ? "50%" : "70%",
                  textAlign: "left",
                  height: "40px"
                }}
              >
                <Input
                  fluid
                  className="table--input"
                  placeholder="Item name"
                  value={item.description}
                  onChange={e =>
                    this.handleChange("description", e.target.value)
                  }
                />
              </td>
              {invoiceTemplate == 1 ? (
                <>
                  <td style={{ width: "10%", height: "40px" }}>
                    <Input
                      fluid
                      type="number"
                      className="table--input--right"
                      placeholder="0"
                      value={item.quantity}
                      onChange={e =>
                        this.handleChange("quantity", e.target.value)
                      }
                    />
                  </td>
                  <td style={{ width: "10%", height: "40px" }}>
                    <CurrencyInput
                      placeholder="0"
                      value={item.unitPrice}
                      decimalsLimit={2}
                      onValueChange={value =>
                        this.handleChange("unitPrice", value)
                      }
                      className="currency-input"
                    />
                  </td>
                </>
              ) : (
                <td
                  style={{
                    width: "15%",
                    fontStyle: "bold",
                    height: "40px",
                    textAlign: "right"
                  }}
                >
                  <CurrencyInput
                    prefix="$ "
                    placeholder="0.00"
                    value={item.amount}
                    decimalsLimit={2}
                    onValueChange={value => this.handleChange("amount", value)}
                    className="currency-input"
                  />
                </td>
              )}
              <td style={{ width: "15%", height: "40%" }}>
                <SelectTax
                  taxRateData={taxRateData}
                  taxRates={item.taxRates}
                  onTax={onTax}
                />
              </td>
              {invoiceTemplate == 1 && (
                <td
                  style={{
                    width: "15%",
                    fontStyle: "bold",
                    height: "40px",
                    textAlign: "right"
                  }}
                >
                  <CurrencyInput
                    prefix="$ "
                    placeholder="0.00"
                    value={item.amount}
                    decimalsLimit={2}
                    onValueChange={value => this.handleChange("amount", value)}
                    className="currency-input"
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <div>
          <div
            style={{
              padding: "5px 10px",
              float: "left",
              color: "red",
              display: error ? "block" : "none"
            }}
          >
            <Icon name="info circle" /> Please input the line items.
          </div>
        </div>
      </div>
    );
  }
}

export default LineItem;
