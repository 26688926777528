import React from "react";
import {
  Segment,
  Grid,
  Divider,
  Table,
  Loader,
  Header,
} from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import { currencyFormat } from "libraries/utils";

const Preview = (props) => {
  const { invoice, selectedData, taxRates, isLoading } = props;
  const type =
    invoice.invoiceItems &&
    invoice.invoiceItems.length > 0 &&
    Number(invoice.invoiceItems[0].unit_price) != 0;

  const getSubtotal = () => {
    if (invoice.invoiceItems) {
      let sum = 0;
      invoice.invoiceItems.forEach((item) => {
        if (type) sum += item.unit_price * item.quantity;
        else sum += item.amount;
      });
      return sum;
    } else return 0;
  };
  const getTotal = () => {
    return invoice.amount_due;
  };

  const getItemTax = (itemTaxRates) => {
    let sum = 0;
    if (itemTaxRates && itemTaxRates.length > 0 && taxRates) {
      itemTaxRates.forEach(tax_id => {
        const rate = taxRates.find(el => el.tax_rate_id == tax_id);
        if(rate != undefined)
          sum += rate.percentage;
      });
    }
    return sum;
  }
  // const getDiscount = () => {
  //   return getSubtotal() * invoice.discount / 100;
  // }

  const getTax = () => {
    let tax = 0;
    if (taxRates && taxRates.length > 0 && invoice.invoiceItems.length > 0)
    invoice.invoiceItems.forEach((item) => {
        let taxRateData = taxRates.filter(
          (el) => item.taxRates.indexOf(el.tax_rate_id) > -1
        );
        tax +=
          ((item.amount - item.amount * getDiscountRate()) *
            (taxRateData.length > 0 ? taxRateData[0].percentage : 0)) /
          100.0;
      });
    return tax;
  };
  const getDiscountRate = () => {
    let rate = 0;
    if (invoice.discount_type == 'percent') {
      rate = invoice.discount;
    } else if (invoice.discount_type == 'amount')
      rate = (invoice.discount / getSubtotal()) * 100;
    return rate / 100;
  };

  const getDiscount = () => {
    let discount = 0;

    if (invoice.invoiceItems.length > 0) {
      invoice.invoiceItems.forEach((item) => {
        discount += item.amount * getDiscountRate();
      });
    }
    return discount;
  };

  return (
    <Segment style={{ padding: "50px" }}>
      {isLoading ? (
        <Grid style={{ textAlign: "center" }}>
          <Loader
            active
            size="large"
            inline
            content={<Header icon>Loading invoice detail...</Header>}
            style={{ margin: "auto" }}
          />
        </Grid>
      ) : (
        <>
          <Grid className="Invoice--manage" widths="equal">
            <Grid.Column floated="left" width={8}>
              <p className="Invoice--title">{invoice.sender_name}</p>
              <p style={{ margin: "0px" }}>{invoice.sender_phone}</p>
              <p style={{ margin: "0px" }}>{invoice.sender_email}</p>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Column width={16}>
                  <p style={{ float: "right", fontSize: "27px" }}>Invoice</p>
                </Grid.Column>
              </Grid>
              <Grid>
                <Grid.Column width={16}>
                  <Grid style={{ float: "right" }}>
                    <table className="Detail-table1">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "left" }}>Invoice Number</td>
                          <td style={{ textAlign: "right" }}>
                            {selectedData.invoice}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>Date of issue</td>
                          <td style={{ textAlign: "right" }}>
                            {selectedData.date}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>Date due</td>
                          <td style={{ textAlign: "right" }}>
                            {moment(
                              new Date(
                                invoice.created_at +
                                  invoice.due_date_num * (1000 * 3600 * 24)
                              )
                            ).format("MMMM DD, YYYY")}
                          </td>
                        </tr>
                        { selectedData.internal_number !== "" && 
                          <tr>
                          <td style={{ textAlign: "left" }}>Internal Invoice Number</td>
                          <td style={{ textAlign: "right" }}>
                            {selectedData.internal_number}
                          </td>
                        </tr>}
                      </tbody>
                    </table>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column>
              <h3 style={{ margin: "0px" }}>Bill to</h3>
              <p style={{ margin: "0px" }}>{invoice.receiver_name}</p>
              <p style={{ margin: "0px" }}>{invoice.receiver_phone}</p>
              <p style={{ margin: "0px" }}>{invoice.receiver_email}</p>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column>
              <h3 style={{ margin: "0px" }}>Address</h3>
              <p style={{ margin: "0px" }}>{invoice.receiver_address}</p>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              <p style={{ fontSize: "25px", margin: "0px" }}>
                $ {getTotal()} due{" "}
                {moment(new Date(invoice.created_at)).format("MMMM DD, YYYY")}
              </p>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              <Table basic="very" striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      collapsing
                      style={{
                        width: "40%",
                        color: "black",
                      }}
                    >
                      Description
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "10%", color: "black" }}>
                      {type && "Qty"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "15%", color: "black" }}>
                      {type && "Unit Price"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "15%", color: "black" }}>
                      {getTax() != 0 && "Tax"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "20%", color: "black" }}>
                      Amount
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {invoice.invoiceItems &&
                    invoice.invoiceItems.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{item.description}</Table.Cell>
                        <Table.Cell>{type && item.quantity}</Table.Cell>
                        <Table.Cell>
                          {type && "$" + currencyFormat(item.unit_price)}
                        </Table.Cell>
                        <Table.Cell>
                          {getItemTax(item.taxRates) != 0 && getItemTax(item.taxRates)+'%'}
                        </Table.Cell>
                        <Table.Cell>
                          $
                          {type
                            ? currencyFormat(item.quantity * item.unit_price)
                            : currencyFormat(item.amount)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  <Table.Row>
                    <Table.Cell colSpan="3" style={{ background: "white" }} />
                    <Table.Cell>Subtotal</Table.Cell>
                    <Table.Cell>${currencyFormat(getSubtotal())}</Table.Cell>
                  </Table.Row>
                  {
                  getTax() != 0 && <Table.Row>
                    <Table.Cell
                      colSpan="3"
                      style={{ border: "1px solid white", background: "white" }}
                    />
                      <Table.Cell>Tax</Table.Cell>
                      <Table.Cell>${currencyFormat(getTax())}</Table.Cell>
                    </Table.Row>
                  }
                  {
                  invoice.discount != 0 && <Table.Row>
                    <Table.Cell
                      colSpan="3"
                      style={{ border: "1px solid white", background: "white" }}
                    />
                      <Table.Cell>Discount ({invoice.discount_type == 'percent' ? invoice.discount + '%' : '$'+invoice.discount})</Table.Cell>
                      <Table.Cell>-${currencyFormat(getDiscount())}</Table.Cell>
                    </Table.Row>
                  }
                  <Table.Row>
                    <Table.Cell
                      colSpan="3"
                      style={{ border: "1px solid white", background: "white" }}
                    />
                    <Table.Cell>Total</Table.Cell>
                    <Table.Cell>${currencyFormat(getTotal())}</Table.Cell>
                  </Table.Row>
                  
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
          <Grid stackable columns={2}>
            <Grid.Column className="word-wrap">{invoice.memo}</Grid.Column>
            <Grid.Column className="word-wrap">{invoice.footer}</Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              <Divider className="Invoice--divider" />
            </Grid.Column>
          </Grid>
        </>
      )}
    </Segment>
  );
};

export default Preview;
