const Root = '/';
const DashboardRoot = `${Root}dashboard`;
const ClientDashboardRoot = `${Root}client-dashboard`;

const Routes = {
  Index: `${Root}`,
  TermsOfService: `${Root}terms-of-service`,
  PrivacyPolicy: `${Root}privacy-policy`,
  Login: `${Root}login`,
  Signup: `${Root}signup`,
  MyProfile: `${Root}my-profile`,
  ContactUs: `${Root}contact-us`,
  ConfirmNow: `${Root}confirm-email`,
  PasswordRecovery: `${Root}password-recovery`,
  PasswordRecoveryPinCode: `${Root}password-recovery/pincode`,
  PasswordRecoveryReset: `${Root}password-recovery/reset-password`,
  Onboarding: `${Root}onboarding`,
  OnboardingReportForm: `${Root}onboarding/report-form`,
  OnboardingBusinessType: `${Root}onboarding/business-type`,
  OnboardingLinkPlaid: `${Root}onboarding/plaid-link`,
  OnboardingSync: `${Root}onboarding/plaid-sync`,
  Subscription: `${Root}subscription`,

  ClientDashboard: `${ClientDashboardRoot}`,
  ClientDashboardClients: `${ClientDashboardRoot}/clients`,
  ClientDashboardTransactions: `${ClientDashboardRoot}/transactions`,
  ClientDashboardReports: `${ClientDashboardRoot}/reports`,
  ClientDashboardReportsProfitAndLoss: `${ClientDashboardRoot}/reports/profit-and-loss`,
  ClientDashboardReportsBalance: `${ClientDashboardRoot}/reports/balance`,
  ClientDashboardInvoicing: `${ClientDashboardRoot}/invoicing`,
  ClientDashboardInvoicingCreate: `${ClientDashboardRoot}/invoicing/create`,
  ClientDashboardInvoicingDetail: `${ClientDashboardRoot}/invoicing/detail/:id`,
  ClientDashboardCustomDashboard: `${ClientDashboardRoot}/reports/custom_dashboard`,
  ClientDashboardCustomProfitAndLoss: `${ClientDashboardRoot}/reports/custom_profit-and-loss`,

  Dashboard: `${DashboardRoot}`,
  DashboardClients: `${DashboardRoot}/clients`,
  DashboardClientTransactions: `${DashboardRoot}/client-transactions`,
  DashboardClientReports: `${DashboardRoot}/client-reports`,
  DashboardClientReportsProfitAndLoss: `${DashboardRoot}/client-reports/profit-and-loss`,
  DashboardTransactions: `${DashboardRoot}/transactions`,
  DashboardReports: `${DashboardRoot}/reports`,
  ReportsProfitAndLoss: `${DashboardRoot}/reports/profit-and-loss`,
  DashboardReportsBalance: `${DashboardRoot}/reports/balance`,
  DashboardReportsBudgeting: `${DashboardRoot}/reports/budgeting`,
  DashboardReportsBalanceSheet: `${DashboardRoot}/reports/balance_sheet`,
  DashboardCustomDashboard: `${DashboardRoot}/reports/custom_dashboard`,
  DashboardCustomProfitAndLoss: `${DashboardRoot}/reports/custom_profit-and-loss`,
  DashboardInvoicing: `${DashboardRoot}/invoicing`,
  DashboardInvoicingCreate: `${DashboardRoot}/invoicing/create`,
  DashboardInvoicingDetail: `${DashboardRoot}/invoicing/detail/:id`,
};

export default Routes;
