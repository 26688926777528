import React from 'react';
import { Grid, Form } from 'semantic-ui-react';
import { TaxDropDown, TaxFormInput } from 'taxUI';

const getItemString = number => (
  number > 1 ? `Select a new category for the ${number} items chosen` : 'Select a new category for the item chosen'
);

const EditCategoryTranSection = props => (
  <Grid.Row>
    <Grid.Column width={2} />
    <Grid.Column width={12}>
      <Form.Field>
        { (props.showLeyend === true) && (
          <center>
            { getItemString(props.totalTransactionSelected) }
          </center>
        )}
        { (props.showLeyend === false) && (
          <TaxFormInput
            fluid
            id="transactionName"
            readOnly={props.editableName}
            name="name"
            type="text"
            placeholder="Transaction Name"
            label="Transaction Name"
            value={props.name}
            onChange={props.handleInputChange}
            required
          />
        )}
      </Form.Field>
      <Form.Field>
        {
          props.categories && (
            <TaxDropDown
              fluid
              id="transactionCategory"
              onChange={props.handleInputChange}
              value={props.category}
              placeholder="Category"
              name="category"
              label="Category"
              selectOnBlur={false}
              options={props.categories}
              search
              selection
              scrolling
              deburr
              required
              loading={props.isLoadingCategories}
            />
          )
        }
      </Form.Field>
      <Form.Field>
        <TaxDropDown
          fluid
          id="transactionSubcategory"
          onChange={props.handleInputChange}
          value={[props.subcategory]}
          placeholder="Select or Add subcatory"
          name="subcategory"
          label="Subcategory"
          additionLabel={<i style={{fontWeight: 'bold'}}>Add: </i>}
          allowAdditions
          onAddItem={props.handleAddSubcategory}
          selectOnBlur={false}
          options={props.subcategories}
          search
          multiple
          selection
          scrolling
          required
        />
          
      </Form.Field>
      <Form.Field>
        {
          props.categories && (
            <TaxDropDown
              fluid
              id="transactionTags"
              onChange={props.handleInputChange}
              value={props.selectedTags}
              placeholder="Select or Add tags"
              name="selectedTags"
              label="Tag"
              additionLabel={<i style={{fontWeight: 'bold'}}>Add: </i>}
              allowAdditions
              onAddItem={props.handleAddition}
              selectOnBlur={false}
              options={props.tags}
              search
              multiple
              selection
              scrolling
              loading={props.isLoadingTags}
              required
            />
          )
        }
      </Form.Field>
      <Form.Field>
        <TaxFormInput
          fluid
          id="transactionMemo"
          name="memo"
          label="Memo"
          placeholder="Memo"
          value={props.memo}
          onChange={props.handleInputChange}
        />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width={2} />
  </Grid.Row>
);

export default EditCategoryTranSection;
