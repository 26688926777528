import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TaxSearch } from 'taxUI';
import { businessTypeParser } from 'libraries/utils';
import { getBusinessTypes } from 'libraries/api-v2/business-type';

class BusinessTypeSelector extends Component {
  state = {
    businessTypes: [],
    results: [],
    value: this.props.value,
  };

  componentDidMount() {
    const { form } = this.props;
    form && this.loadBusinessTypes(form, true);
  }

  componentDidUpdate(prevProps) {
    const { form: prevForm } = prevProps;
    const { form: nextForm } = this.props;
    if (prevForm !== nextForm) this.loadBusinessTypes(nextForm);
  }

  loadBusinessTypes = (form, justMounted = false) => {
    const { username, token, value } = this.props;
    this.setState({ isLoading: true, results: [], value: justMounted ? value : '' }, () => {
      getBusinessTypes(username, token, form)
        .then(({data: {businessType}}) => {
          this.setState({ businessTypes: businessType, isLoading: false });
        })
        // TO DO: display Notification error
        .catch(console.log);
    });
  };

  resetComponent = () => {
    this.setState({ isLoading: false, results: [], value: '' }, () => {
      const { onChangeValue } = this.props;
      onChangeValue && onChangeValue({ name: '', code: undefined });
    });
  }

  handleResultSelect = (e, { result: { title: name, code } }) => {
    const { onChangeValue } = this.props;
    this.setState({ value: name }, () => {
      const businessType = { name, code };
      onChangeValue && onChangeValue(businessType);
    });
  };

  handleSearchChange = (e, { value }) => {
    clearTimeout(this.timeout);
    if (value === '') this.resetComponent();
    else {
      this.setState({ isLoading: true, value }, () => {
        this.timeout = setTimeout(() => {
          const { businessTypes } = this.state;
          const results = businessTypeParser(businessTypes, value);
          this.setState({ isLoading: false, results });
        }, 500);
      });
    }
  };

  render() {
    const { isLoading, value, results } = this.state;
    const { label } = this.props;
    return (
      <TaxSearch
        category
        label={label}
        placeholder={label}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleSearchChange}
        results={results}
        value={value}
        fluid
      />
    );
  }
}

const mapStateToProps = ({
  session: { token, username },
}) => ({ token, username });

export default connect(mapStateToProps, null)(BusinessTypeSelector);
