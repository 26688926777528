import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Container,
  Header,
  Loader,
  Dimmer,
  Confirm,
} from "semantic-ui-react";
import "./styles.scss";
import { filterSelectedT } from "libraries/utils";
import { areNotBeingSeen } from "libraries/selectedTransactionsFilter";

export class BulkEditingBar extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { selected, allTransactions } = this.props.transactions;
    const filteredSelected = filterSelectedT(allTransactions, selected);
    const notseen = areNotBeingSeen(filteredSelected);
    const existDuplicated = filteredSelected.some(
      (transaction) => transaction.duplicate_status > 0
    );

    return (
      <Container className="BulkEdit__Container">
        {
          <Dimmer active={this.props.isLoading} inverted>
            <Loader
              active
              size="large"
              content={<Header icon>Updating...</Header>}
              style={{ position: "fixed" }}
            />
          </Dimmer>
        }
        <Confirm
          content="Are you sure you want to remove this/these transaction/s?"
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          onConfirm={() => {
            this.props.handleMarkAsDuplicated(1);
            this.setState({ open: false });
          }}
          size="tiny"
        />
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column className="BulkEdition__Total" width={6}>
              <Header as="h5">
                {this.props.itemsSelected}
                {this.props.itemsSelected > 1 ? " items " : " item "}
                selected
              </Header>
            </Grid.Column>
            <Grid.Column className="BulkEdit__Button" width={10}>
              {notseen && (
                <Button
                  onClick={this.props.handleMarkAsSeen}
                  className="mark-as-reviewed"
                >
                  MARK AS REVIEWED
                </Button>
              )}
              <Button
                onClick={this.props.showBulkEdition}
                className="change-category"
              >
                CHANGE CATEGORY
              </Button>
              {existDuplicated ? (
                <Button
                  onClick={() => this.props.handleMarkAsDuplicated(0)}
                  className="change-category"
                >
                  MARK AS UNVALIDATE
                </Button>
              ) : (
                <Button
                  onClick={() => this.setState({ open: true })}
                  className="change-category"
                >
                  MARK AS REMOVED
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  transactions: state.transactions,
});

export default connect(
  mapStateToProps,
  null
)(BulkEditingBar);
