import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const getSaveFunction = (props) => {
  let func;
  switch (props.contentType) {
    case 'bulk':
      func = props.handleSaveBulkEdition;
      break;
    case 'creation':
      func = props.handleSaveCreation;
      break;
    default:
      func = props.handleSaveEdition;
      break;
  }
  return func;
};

const getDisableStatus = (props) => {
  let status;
  switch (props.contentType) {
    case 'bulk':
      status = props.mixedTransactions;
      break;
    default:
      status = props.openConfirmation;
      break;
  }
  return status;
};

const ButtonSection = props => (
  <Grid.Row centered className="ECTransaction__ButtonRow">
    {
      props.contentType === 'edition' && (
        <Grid.Column verticalAlign="middle" mobile={16} tablet={5} computer={3}>
          <Button
            type="button"
            onClick={props.handleConfirmation}
            disabled={props.openConfirmation}
          >
            DELETE
          </Button>
        </Grid.Column>
      )
    }
    <Grid.Column verticalAlign="middle" mobile={16} tablet={5} computer={3}>
      <Button
        type="button"
        onClick={props.handleClose}
        disabled={props.openConfirmation}
      >
        CANCEL
      </Button>
    </Grid.Column>
    <Grid.Column verticalAlign="middle" mobile={16} tablet={5} computer={3}>
      <Button
        onClick={getSaveFunction(props)}
        type="submit"
        disabled={getDisableStatus(props)}
        primary
      >
      SAVE
      </Button>
    </Grid.Column>
  </Grid.Row>
);

export default ButtonSection;
