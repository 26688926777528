/** ACTION TYPES VERBS DEFINITION:
 * SET: Setting the values of an array or the value of an object
 * CLEAR: Deleting all the values of an array or the value of an object
 * PUSH: Pushing a value to an array
 * REMOVE: Removing a value of an array
 */

/** Session */
export const SET_USER_LOGIN_STATE = 'SET_USER_LOGIN_STATE';
export const SET_ONBOARDING_DONE = 'SET_ONBOARDING_DONE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_USER_LOGIN_STATE = 'CLEAR_USER_LOGIN_STATE';
export const SET_IS_LOGGING_OUT = 'LOG_OUT';
export const SET_USER_SESSION = 'SET_USER_SESSION';

/** Onboarding */
export const SET_SELECTED_FORM = 'SET_SELECTED_FORM';
export const SET_BUSINESS_TYPE = 'SET_BUSINESS_TYPE';
export const SET_BUSINESS_TYPE_DETAILS = 'SET_BUSINESS_TYPE_DETAILS';
export const SET_TRANSACTION_PERIOD = 'SET_TRANSACTION_PERIOD';
export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME';
export const SET_PUBLIC_ACCESS_TOKEN = 'SET_PUBLIC_ACCESS_TOKEN';
export const REMOVE_PUBLIC_ACCESS_TOKEN = 'REMOVE_PUBLIC_ACCESS_TOKEN';
export const CLEAR_ONBOARDING = 'CLEAR_ONBOARDING';

/** Dashboard transactions table selection */
export const ADD_SELECTED_TRANSACTION = 'ADD_SELECTED_TRANSACTION';
export const REMOVE_SELECTED_TRANSACTION = 'REMOVE_SELECTED_TRANSACTION';
export const RESET_SELECTED_TRANSACTIONS = 'RESET_SELECTED_TRANSACTIONS';
export const ADD_SELECTED_PAGE = 'ADD_SELECTED_PAGE';
export const REMOVE_SELECTED_PAGE = 'REMOVE_SELECTED_PAGE';
export const RESET_SELECTED_PAGES = 'RESET_SELECTED_PAGES';

/** Notifications */
export const PUSH_NOTIFICATION = 'PUSH_NEW_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

/** Transactions data */
export const SET_TRANSACTIONS = 'SET_INIT_TRANSACTIONS';
export const SET_TRANSACTION_IDS = 'SET_INIT_TRANSACTION_IDS';
export const UPDATE_SHOWN_TRANSACTIONS = 'UPDATE_SHOWN_TRANSACTIONS';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const MARK_AS_SEEN_TRANSACTIONS = 'MARK_AS_SEEN_TRANSACTIONS';
export const UPDATE_DUPLICATE_STATUS_TRANSACTIONS = 'UPDATE_DUPLICATE_STATUS_TRANSACTIONS';

/** Filters */
export const ADD_FILTER = 'ADD_FILTER';
export const SET_IS_SELECTING_CUSTOM_DATE = 'SET_IS_SELECTING_CUSTOM_DATE';

/** Sync new transactions */
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';

/** Plaid */
export const SET_PLAID_PUBLIC_KEY = 'SET_PLAID_PUBLIC_KEY';
export const SET_PLAID_ENV = 'SET_PLAID_ENV';
export const CLEAR_PLAID_ENV = 'CLEAR_PLAID_ENV';

/* Password recovery data */
export const UPDATE_PASSWORD_RECOVERY_EMAIL = 'UPDATE_PASSWORD_RECOVERY_EMAIL';
export const UPDATE_PASSWORD_RECOVERY_PINCODE = 'UPDATE_PASSWORD_RECOVERY_PINCODE';
export const CLEAR_RECOVERY_DATA = 'CLEAR_RECOVERY_DATA';

/** Profit and Loss Views */
export const UPDATE_PNL_DATE_RANGE = 'UPDATE_PNL_DATE_RANGE';
export const TOGGLE_PNL_IS_SELECTING_DATE = 'TOGGLE_PNL_IS_SELECTING_DATE';
export const SET_IS_TAX_FORM_VIEW = 'SET_IS_TAX_FORM_VIEW';
export const SET_PNL_DATA = 'SET_PNL_DATA';
export const CLEAR_PNL_DATA = 'CLEAR_PNL_DATA';
export const SET_IS_MONTH_PNL_VIEW = 'SET_IS_MONTH_PNL_VIEW';

/** Subscription */
export const SET_STRIPE_PLANS = 'SET_STRIPE_PLANS';
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION';
export const SET_FUTURE_SUBSCRIPTION = 'SET_FUTURE_SUBSCRIPTION';
export const SET_HAS_PAYMENT_METHOD = 'SET_HAS_PAYMENT_METHOD';
export const SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN';
export const SET_CANCEL_AT_PERIOD_END = 'SET_CANCEL_AT_PERIOD_END';
export const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION';

/* Invitations */
export const SET_INVITEE_FULL_NAME = 'SET_INVITEE_FULL_NAME';
export const SET_INVITEE_EMAIL = 'SET_INVITEE_EMAIL';
export const CLEAR_INVITEE = 'CLEAR_INVITEE';
export const SET_INVITATIONS = 'SET_INVITATIONS';
export const SET_RECEIVED_INVITATIONS = 'SET_RECEIVED_INVITATIONS';
export const SET_SENT_INVITATIONS = 'SET_SENT_INVITATIONS';

export const SET_CLIENTS = 'SET_CLIENTS';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';

/* Client Transactions */
export const ADD_SELECTED_CLIENT_TRANSACTION = 'ADD_SELECTED_CLIENT_TRANSACTION';
export const REMOVE_SELECTED_CLIENT_TRANSACTION = 'REMOVE_SELECTED_CLIENT_TRANSACTION';
export const RESET_SELECTED_CLIENT_TRANSACTIONS = 'RESET_SELECTED_CLIENT_TRANSACTIONS';
export const ADD_SELECTED_CLIENT_PAGE = 'ADD_SELECTED_CLIENT_PAGE';
export const REMOVE_SELECTED_CLIENT_PAGE = 'REMOVE_SELECTED_CLIENT_PAGE';
export const RESET_SELECTED_CLIENT_PAGES = 'RESET_SELECTED_CLIENT_PAGES';

export const SET_CLIENT_TRANSACTIONS = 'SET_INIT_CLIENT_TRANSACTIONS';
export const SET_CLIENT_TRANSACTION_IDS = 'SET_INIT_CLIENT_TRANSACTION_IDS';
export const CLEAR_CLIENT_TRANSACTIONS = 'CLEAR_CLIENT_TRANSACTIONS';
export const MARK_AS_SEEN_CLIENT_TRANSACTIONS = 'MARK_AS_SEEN_CLIENT_TRANSACTIONS';

export const UPDATE_CLIENT_TRANSACTIONS = 'UPDATE_CLIENT_TRANSACTIONS';

/* Invoice */
export const DRAFT_INVOICE = 'DRAFT_INVOICE';
export const DETAIL_INVOICE = 'DETAIL_INVOICE';
export const COPY_INVOICE = 'COPY_INVOICE';
