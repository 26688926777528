import React from 'react';
import Routes from 'routes';
import { Button, Message, Icon } from 'semantic-ui-react';
import { getHistoricalSyncStatusAsProfessional } from 'libraries/api-v2/customer-service';
import jsCookie from 'js-cookie';
import './style.scss';

export class SyncMessagev2 extends React.Component {
  state = {
    hidden: true,
    isSyncing: false,
    isCompleted: false,
    isFailed: false,
    isDisabled: false,
    isEmpty: false,
    timeoutId: null,
  }

  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.updateTransactions = this.updateTransactions.bind(this);
    this.syncStatusCallback = this.syncStatusCallback.bind(this);

    const clientEmail = jsCookie.get('active_client_email');

    getHistoricalSyncStatusAsProfessional(clientEmail)
      .then(this.syncStatusCallback);
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  handleDismiss() {
    this.setState({ hidden: true });
  }

  syncStatusCallback(response) {
    if (response.length > 0) {
      this.setState({
        isEmpty: response.length === 0,
        isFailed: response.some(element => element.status === 'failed'),
        isCompleted: response.every(element => element.status === 'complete'),
        isDisabled: response.some(element => element.status === 'disabled'),
      });
      if (!this.state.isCompleted
        && !this.state.isFailed && !this.state.isDisabled) {
        this.verifySyncProgress();
        this.setState({ isSyncing: true, hidden: false });
      }
    } else {
      this.setState({ isEmpty: true, hidden: false });
    }
  }

  verifySyncProgress(itemId = null, items = []) {
    const clientEmail = jsCookie.get('active_client_email');
    console.log('verifySyncProgress', itemId);
    const tid = setTimeout(() => {
      getHistoricalSyncStatusAsProfessional(clientEmail).then((response) => {
        if (response.length > 0) {
          if (itemId) {
            console.log('getHistoricalSyncStatusAsProfessional/response', response);
            const item = response.find(element => element.itemId === itemId);
            console.log('item', item);
            if (!item) {
              this.verifySyncProgress(itemId, items);
            } else {
              const isFailedSync = item.status === 'failed';
              const isCompletedSync = item.status === 'complete';
              const isDisabled = item.status === 'disabled';
              console.log('verifySyncProgress', { isFailedSync, isCompletedSync, isDisabled });
              if (!isCompletedSync && !isFailedSync && !isDisabled) {
                this.verifySyncProgress(itemId, items);
              } else {
                this.setState({
                  isSyncing: false,
                  isCompleted: isCompletedSync,
                  isFailed: isFailedSync,
                });
              }
            }
          } else {
            const filteredResponse = response.filter(element => items.includes(element.item_id))
            const isFailedSync = filteredResponse.some(element => element.status === 'failed');
            const isCompletedSync = filteredResponse.every(element => element.status === 'complete');
            const isDisabled = filteredResponse.some(element => element.status === 'disabled');
            if (!isCompletedSync && !isFailedSync && !isDisabled) {
              this.verifySyncProgress(null, items);
            } else {
              this.setState({
                isSyncing: false,
                isCompleted: isCompletedSync,
                isFailed: isFailedSync,
              });
            }
          }
        } else {
          this.setState({ isEmpty: true, hidden: false });
        }
      });
    }, 3000);
    this.setState({ timeoutId: tid });
  }

  verifySyncedAccounts(itemId = null, items = []) {
    this.setState({
      isCompleted: false,
      isFailed: false,
      isSyncing: true,
      hidden: false,
      isEmpty: false,
      isDisabled: false
    });
    this.verifySyncProgress(itemId, items);
  }

  updateTransactions() {
    if ({}.toString.call(this.props.updateTransactions) === '[object Function]') {
      const { username, token } = this.props.session;
      this.props.updateTransactions(username, token);
      this.setState({ hidden: true });
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isSyncing && !this.state.isCompleted && !this.state.isFailed && (
            <Message
              icon
              hidden={this.state.hidden}
              className={`sync ${this.props.section}`}
              onDismiss={this.props.closeButton === 'true' && this.state.isCompleted ? this.handleDismiss : null}
            >
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one moment...</Message.Header>
                We are downloading all your transactions,
                this action might take a couple of minutes.
              </Message.Content>
            </Message>
          )
        }
        {
          this.state.isFailed && (
            <Message
              icon
              negative
              hidden={this.state.hidden}
              className={`sync ${this.props.section}`}
              onDismiss={this.props.closeButton === 'true' && this.state.isCompleted ? this.handleDismiss : null}
            >
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Synchronization Error</Message.Header>
                An error ocurred while we were downloading your transactions, please try again.
              </Message.Content>
              {
                this.props.syncTransactions
                && (
                  <Button primary size="small" key="sync-transactions" onClick={this.syncTransactions}>
                    SYNC TRANSACTIONS
                    <i aria-hidden="true" className="right sync alternate icon" />
                  </Button>
                )
              }
            </Message>
          )
        }
        {
          this.state.isCompleted && !this.state.isFailed && (
            <Message
              icon
              hidden={this.state.hidden}
              className={`sync ${this.props.section}`}
              onDismiss={this.props.closeButton === 'true' && this.state.isCompleted ? this.handleDismiss : null}
            >
              <Icon name="circle check" color="green" />
              <Message.Content>
                <Message.Header>Transactions Downloaded</Message.Header>
                All your transactions were synchronized sucessfully.
              </Message.Content>
              {
                this.props.updateTransactions
                && this.state.isCompleted
                && <Button primary size="small" key="update-transactions-table" onClick={this.updateTransactions}>UPDATE TRANSACTIONS LIST</Button>
              }
            </Message>
          )
        }
        {
          this.state.isEmpty && (
            <Message
              warning
              className="accounts-warning"
              hidden={this.state.hidden}
              onDismiss={this.handleDismiss}
            >
              <Message.Header>{'You don\'t have any accounts synchronized yet!'}</Message.Header>
              <p>
                Go to
                <a href={Routes.MyProfile}>
                  {' My Profile '}
                </a>
                section and add at least one bank account.
              </p>
            </Message>
          )
        }
      </div>
    );
  }
}

export default (SyncMessagev2);
