import React from 'react';
import {
  Segment, Popup, Icon, Button, Checkbox, Dropdown,
} from 'semantic-ui-react';
import { DatesRangeInput, MonthRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  togglePnlIsSelectingDate, updatePnlDateRange, setIsTaxFormView, setIsMonthPNLView,
} from 'store/actions/profitAndLoss';
import { exportPnlPdf } from 'libraries/api-service';
import WithNotifications from 'components/hocs/WithNotifications';
import { exportXLSX, splitDateRangeInput, splitMonthRangeInput } from 'libraries/utils';

import './styles.scss';
import { exportPnlMonthXLSX, getTransactionTagList } from "libraries/api-v2/transactions-service";
import { addFilter } from "store/actions/filters";

export class PNLHeader extends React.Component {
  state = {
    isDownloading: false,
    isXLSXDownloading: false,
    includingRemainingBalance: false,
  }

  handleRemainingBalances = () => {
    this.setState(prev => ({
      includingRemainingBalance: !prev.includingRemainingBalance
    }))
  }

  download = () => {
    this.setState({ isDownloading: true });
    const { username, token, profitAndLoss: { dateRange } } = this.props;
    const [startDate, endDate] = splitDateRangeInput(dateRange);
    exportPnlPdf(username, token, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then((pdf) => {
        this.setState({ isDownloading: false });
        exportXLSX(pdf, `Synkbooks-${dateRange}-PNL.pdf`);
      })
      .catch(() => {
        this.setState({ isDownloading: false });
        this.props.pushNotification('NOT_PNL_MAIN_DOWNLOAD_FAIL', 'Download failed',
          'An error ocurred while trying to download the pdf.',
          'error', 7000);
      });
  }

  getTransactionTags() {
    getTransactionTagList().then(({ tagList }) => {
      const tags = tagList.map(tag => ({
        key: tag,
        text: tag,
        value: tag
      }));
      this.setState({
        tags,
        isLoadingTags: false
      })
    }).catch(() => {
      this.setState({
        isLoadingTags: false
      })
    });
  }

  onTagChange = (event, { value }) => this.props.addFilter('TAG', value);

  onDateElementClick = () => {
    const { profitAndLoss: { isSelectingDate } } = this.props;
    this.props.togglePnlIsSelectingDate(!isSelectingDate);
  }

  onTogglePnlView = () => {
    const { profitAndLoss: { isTaxFormView } } = this.props;
    this.props.setIsTaxFormView(!isTaxFormView);
  }

  onKeyEvent = (event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.triggerUpdate();
    }
  }

  triggerUpdate = (dateRange = this.props.profitAndLoss.dateRange) => {
    if (this.isValidDate(dateRange)) {
      this.props.hideTable();
      this.props.updatePnl(dateRange, this.props.TAG);
    }
  }

  isValidDate = (value) => {
    const [startDate, endDate] = splitDateRangeInput(value);
    return startDate.isValid() && endDate.isValid();
  }

  onDateChange = (event, {value}) => {
    this.props.updatePnlDateRange(value);
    if (event.currentTarget.tagName !== 'INPUT') {
      this.triggerUpdate(value);
    }
  }

  render() {
    const today = moment().format('MM/DD/YYYY');
    const thisMonth = moment().format('MM/YYYY');
    const { profitAndLoss } = this.props;
    return (
      <Segment
        attached
        className="Profit-And-Loss__Top"
      >
        <div className="Profit-And-Loss__FlexBox">
          <div className="Profit-And-Loss__User-Info">
            <span className="Profit-And-Loss__Title">PROFIT AND LOSS</span>
            <span className="Profit-And-Loss__Name">{this.props.userProfile.fullName}</span>
            <span className="Profit-And-Loss__User-Info__Selected-Form">{this.props.userProfile.form}</span>
            {
              profitAndLoss.isMonthPNLView ? null :
                !profitAndLoss.isSelectingDate
                  ? (
                    <div className="Profit-And-Loss__Date-Label">
                  <span
                    onClick={this.onDateElementClick}
                    onFocus={this.onDateElementClick}
                    onKeyPress={this.onDateElementClick}
                    role="button"
                    tabIndex="0"
                  >
                    {profitAndLoss.dateRange}
                    <Popup size="tiny" trigger={<Icon name="edit" size="small" />} content="Click to select a date" />
                  </span>
                    </div>)
                  : (
                    <DatesRangeInput
                      id="profit-loss-date"
                      className="Profit-And-Loss__Date-Label"
                      fluid
                      placeholder="---"
                      dateFormat="MM/DD/YYYY"
                      popupPosition="bottom left"
                      maxDate={today}
                      iconPosition="left"
                      value={profitAndLoss.dateRange}
                      onChange={this.onDateChange}
                      onKeyDown={this.onKeyEvent}
                    />)
            }
          </div>
          {
            !profitAndLoss.isMonthPNLView && (
              <div className="Profit-And-Loss__Export HideForPrint">
          <span
            onClick={this.onTogglePnlView}
            onKeyPress={this.onTogglePnlView}
            tabIndex={0}
            role="button"
          >
            {profitAndLoss.isTaxFormView ? 'Profit and Loss View' : 'Tax Form View'}
            <Icon name="file alternate outline" />
          </span>
                {
                  !profitAndLoss.isTaxFormView && (
                    <Button
                      primary
                      icon
                      labelPosition="left"
                      onClick={this.download}
                      loading={this.state.isDownloading}
                      disabled={this.state.isDownloading}
                      size="mini"
                    >
                      <Icon name="file pdf" />
                      Export
                    </Button>
                  )
                }
                <Button primary labelPosition="left" onClick={() => window.print()}
                        style={{ fontSize: !profitAndLoss.isTaxFormView ? '.75em' : '1em' }}>
                  <Icon name="print" />
                  Print
                </Button>
              </div>
            )
          }
        </div>
        <div className="Profit-And-Loss__Month_to_Month">
          <div className="Expand__Button__Container">
            <Dropdown
              id="tagName"
              onChange={this.onTagChange}
              value={this.props.TAG}
              onOpen={() => {
                this.setState({ isLoadingTags: true }, () => {
                  this.getTransactionTags();
                });
              }}
              size="small"
              placeholder="Select tags"
              options={this.state.tags}
              loading={this.state.isLoadingTags}
              multiple
              search
              selection
              scrolling
              deburr
            />
            <Button onClick={() => this.triggerUpdate()}>
              VIEW
            </Button>
          </div>
        </div>
      </Segment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
                           session: { userProfile, username, token },
                           profitAndLoss,
                           filters: { TAG },
                         }) => ({
  userProfile,
  profitAndLoss,
  username,
  token,
  TAG
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  togglePnlIsSelectingDate, updatePnlDateRange, setIsTaxFormView, addFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(PNLHeader));
