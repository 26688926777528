import React from 'react';
import {
  Segment, Popup, Icon, Button, Checkbox,
} from 'semantic-ui-react';
import {DatesRangeInput, MonthRangeInput} from 'semantic-ui-calendar-react';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  togglePnlIsSelectingDate, updatePnlDateRange, setIsTaxFormView, setIsMonthPNLView,
} from 'store/actions/profitAndLoss';
import {exportPnlPdf} from 'libraries/api-service';
import WithNotifications from 'components/hocs/WithNotifications';
import {exportXLSX, splitDateRangeInput, splitMonthRangeInput} from 'libraries/utils';

import './styles.scss';
import {exportPnlMonthXLSX, getProfitAndLossMonthResult} from "libraries/api-v2/transactions-service";

export class PNLHeader extends React.Component {
  state = {
    isDownloading: false,
    isXLSXDownloading: false,
    includingRemainingBalance: false,
  }

  onToggleMonthPNLView = () => {
    const {profitAndLoss: {isMonthPNLView}} = this.props;
    this.props.setIsMonthPNLView(!isMonthPNLView);
  }

  handleRemainingBalances = () => {
    this.setState(prev => ({
      includingRemainingBalance: !prev.includingRemainingBalance
    }))
  }

  download = () => {
    this.setState({isDownloading: true});
    const {username, token, profitAndLoss: {dateRange}} = this.props;
    const [startDate, endDate] = splitDateRangeInput(dateRange);
    exportPnlPdf(username, token, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then((pdf) => {
        this.setState({isDownloading: false});
        exportXLSX(pdf, `Synkbooks-${dateRange}-PNL.pdf`);
      })
      .catch(() => {
        this.setState({isDownloading: false});
        this.props.pushNotification('NOT_PNL_MAIN_DOWNLOAD_FAIL', 'Download failed',
          'An error ocurred while trying to download the pdf.',
          'error', 7000);
      });
  }

  async receiveExportProfitAndLossResult(taskId, startMonth, endMonth) {
    while(true) {
      try {
        const result = await getProfitAndLossMonthResult(taskId);
        if (result !== "pending") {
          const { status, data } = result;
          if (status === "success") {
            this.setState({ isXLSXDownloading: false });
            exportXLSX({data}, `Synkbooks-${moment(startMonth).format('YYYY-MM')}-${moment(endMonth).format('YYYY-MM')}-PNL.xlsx`);
          } else if (status === "error") {
            this.setState({ isXLSXDownloading: false });
          }
          break;
        }
      } catch (e) {
        this.setState({ isXLSXDownloading: false });
        console.log('Error/receiveExportProfitAndLossResult', e);
        break;
      }
    }
  }

  downloadMonthToMonth = () => {
    this.setState({isXLSXDownloading: true});
    const {includingRemainingBalance} = this.state;
    const {monthRange} = this.props;
    let [startMonth, endMonth] = splitMonthRangeInput(monthRange);
    exportPnlMonthXLSX(startMonth.format('YYYY-MM-DD'), endMonth.format('YYYY-MM-DD'), includingRemainingBalance)
      .then(res => {
        const { id } = res;
        this.receiveExportProfitAndLossResult(id, startMonth, endMonth);
      })
      .catch(err => {
        console.log(err);
        this.setState({ isXLSXDownloading: false });
      })
      // .then((pdf) => {
      //   this.setState({isXLSXDownloading: false});
      //   exportXLSX(pdf, `Synkbooks-${moment(startMonth).format('YYYY-MM')}-${moment(endMonth).format('YYYY-MM')}-PNL.xlsx`);
      // })
      // .catch(() => {
      //   this.setState({isXLSXDownloading: false});
      //   this.props.pushNotification('NOT_PNL_MAIN_DOWNLOAD_FAIL', 'Download failed',
      //     'An error ocurred while trying to download the xlsx.',
      //     'error', 7000);
      // });
  }

  onDateElementClick = () => {
    const {profitAndLoss: {isSelectingDate}} = this.props;
    this.props.togglePnlIsSelectingDate(!isSelectingDate);
  }

  onTogglePnlView = () => {
    const {profitAndLoss: {isTaxFormView}} = this.props;
    this.props.setIsTaxFormView(!isTaxFormView);
  }

  onKeyEvent = (event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.triggerUpdate();
    }
  }

  triggerUpdate = (dateRange = this.props.profitAndLoss.dateRange) => {
    if (this.isValidDate(dateRange)) {
      this.props.hideTable();
      this.props.updatePnl(dateRange);
    }
  }

  onDateChange = (event, {value}) => {
    this.props.updatePnlDateRange(value);
    if (event.currentTarget.tagName !== 'INPUT') {
      this.triggerUpdate(value);
    }
  }

  isValidDate = (value) => {
    const [startDate, endDate] = splitDateRangeInput(value);
    return startDate.isValid() && endDate.isValid();
  }

  render() {
    const today = moment().format('MM/DD/YYYY');
    const thisMonth = moment().format('MM/YYYY');
    const {profitAndLoss} = this.props;
    return (
      <Segment
        attached
        className="Profit-And-Loss__Top"
      >
        <div className="Profit-And-Loss__FlexBox">
          <div className="Profit-And-Loss__User-Info">
            <span className="Profit-And-Loss__Title">PROFIT AND LOSS</span>
            <span className="Profit-And-Loss__Name">{this.props.userProfile.fullName}</span>
            <span className="Profit-And-Loss__User-Info__Selected-Form">{this.props.userProfile.form}</span>
            {
              profitAndLoss.isMonthPNLView ? null :
              !profitAndLoss.isSelectingDate
                ? (
                  <div className="Profit-And-Loss__Date-Label">
                  <span
                    onClick={this.onDateElementClick}
                    onFocus={this.onDateElementClick}
                    onKeyPress={this.onDateElementClick}
                    role="button"
                    tabIndex="0"
                  >
                    {profitAndLoss.dateRange}
                    <Popup size="tiny" trigger={<Icon name="edit" size="small"/>} content="Click to select a date"/>
                  </span>
                  </div>)
                : (
                  <DatesRangeInput
                    id="profit-loss-date"
                    className="Profit-And-Loss__Date-Label"
                    fluid
                    placeholder="---"
                    dateFormat="MM/DD/YYYY"
                    popupPosition="bottom left"
                    maxDate={today}
                    iconPosition="left"
                    value={profitAndLoss.dateRange}
                    onChange={this.onDateChange}
                    onKeyDown={this.onKeyEvent}
                  />)
            }
          </div>
          {
            !profitAndLoss.isMonthPNLView &&(
              <div className="Profit-And-Loss__Export HideForPrint">
          <span
            onClick={this.onTogglePnlView}
            onKeyPress={this.onTogglePnlView}
            tabIndex={0}
            role="button"
          >
            {profitAndLoss.isTaxFormView ? 'Profit and Loss View' : 'Tax Form View'}
            <Icon name="file alternate outline"/>
          </span>
                {
                  !profitAndLoss.isTaxFormView && (
                    <Button
                      primary
                      icon
                      labelPosition="left"
                      onClick={this.download}
                      loading={this.state.isDownloading}
                      disabled={this.state.isDownloading}
                      size="mini"
                    >
                      <Icon name="file pdf"/>
                      Export
                    </Button>
                  )
                }
                <Button primary labelPosition="left" onClick={() => this.props.confirmPrint()}
                        style={{fontSize: !profitAndLoss.isTaxFormView ? '.75em' : '1em'}}>
                  <Icon name="print"/>
                  Print
                </Button>
              </div>
            )
          }
        </div>
        <div className="Profit-And-Loss__Month_to_Month">
          {/*<div className="Profit-And-Loss__Title Title">Per Month</div>*/}
          <div className="Expand__Button__Container">
            <Button
              icon
              labelPosition="left"
              onClick={this.onToggleMonthPNLView}
              size="mini"
            >
              <Icon name="calendar alternate outline"/>
              MONTHLY PROFIT AND LOSS
            </Button>
          </div>
          {
            profitAndLoss.isMonthPNLView && (
              <div>
                <MonthRangeInput
                  name="monthRange"
                  placeholder="---"
                  dateFormat="MM/YYYY"
                  clearable
                  closable
                  popupPosition="bottom left"
                  maxDate={thisMonth}
                  iconPosition="right"
                  value={this.props.monthRange}
                  clearIcon={<Icon name="remove" color="red"/>}
                  onChange={this.props.onMonthRangeDateChange}
                />
                <div className="Export__Remain__Balances">
                  <Checkbox
                    checked={this.state.includingRemainingBalance}
                    label={`INCLUDE REMAINING BALANCES`}
                    onChange={this.handleRemainingBalances}
                  />
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    onClick={this.downloadMonthToMonth}
                    loading={this.state.isXLSXDownloading}
                    disabled={this.state.isXLSXDownloading}
                    size="mini"
                  >
                    <Icon name="file excel"/>
                    Export
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Segment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
                           session: {userProfile, username, token},
                           profitAndLoss,
                         }) => ({
  userProfile,
  profitAndLoss,
  username,
  token,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  togglePnlIsSelectingDate, updatePnlDateRange, setIsTaxFormView, setIsMonthPNLView
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(PNLHeader));
