import React from 'react';
import {
  Segment, Header, Grid, Icon, Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import Routes from 'routes';
import { exportActivityLogAsProfessional } from 'libraries/api-v2/audit-trails-service';
import jsCookie from 'js-cookie';
import { exportXLSX } from 'libraries/utils';
import withNotifications from 'components/hocs/WithNotifications';
import {
  setIsTaxFormView,
} from 'store/actions/profitAndLoss';
import './styles.scss';

export class Reportsv2 extends React.Component {
  state = {
    isDownloading: false,
  }

  handleActivityLog = () => {
    this.setState({ isDownloading: true });

    const clientEmail = jsCookie.get('active_client_email');

    exportActivityLogAsProfessional(this.props.username, this.props.token, clientEmail)
      .then((res) => {
        const url = res.data.fileUrl;
        fetch(url)
          .then(res => res.text())
          .then(base64text => {
            const response = {};
            response.data = base64text;
            exportXLSX(response, 'SynkBooks-activityLog.xlsx');
            this.setState({ isDownloading: false });
          })
          .catch(err => {
            console.error('Error downloading the file:', err)
            this.setState({ isDownloading: false });
          })
      })
      .catch(() => {
        this.setState({ isDownloading: false });
        this.props.pushNotification('FAIL_ACT-LOG_DOWNLOAD', 'Download failed',
          'An error ocurred while trying to download your activities log. Please try again.', 'error', 5000);
      });
  }

  onTaxFormClick = () => {
    this.props.setIsTaxFormView(true);
  }

  render() {
    const clientEmail = jsCookie.get('active_client_email');

    if (clientEmail === undefined) {
      return (
        <p>Select a client from the Clients tab first.</p>
      );
    }

    return (
      <div className="Reports-Segments">
        <Header as="h1" className="Reports__Title">Select a report type</Header>
        <Grid stackable columns="equal" centered>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.ClientDashboardReportsProfitAndLoss}
              className="Reports__Segment"
            >
              <Icon name="chart line" size="massive" />
              <Button primary fluid>Profit and Loss</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ClientDashboardReportsProfitAndLoss}
              onClick={this.onTaxFormClick}
              className="Reports__Segment"
            >
              <Icon name="copy outline" size="massive" />
              <Button primary fluid>Tax Form</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              onClick={this.handleActivityLog}
              className="Reports__Segment Reports__Segment--Audit-Trail"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="id badge outline" size="massive" />
              <Button
                primary
                icon
                fluid
                labelPosition="right"
                loading={this.state.isDownloading}
                size="medium"
              >
                Download Activity Log
                <Icon name="download" />
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ClientDashboardReportsBalance}
              className="Reports__Segment Reports__Segment--Audit-Trail"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="balance" size="massive" />
              <Button
                primary
                fluid
              >
                Balances
              </Button>
            </Segment>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal" centered>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ClientDashboardInvoicing}
              className="Reports__Segment"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="dollar sign" size="massive" />
              <Button
                primary
                fluid
              >
                Invoice
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ClientDashboardCustomDashboard}
              className="Reports__Segment"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="low vision" size="massive" />
              <Button
                primary
                fluid
              >
                Custom Dashboard
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ClientDashboardCustomProfitAndLoss}
              className="Reports__Segment"
            >
              <Icon name="tags" size="massive" />
              <Button primary fluid>Custom Profit And Loss</Button>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { token, username },
}) => ({
  token,
  username,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  setIsTaxFormView,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNotifications(withRouter(Reportsv2)));
