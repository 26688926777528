import React from 'react';
import { Button } from 'semantic-ui-react';
import isNull from 'lodash-es/isNull';
import { startHistoricalSyncProcessAsProfessional, triggerHistoricalSyncProcess } from 'libraries/api-v2/customer-service';
import jsCookie from "js-cookie";

export class SyncButtonv2 extends React.Component {
  state = {
    isMounted: false,
    email: ''
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  setLoadingStatus = (isLoading, callback) => {
    this.setState({ isLoading });
    if (!isNull(callback) && {}.toString.call(callback) === '[object Function]') {
      callback();
    }
  }

  startHistoricalSync = () => {
    const clientEmail = jsCookie.get('active_client_email');
    this.state.isMounted && this.setLoadingStatus(true,
      () => startHistoricalSyncProcessAsProfessional(clientEmail)
        .then((res) => {
          console.log('startHistoricalSyncProcess', res);
          if (!isNull(this.props.syncMessage)) {
            this.props.syncMessage.verifySyncedAccounts(null, res.plaidItemsStarted);
          }
          this.state.isMounted && this.setLoadingStatus(false);
          this.props.pushNotification('SUCCESS_REQUEST_HISTORICAL_SYNC', 'Success',
            'We have received you request. The sync process will start shortly.', 'success', 5000);
        })
        .catch((error) => {
          this.state.isMounted && this.setLoadingStatus(false);
          this.props.pushNotification('ERROR_REQUEST_HISTORICAL_SYNC', 'Sync failed',
            'An error ocurred while trying to start the sync process. Please try again.', 'error', 5000);
        }));
  }

  render() {
    return (
      <>
      <Button
        onClick={this.startHistoricalSync}
        size="small"
        content="Sync"
        icon="sync alternate"
        labelPosition="right"
        primary
        disabled={this.props.processing || this.state.isLoading}
      />
      <div style={{display: 'none'}}>
        <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
        <button onClick={() => triggerHistoricalSyncProcess(this.state.email)}>Trigger</button>
      </div>
      </>
    );
  }
}

export default (SyncButtonv2);
