import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import isNull from 'lodash-es/isNull';
import { startHistoricalSyncProcess } from 'libraries/api-v2/customer-service';

export class ItemSyncButton extends React.Component {
  state = {
    isMounted: false,
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  setLoadingStatus = (isLoading, callback) => {
    this.setState({ isLoading });
    if (!isNull(callback) && {}.toString.call(callback) === '[object Function]') {
      callback();
    }
  }

  startHistoricalSync = () => {
    this.state.isMounted && this.setLoadingStatus(true,
      () => startHistoricalSyncProcess(this.props.itemId)
        .then(() => {
          if (!isNull(this.props.syncMessage)) {
            this.props.syncMessage.verifySyncedAccounts(this.props.itemId);
          }
          this.state.isMounted && this.setLoadingStatus(false);
          this.props.pushNotification('SUCCESS_REQUEST_HISTORICAL_SYNC', 'Success',
            'We have received you request. The sync process will start shortly.', 'success', 5000);
        })
        .catch((error) => {
          this.state.isMounted && this.setLoadingStatus(false);
          this.props.pushNotification('ERROR_REQUEST_HISTORICAL_SYNC', 'Sync failed',
            'An error ocurred while trying to start the sync process. Please try again.', 'error', 5000);
        }));
  }

  render() {
    return (
      <Popup
        content="Sync your account"
        position='top center'
        trigger={(
          <Icon
            name="sync alternate"
            size="small"
            className="action"
            onClick={this.startHistoricalSync}
          />
        )}
      />
    );
  }
}

export default (ItemSyncButton);
