import React from 'react';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import Reports from 'components/containers/Reportsv2';
import ProfitAndLoss from './ProfitAndLoss';
import Balance from "./Balance";
import CustomDashboardv2 from "screens/Dashboard/ClientReports/CustomDashboardV2";
import CustomProfitAndLossv2 from "screens/ClientDashboard/Reports/CustomProfitAndLossv2";

const ReportsScreen = () => (
  <Switch>
    <Route exact path={Routes.ClientDashboardReports} component={Reports} />
    <Route exact path={Routes.ClientDashboardReportsProfitAndLoss} component={ProfitAndLoss} />
    <Route exact path={Routes.ClientDashboardReportsBalance} component={Balance} />
    <Route path={Routes.ClientDashboardCustomDashboard} component={CustomDashboardv2} />
    <Route path={Routes.ClientDashboardCustomProfitAndLoss} component={CustomProfitAndLossv2} />
  </Switch>
);

export default ReportsScreen;
