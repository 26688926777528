import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { bindActionCreators } from 'redux';
import { setPlaidEnvironment, setPlaidPublicKey } from 'store/actions/plaid';
import withNotifications from 'components/hocs/WithNotifications';
import Card from 'components/ui/Card';
import SyncButton from 'components/presentationals/SyncButtonv2';
import ItemSyncButton from 'components/presentationals/SyncButtonv2/ItemSyncButtonv2';
import SyncMessage from 'components/presentationals/SyncMessagev2';
import ErrorPlaidMessages from 'components/presentationals/ErrorPlaidMessages';
import uuidv4 from 'uuid/v4';
import { updateTransactions } from 'store/actions/transactions';
import { Button, Checkbox, Dimmer, Icon, Image, List, Modal, Popup, Segment } from 'semantic-ui-react';
import { getInstitutionById, storePlaidItem, updateAccountStatusAsProfessional } from 'libraries/api-service';
import {
  clearItemErrors,
  getKeys,
  getPublicToken,
  markAsRemoved,
  removeInstitutions,
  resetLogin
} from 'libraries/api-v2/plaid-service';
import { getInstitutionsV2 } from "libraries/api-v2/institution-service";
import hasSubscription from 'store/selectors/subscription';

import './styles.scss';
import jsCookie from "js-cookie";
import UpdateBank from "components/presentationals/Plaid/UpdateBank";

const { REACT_APP_PLAID_CLIENT_NAME, REACT_APP_PLAID_WEBHOOK } = process.env

class LinkedAccountsv2 extends Component {
  state = {
    linkedAccounts: [],
    isConfirmOpen: false,
    processing: false,
    isSyncing: false,
    updateMode: {
      publicToken: undefined,
      itemId: undefined,
    },
    editItemAccounts: {
      itemId: undefined,
      accounts: {},
    },
    showRemovedAccounts: false
  };

  constructor(props) {
    super(props);
    this.ErrorPlaidMessages = React.createRef();
    this.syncMessage = React.createRef();
  }

  componentDidMount() {
    getKeys().then((res) => {
      const plaidPublicKey = res.data.plaid_public_key;
      const plaidEnv = res.data.plaid_env;
      this.props.setPlaidEnvironment(plaidEnv);
      this.props.setPlaidPublicKey(plaidPublicKey);
    });
    this.fetchInstitutions();
  }

  fetchInstitutions = () => {
    const clientEmail = jsCookie.get('active_client_email');
    this.setState({ processing: true }, () => {
      getInstitutionsV2(clientEmail)
        .then(this.addLogos)
        .then(linkedAccounts => {
          this.setState({ linkedAccounts, processing: false });
          console.log({ linkedAccounts })
        });
    });
  }

  addLogos = (institutions) => {
    const logos = {};

    const promises = institutions
      .map((inst) => {
        if (Object.prototype.hasOwnProperty.call(logos, inst.institutionId)) return null;
        logos[inst.institutionId] = '';
        return getInstitutionById(inst.institutionId).then((res) => {
          logos[inst.institutionId] = res;
        });
      })
      .filter(Boolean);

    return Promise.all(promises)
      .then(() => institutions.map(inst => ({ ...inst, ...logos[inst.institutionId] })));
  };

  openPlaidModal = () => window.linkHandler && window.linkHandler.open();

  onPlaidSuccess = (
    publicToken,
    { accounts, institution: { institution_id: institutionId, name: institutionName } },
  ) => {
    const selectedAccounts = accounts.map(acc => acc.id);
    const getLogoPromise = getInstitutionById(institutionId);
    const storePlaidItemPromise = storePlaidItem(publicToken, selectedAccounts);
    this.syncMessage.current.verifySyncedAccounts();
    this.setState({ processing: true }, () => {
      Promise.all([getLogoPromise, storePlaidItemPromise]).then(
        ([getLogoResponse, storePlaidItemResponse]) => {
          const { logo, primaryColor } = getLogoResponse;
          const {
            data: { stored_item: itemId },
          } = storePlaidItemResponse;
          const newInstitution = {
            id: uuidv4(),
            institutionId,
            institutionName,
            logo,
            primaryColor,
            items: [
              {
                itemId,
                hasError: false,
                accounts: accounts.map(({ id, name, mask }) => ({
                  accountId: id,
                  accountName: name,
                  accountMask: mask,
                  included: true,
                })),
              },
            ],
          };
          this.setState((pState) => {
            const idx = pState.linkedAccounts.findIndex(ac => ac.institutionId === institutionId);
            if (idx === -1) {
              return {
                processing: false,
                linkedAccounts: [...pState.linkedAccounts, newInstitution].sort((a, b) => {
                  const prev = a.institutionName.toLowerCase();
                  const next = b.institutionName.toLowerCase();
                  if (prev < next) return -1;
                  if (prev > next) return 1;
                  return 0;
                }),
              };
            }
            const linkedAccountsCopy = [...pState.linkedAccounts];
            linkedAccountsCopy[idx].items = [
              ...linkedAccountsCopy[idx].items,
              ...newInstitution.items,
            ];
            return { processing: false, linkedAccounts: linkedAccountsCopy };
          });
        },
      ).catch(() => {
        this.setState({ processing: false });
        this.props.pushNotification(
          'ERROR_STORING_ITEM',
          'Error storing item',
          'An error ocurred while trying to link the account. Please try again.',
          'error',
          5000,
        );
      });
    });
  };

  onUpdateSuccess = () => {
    const { itemId } = this.state.updateMode;
    clearItemErrors(itemId)
      .then(() => {
        const { linkedAccounts } = this.state;
        const updatedAccounts = linkedAccounts.map(account => ({
          ...account,
          items: account.items.map(item => ({
            ...item,
            hasError: (item.itemId === itemId) ? false : item.hasError,
          })),
        }));
        this.setState({
          linkedAccounts: updatedAccounts,
          updateMode: { itemId: undefined, publicToken: undefined },
        });
        this.props.pushNotification(
          'SUCCESS_CLEAR_ITEM_ERRORS',
          'Your account has been updated',
          'You\'ve successfully updated your account.',
          'success',
          7000,
        );
      })
      .catch(() => {
        this.setState({
          updateMode: { itemId: undefined, publicToken: undefined },
        });
        this.props.pushNotification(
          'ERROR_CLEAR_ITEM_ERRORS',
          'Error clearing your account errors.',
          'An error ocurred while clearing the errors for your account in our system. We\'ll try again if you click on the UPDATE DATA warning.',
          'error',
          7000,
        );
      });
  }

  onRemoveInstitutions = () => {
    const { itemId } = this.state;
    this.setState({ processing: true, isConfirmOpen: false }, () => {
      markAsRemoved(itemId)
        .then(() => {
          this.setState(prevState => ({
            linkedAccounts: prevState.linkedAccounts
              .map(account => ({
                ...account,
                items: account.items.filter(item => item.itemId !== itemId),
              }))
              .filter(account => account.items.length > 0),
            itemId: undefined,
            processing: false,
          }));
        })
        .catch(() => {
          this.setState({ itemId: undefined, processing: false });
          this.props.pushNotification(
            'ERROR_REMOVE_ACCOUNT',
            'Error removing account',
            'An error ocurred while trying to remove the account. Please try again.',
            'error',
            5000,
          );
        });
    });
  };

  onDeleteInstitutions = () => {
    const { itemId } = this.state;
    this.setState({ processing: true, isConfirmOpen: false }, () => {
      removeInstitutions(itemId)
        .then(() => {
          this.setState(prevState => ({
            linkedAccounts: prevState.linkedAccounts
              .map(account => ({
                ...account,
                items: account.items.filter(item => item.itemId !== itemId),
              }))
              .filter(account => account.items.length > 0),
            itemId: undefined,
            processing: false,
          }));
        })
        .catch(() => {
          this.setState({ itemId: undefined, processing: false });
          this.props.pushNotification(
            'ERROR_UNLINKING_ACCOUNT',
            'Error unlinking account',
            'An error ocurred while trying to unlink the account. Please try again.',
            'error',
            5000,
          );
        });
    });
  };


  onOpenConfirmationDialog = itemId => this.setState({ isConfirmOpen: true, itemId });

  onCancelConfirmationDialog = () => this.setState({ isConfirmOpen: false, itemId: undefined });

  onConfirmConfirmationDialog = () => {
    const { itemId } = this.state;
    this.setState({ processing: true, isConfirmOpen: false }, () => {
      removeInstitutions(itemId)
        .then(() => {
          this.setState(prevState => ({
            linkedAccounts: prevState.linkedAccounts
              .map(account => ({
                ...account,
                items: account.items.filter(item => item.itemId !== itemId),
              }))
              .filter(account => account.items.length > 0),
            itemId: undefined,
            processing: false,
          }));
        })
        .catch(() => {
          this.setState({ itemId: undefined, processing: false });
          this.props.pushNotification(
            'ERROR_UNLINKING_ACCOUNT',
            'Error unlinking account',
            'An error ocurred while trying to unlink the account. Please try again.',
            'error',
            5000,
          );
        });
    });
  };

  updatePlaidAccount = (itemId) => {
    this.setState({ processing: true }, () => getPublicToken(itemId)
      .then(({ data }) => {
        const hasResponse = !!data.response;
        const publicToken = hasResponse && data.response.public_token
          ? data.response.public_token : undefined;
        this.setState({
            processing: false,
            updateMode: { itemId, publicToken },
          },
          () => {
            if (publicToken) {
              const { plaidPublicKey, plaidEnv } = this.props;
              window.Plaid.create({
                clientName: REACT_APP_PLAID_CLIENT_NAME,
                webhook: REACT_APP_PLAID_WEBHOOK,
                product: ['auth', 'transactions'],
                env: plaidEnv,
                key: plaidPublicKey,
                onExit: this.handleOnExit,
                onSuccess: this.onUpdateSuccess,
                token: publicToken
              }).open();
            } else {
              this.onUpdateSuccess();
            }
          });
      })
      .catch(() => {
        this.props.pushNotification(
          'ERROR_GET_PUBLIC_TOKEN',
          'Update failed',
          'Unable to initialize plaid',
          'error',
          7000,
        );
      }));
  }

  handleOnExit = (error, metadata) => {
    this.ErrorPlaidMessages.current.handleOnExit(error, metadata);
    this.setState({ updateMode: { itemId: undefined, publicToken: undefined } });
  }

  setItemEditMode = (itemId, accounts) => {
    const markedAccounts = {};
    accounts && accounts.forEach((account) => {
      markedAccounts[account.accountId] = account.included;
    });
    this.setState({
      editItemAccounts: {
        itemId,
        accounts: markedAccounts,
      },
    });
  }

  handleOnSync = (syncing) => {
    this.setState({ isSyncing: syncing });
  }

  handleOnItemEditChange = (accountId) => {
    const { accounts, itemId } = this.state.editItemAccounts;
    const hasAccount = accounts[accountId];
    const updatedAccounts = {
      ...accounts,
      [accountId]: !hasAccount,
    };
    this.setState({
      editItemAccounts: {
        itemId,
        accounts: updatedAccounts,
      },
    });
  }

  handleOnAccountsSave = () => {
    const toTrue = [];
    const toFalse = [];
    const { accounts } = this.state.editItemAccounts;
    for (const accId in accounts) {
      if (accounts[accId]) toTrue.push(accId);
      else toFalse.push(accId);
    }
    const clientEmail = jsCookie.get('active_client_email');
    updateAccountStatusAsProfessional(clientEmail, toTrue, toFalse)
      .then((response) => {
        this.setState({ editItemAccounts: { itemId: undefined, accounts: [] } });
        this.fetchInstitutions();
        this.props.pushNotification(
          'SUCCESS_UPDATING_ITEM_ACCOUNTS',
          'Account updated',
          response.data.message,
          'success',
          5000,
        );
      })
      .catch(({ response }) => {
        this.setState({ editItemAccounts: { itemId: undefined, accounts: [] } });
        this.props.pushNotification(
          'ERROR_UPDATING_ITEM_ACCOUNTS',
          'Something went wrong',
          response.data.message,
          'error',
          7000,
        );
      });
  }

  render() {
    const {
      linkedAccounts, isConfirmOpen, processing, updateMode: { publicToken },
      editItemAccounts, showRemovedAccounts
    } = this.state;
    const editItemId = editItemAccounts.itemId;
    const { plaidPublicKey, plaidEnv, isSubscribed } = this.props;
    const actions = [
      // <Button primary size="small" key="LinkedAccounts-btnAdd" onClick={this.openPlaidModal} disabled={processing || this.state.isSyncing}>
      //   Add account
      // </Button>,
      <SyncButton
        key="LinkedAccounts-btnSync"
        pushNotification={ this.props.pushNotification }
        updateTransactions={ updateTransactions }
        processing={ processing }
        syncMessage={ this.syncMessage.current }
      />,
    ];
    const clientEmail = jsCookie.get('active_client_email');

    return (
      <>
        <Modal
          onClose={ () => this.setState({ isConfirmOpen: false }) }
          onOpen={ () => this.setState({ isConfirmOpen: true }) }
          open={ isConfirmOpen }
        >
          <Modal.Content>
            <Modal.Description>
              <p>
                Deleting a banking institution will delete all the data from your general ledger and your profit and
                loss. You will not be able to find transactions from the institution you delete. We do not recommend
                doing this, unless your absolutely sure you do not want this financial institution account associated
                with your SynkBooks account.
              </p>
              <p>
                Removing a banking institution will mark the account as removed. You will not be able to fetch new
                transactions, but this will keep all the transactions from the removed account viewable in the
                SynkBooks' dashboard. This is for an account that you do not use anymore, but you would like to keep the
                transactions viewable on SynkBooks.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ this.onCancelConfirmationDialog }>
              Cancel
            </Button>
            <Button
              color='primary'
              content="Remove"
              onClick={ this.onRemoveInstitutions }
            />
            <div style={ { display: 'none' } }>
              <Button
                color='primary'
                content="Remove"
                onClick={ this.onRemoveInstitutions }
              />
            </div>
            <Button
              color='primary'
              content="Delete"
              onClick={ this.onDeleteInstitutions }
            />
          </Modal.Actions>
        </Modal>
        {/* <Confirm
          open={isConfirmOpen}
          content="Removing a banking institution will remove all the data from your general ledger and your profit and loss. You will not be able to find transactions from the institution you remove. We do not recommend doing this, unless your absolutely sure you do not want this financial institution account associated with your SynkBooks account. Do you wish to proceed?"
          onCancel={this.onCancelConfirmationDialog}
          onConfirm={this.onConfirmConfirmationDialog}
        /> */ }
        <Card header="Linked Accounts" actions={ actions }>
          { !isSubscribed && <Dimmer active inverted/> }
          <SyncMessage
            section="profile"
            closeButton="true"
            ref={ this.syncMessage }
            session={ this.props.session }
          />
          <ErrorPlaidMessages
            section="profile"
            closeButton="true"
            ref={ this.ErrorPlaidMessages }
          />
          <div>
            <Checkbox
              label="Show removed accounts"
              checked={ showRemovedAccounts }
              onChange={ (e, data) => this.setState({ showRemovedAccounts: data.checked }) }/>
          </div>

          <div className="margin linked-accounts">
            <Segment basic>
              <List celled className="LinkedAccounts">
                { linkedAccounts.map(({
                                        id, institutionName, logo, items, bookStartDate
                                      }) => (
                  <>
                    { (items.some(item => !item.removed)) &&
                      <List.Item key={ id } className="LinkedAccounts__Item"
                                 disabled={ processing || this.state.isSyncing }>
                        <Image avatar src={ `data:image/jpeg;charset=utf-8;base64,${ logo }` }/>
                        <List.Content className="LinkedAccounts__Item__Content">
                          <List.Header className="LinkedAccounts__Item__InstitutionName">
                            { institutionName } <span>{ bookStartDate }</span>
                          </List.Header>
                          <List.Description className="LinkedAccounts__Item__Details">
                            { items.map(({ itemId, hasError, accounts, removed }) => (
                              <div
                                key={ itemId }
                                style={ { display: removed ? 'none' : 'block' } }
                                className={ cn(
                                  editItemId === itemId
                                    ? 'LinkedAccounts__Item__Details__Container-edit'
                                    : 'LinkedAccounts__Item__Details__Container',
                                ) }
                              >
                                {
                                  editItemId !== itemId && (
                                    <div
                                      role="presentation"
                                      className="LinkedAccounts__Item__Details__Container__Btns"
                                    >
                                      {/* <UpdateBank itemId={itemId} onSuccess={this.onConnectBankSuccess}>
                                    <Icon
                                      name="add circle"
                                      size="small"
                                      className="action"
                                    />
                                  </UpdateBank> */ }
                                      <div style={ { display: 'none' } }>
                                        <Icon
                                          name="redo"
                                          size="small"
                                          className="action"
                                          onClick={ () => resetLogin(itemId) }
                                        />
                                      </div>
                                      <ItemSyncButton
                                        itemId={ itemId }
                                        pushNotification={ this.props.pushNotification }
                                        updateTransactions={ updateTransactions }
                                        processing={ processing }
                                        syncMessage={ this.syncMessage.current }
                                      />
                                      <UpdateBank itemId={ itemId } onSuccess={ () => this.onUpdateSuccess(itemId) }>
                                        <Popup
                                          content='Reconnect your account'
                                          position='top center'
                                          trigger={ (
                                            <Icon
                                              name="chain"
                                              size="small"
                                              className="action"
                                            />
                                          ) }/>
                                      </UpdateBank>
                                      <Popup
                                        content="Edit your account"
                                        position='top center'
                                        trigger={ (
                                          <Icon
                                            name="edit"
                                            size="small"
                                            className="action"
                                            onClick={ () => this.setItemEditMode(itemId, accounts) }
                                          />) }/>
                                      <Popup
                                        content="Delete your account"
                                        position='top center'
                                        trigger={ (
                                          <Icon
                                            name="delete"
                                            size="small"
                                            className="action"
                                            onClick={ () => this.onOpenConfirmationDialog(itemId) }
                                          />) }/>
                                    </div>)
                                }
                                { accounts.map(({
                                                  accountId, accountName, accountMask, included, key,
                                                }) => (
                                  <div key={ key }>
                                    {
                                      editItemId === itemId
                                        ? (
                                          <Checkbox
                                            checked={ editItemAccounts.accounts[accountId] }
                                            label={ `${ accountName } (**** **** **** ${ accountMask })` }
                                            onChange={ () => this.handleOnItemEditChange(accountId) }
                                          />)
                                        : (
                                          <>
                                            <Icon name={ cn(!included && 'broken', 'chain') }/>
                                            { `${ accountName } (**** **** **** ${ accountMask })` }
                                          </>
                                        )
                                    }
                                  </div>
                                )) }
                                <div className="LinkedAccounts__Item__Details__Container__Actions">
                                  { editItemId !== itemId && (
                                    <div>
                                      <Icon name="check outline circle" color="green"/>
                                      LINKED
                                    </div>)
                                  }
                                  { hasError && <div
                                    // style={{ display: hasError ? 'block' : 'none' }}
                                    tabIndex={ 0 }
                                    role="button"
                                    className="LinkedAccounts__Item__Details__ActionUpdate"
                                  >

                                    <UpdateBank itemId={ itemId } onSuccess={ () => this.onUpdateSuccess(itemId) }>
                                      <Popup
                                        content={ `${ hasError ? 'The login details of these accounts have changed (credentials or MFA).' : '' } Please, reconnect these accounts by clicking this link.` }
                                        trigger={ (
                                          <div>
                                            <Icon name="warning circle" color="orange"/>
                                            RE-AUTHENTICATION REQUIRED
                                          </div>
                                        ) }
                                      />
                                    </UpdateBank>
                                  </div> }

                                </div>
                                { editItemId === itemId && (
                                  <div className="LinkedAccounts__Item__Details__Edit__Actions">
                                    <Button
                                      size="tiny"
                                      onClick={ () => this.setItemEditMode() }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      primary
                                      size="tiny"
                                      onClick={ this.handleOnAccountsSave }
                                    >
                                      Save
                                    </Button>
                                  </div>)
                                }
                              </div>
                            )) }
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    }
                  </>
                )) }

                { linkedAccounts.map(({
                                        id, institutionName, logo, items, bookStartDate
                                      }) => (
                  <>
                    { (items.some(item => item.removed) && showRemovedAccounts) &&
                      <List.Item key={ id } className="LinkedAccounts__Item"
                                 disabled={ processing || this.state.isSyncing }>
                        <Image avatar src={ `data:image/jpeg;charset=utf-8;base64,${ logo }` }/>
                        <List.Content className="LinkedAccounts__Item__Content">
                          <List.Header className="LinkedAccounts__Item__InstitutionName">
                            { institutionName } <span>{ bookStartDate }</span>
                          </List.Header>
                          <List.Description className="LinkedAccounts__Item__Details">
                            { items.map(({ itemId, hasError, accounts, removed }) => (
                              <div
                                key={ itemId }
                                style={ { display: !removed ? 'none' : 'block' } }
                                className={ cn(
                                  editItemId === itemId
                                    ? 'LinkedAccounts__Item__Details__Container-edit'
                                    : 'LinkedAccounts__Item__Details__Container',
                                ) }
                              >
                                {
                                  editItemId !== itemId && (
                                    <div
                                      role="presentation"
                                      className="LinkedAccounts__Item__Details__Container__Btns"
                                    >
                                      {/* <UpdateBank itemId={itemId} onSuccess={this.onConnectBankSuccess}>
                                    <Icon
                                      name="add circle"
                                      size="small"
                                      className="action"
                                    />
                                  </UpdateBank> */ }
                                      <div style={ { display: 'none' } }>
                                        <Icon
                                          name="redo"
                                          size="small"
                                          className="action"
                                          onClick={ () => resetLogin(itemId) }
                                        />
                                      </div>
                                      <ItemSyncButton
                                        itemId={ itemId }
                                        pushNotification={ this.props.pushNotification }
                                        updateTransactions={ updateTransactions }
                                        processing={ processing }
                                        syncMessage={ this.syncMessage.current }
                                      />
                                      <UpdateBank itemId={ itemId } onSuccess={ () => this.onUpdateSuccess(itemId) }>
                                        <Popup
                                          content='Reconnect your account'
                                          position='top center'
                                          trigger={ (
                                            <Icon
                                              name="chain"
                                              size="small"
                                              className="action"
                                            />
                                          ) }/>
                                      </UpdateBank>
                                      <Popup
                                        content="Edit your account"
                                        position='top center'
                                        trigger={ (
                                          <Icon
                                            name="edit"
                                            size="small"
                                            className="action"
                                            onClick={ () => this.setItemEditMode(itemId, accounts) }
                                          />) }/>
                                      <Popup
                                        content="Delete your account"
                                        position='top center'
                                        trigger={ (
                                          <Icon
                                            name="delete"
                                            size="small"
                                            className="action"
                                            onClick={ () => this.onOpenConfirmationDialog(itemId) }
                                          />) }/>
                                    </div>)
                                }
                                { accounts.map(({
                                                  accountId, accountName, accountMask, included, key,
                                                }) => (
                                  <div key={ key }>
                                    {
                                      editItemId === itemId
                                        ? (
                                          <Checkbox
                                            checked={ editItemAccounts.accounts[accountId] }
                                            label={ `${ accountName } (**** **** **** ${ accountMask })` }
                                            onChange={ () => this.handleOnItemEditChange(accountId) }
                                          />)
                                        : (
                                          <>
                                            <Icon name={ cn(!included && 'broken', 'chain') }/>
                                            { `${ accountName } (**** **** **** ${ accountMask })` }
                                          </>
                                        )
                                    }
                                  </div>
                                )) }
                                <div className="LinkedAccounts__Item__Details__Container__Actions">
                                  { editItemId !== itemId && (
                                    <div>
                                      <Icon name="remove circle" color="orange"/>
                                      REMOVED
                                    </div>)
                                  }
                                  { hasError && <div
                                    // style={{ display: hasError ? 'block' : 'none' }}
                                    tabIndex={ 0 }
                                    role="button"
                                    className="LinkedAccounts__Item__Details__ActionUpdate"
                                  >

                                    <UpdateBank itemId={ itemId } onSuccess={ () => this.onUpdateSuccess(itemId) }>
                                      <Popup
                                        content={ `${ hasError ? 'The login details of these accounts have changed (credentials or MFA).' : '' } Please, reconnect these accounts by clicking this link.` }
                                        trigger={ (
                                          <div>
                                            <Icon name="warning circle" color="orange"/>
                                            RE-AUTHENTICATION REQUIRED
                                          </div>
                                        ) }
                                      />
                                    </UpdateBank>
                                  </div> }

                                </div>
                                { editItemId === itemId && (
                                  <div className="LinkedAccounts__Item__Details__Edit__Actions">
                                    <Button
                                      size="tiny"
                                      onClick={ () => this.setItemEditMode() }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      primary
                                      size="tiny"
                                      onClick={ this.handleOnAccountsSave }
                                    >
                                      Save
                                    </Button>
                                  </div>)
                                }
                              </div>
                            )) }
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    }
                  </>
                )) }
              </List>
            </Segment>
          </div>
        </Card>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  username: state.session.username,
  plaidPublicKey: state.plaid.plaidPublicKey,
  plaidEnv: state.plaid.plaidEnv,
  isSubscribed: hasSubscription(state),
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setPlaidPublicKey,
    setPlaidEnvironment,
    updateTransactions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNotifications(LinkedAccountsv2));
