import apiRequestV2 from "libraries/api-v2/api";

const basePath = "/web-api";

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

export const createInvoice = (invoiceData) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/create`, {
    body: invoiceData,
  });

export const createInvoiceAsProfessional = (clientEmail, invoiceData) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/as-professional`, {
    body: {
      clientEmail,
      invoiceData
    },
  });

export const getInvoices = () =>
  apiRequestV2("get", `${basePath}/stripe/invoice/list`);

export const getInvoicesAsProfessional = (clientEmail) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/list/as-professional`, {
    body: {
      clientEmail
    }
  });

export const getInvoiceById = (id) =>
  apiRequestV2("get", `${basePath}/stripe/invoice/${id}`);

export const getInvoiceByIdAsProfessional = (clientEmail, id) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/${id}/as-professional`, {
    body: {
      clientEmail
    }
  });

export const signUp = () => 
  apiRequestV2("get", `${basePath}/stripe/connect/account-link`);

export const onBoardingLink = () =>
    apiRequestV2("get", `${basePath}/stripe/connect/onboarding-link`);

export const accountLink = () => 
  apiRequestV2("get", `${basePath}/stripe/connect/account-link`);

export const loginLink = () =>
    apiRequestV2("get", `${basePath}/stripe/connect/login-link`);

export const remindInvoice = (id) => 
  apiRequestV2("get", `${basePath}/stripe/invoice/remind/${id}`);

export const remindInvoiceAsProfessional = (clientEmail, id) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/remind/${id}/as-professional`, {
    body: {
      clientEmail
    }
  });

export const cancelInvoice = (id) => 
  apiRequestV2("get", `${basePath}/stripe/invoice/cancel/${id}`);

export const cancelInvoiceAsProfessional = (clientEmail, id) =>
  apiRequestV2("post", `${basePath}/stripe/invoice/cancel/${id}/as-professional`, {
    body: {
      clientEmail
    }
  });

export const deleteDraftInvoice = (id) =>
  apiRequestV2("del", `${basePath}/stripe/invoice/${id}`);

export const deleteDraftInvoiceAsProfessional = (clientEmail, id) =>
  apiRequestV2("del", `${basePath}/stripe/invoice/${id}/as-professional`, {
    body: {
      clientEmail
    }
  });

export const markInvoiceAsPaid = (id) =>
  apiRequestV2("put", `${basePath}/stripe/invoice/${id}/mark-as-paid`);

export const markInvoiceAsPaidAsProfessional = (clientEmail, id) =>
  apiRequestV2("put", `${basePath}/stripe/invoice/${id}/mark-as-paid/as-professional`, {
    body: {
      clientEmail
    }
  });

export const addTax = (taxData) => 
  apiRequestV2("post", `${basePath}/stripe/tax_rate/create`, {
    body:taxData
  });

export const addTaxAsProfessional = (clientEmail, taxData) =>
  apiRequestV2("post", `${basePath}/stripe/tax_rate/create/as-professional`, {
    body: {
      clientEmail,
      taxData
    }
  });

export const getTaxes = () =>
  apiRequestV2("get", `${basePath}/stripe/tax_rate/list`);

export const getTaxesAsProfessional = (clientEmail) =>
  apiRequestV2("post", `${basePath}/stripe/tax_rate/list/as-professional`, {
    body: {
      clientEmail
    }
  });

export const getInvoiceStats = (startDate, endDate) => apiRequestV2('post', `${basePath}/stripe/invoice-stats`, {
  body: {
    startDate,
    endDate,
  },
});

export const getInvoiceStatsAsProfessional = (clientEmail, startDate, endDate) => apiRequestV2('post', `${basePath}/stripe/invoice-stats/as-professional`, {
  body: {
    clientEmail,
    startDate,
    endDate,
  },
});

export default {
  createInvoice,
  getInvoices,
  getInvoiceById,
  signUp,
  accountLink,
  remindInvoice,
  cancelInvoice,
  addTax,
  getTaxes,
  getInvoicesAsProfessional,
  getInvoiceStatsAsProfessional,
  createInvoiceAsProfessional,
  getInvoiceByIdAsProfessional,
  remindInvoiceAsProfessional,
  cancelInvoiceAsProfessional,
  deleteDraftInvoiceAsProfessional,
  addTaxAsProfessional,
  getTaxesAsProfessional
};
