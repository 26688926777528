import { FILTERS } from 'libraries/transactionsFilterManager';
import { ADD_FILTER, SET_IS_SELECTING_CUSTOM_DATE } from '../ActionTypes';

const initialState = {
  TAB: FILTERS.TABS.ALL_TRANSACTIONS,
  AMOUNT: FILTERS.AMOUNT,
  MONTH: FILTERS.MONTH,
  CUSTOM_DATE: FILTERS.CUSTOM_DATE,
  IS_SELECTING_CUSTOM_DATE: false,
  SEARCH: FILTERS.SEARCH,
  ORDER: {
    DIRECTION: FILTERS.ORDER.DIRECTION,
    COLUMN: FILTERS.ORDER.COLUMN,
  },
  ACCOUNTNAME: FILTERS.ACCOUNTNAME,
  CATEGORIES: FILTERS.CATEGORIES,
  DUPLICATE_STATUS: FILTERS.DUPLICATE_STATUS
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_FILTER: {
      return {
        ...state,
        [payload.type]: payload.value,
      };
    }
    case SET_IS_SELECTING_CUSTOM_DATE: {
      return {
        ...state,
        IS_SELECTING_CUSTOM_DATE: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
