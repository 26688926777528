import API from '@aws-amplify/api';
import { ENDPOINT_V2 } from 'libraries/config';

const apiName = ENDPOINT_V2;

export const baseApiV2Path = '/web-api';

const apiRequestV2 = (method, url, params): Promise<any> => API[method](apiName, url, {
  ...params,
  response: true,
});

export default apiRequestV2;
