import React from "react";
import "./styles.scss";
import { currencyFormat } from 'libraries/utils';
import { Input, Select } from "semantic-ui-react";
import { getTaxes } from "libraries/api-v2/invoicing-service";

const discountTypeOption = [
  { key: 1, text: "%", value: 'percent' },
  { key: 2, text: "Amount", value: 'amount' },
];

class TotalTable extends React.Component {
  handleChange = (type, value) => {
    this.props.setCoupon(type, value);
  }
  render() {
    return (
      <table className="Create--table">
        <tbody>
          <tr>
            <td colSpan={3} className="Create--td-text">
              Subtotal
            </td>
            <td style={{ width: "40%", padding: "0px 15px" }}>
              $ {currencyFormat(Number(this.props.subtotal))}
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="Create--td-text">
              Tax
            </td>
            <td style={{ width: "40%", padding: "0px 15px" }}>
              $ {currencyFormat(Number(this.props.tax))}
            </td>
          </tr>
          <tr>
            <td className="Create--td-text" >
              Discount
            </td>
            <td style={{ width: "150px" }}>
              <Input fluid 
                value={this.props.coupon.discount}
                onChange={e => {
                  let value = e.target.value;
                  this.handleChange(this.props.coupon.discount_type, value);
                }}/>
            </td>
            <td>
              <Select
                style={{width:'100px'}}
                options={discountTypeOption}
                fluid
                value={this.props.coupon.discount_type}
                onChange={(e, data) => {
                  this.handleChange(data.value, this.props.coupon.discount);
                }}/>
            </td>
            <td className="Create--td-text">
              {Number(this.props.discount) !== 0 ? '-':''}$ {currencyFormat(Number(this.props.discount))}
            </td>
          </tr>
          <tr>
            <td
              colSpan={3}
              className="Create--td-text"
              style={{ fontWeight: "bold" }}
            >
              Total
            </td>
            <td className="Create--td-text" style={{ fontWeight: "bold" }}>
              $ {currencyFormat(Number(this.props.total))} USD
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default TotalTable;
