import React, { Component, Fragment } from 'react';
import {
  Confirm, Button, Grid, Image,
} from 'semantic-ui-react';

import star from 'images/star.svg';
import './styles.scss';

class NewTransactionsNotificator extends Component {
  state = { isConfirmOpen: false };

  onMarkAsReviewed = () => this.setState({ isConfirmOpen: true });

  closeConfirmationDialog = () => this.setState({ isConfirmOpen: false });

  onConfirmConfirmationDialog = () => {
    const { onMarkAll } = this.props;
    onMarkAll && onMarkAll();
    this.closeConfirmationDialog();
  };

  render() {
    const {
      props: { num, priorNum, isYTD },
      state: { isConfirmOpen },
    } = this;
    return (
      <Fragment>
        <Confirm
          open={isConfirmOpen}
          content={`By selecting "Mark All Transactions As Reviewed" you will select ${num} ${num > 1 ? 'transactions' : 'transaction'} as reviewed. Do you wish to proceed?`}
          onCancel={this.closeConfirmationDialog}
          onConfirm={this.onConfirmConfirmationDialog}
        />
        <div className="NewTransactionsNotificator__Container">
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column verticalAlign="middle">
                <Image src={star} size="mini" inline spaced />
                {`You have ${num} ${
                  num > 1 ? 'transactions' : 'transaction'
                } that need to be reviewed ${isYTD ? 'year to date' : ''}`}
                {
                  priorNum > 0 && isYTD && (
                      <div className="NewTransactionsNotificator__Prior">
                        <Image src={star} size="mini" inline spaced />
                        {`You have ${priorNum} ${
                            priorNum > 1 ? 'transactions' : 'transaction'
                        } that need to be reviewed in prior years`}
                      </div>
                  )
                }
              </Grid.Column>
              {/* <Grid.Column floated="right" textAlign="right">
                <Button onClick={this.onMarkAsReviewed}>MARK ALL TRANSACTIONS AS REVIEWED</Button>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default NewTransactionsNotificator;
