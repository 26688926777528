import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TaxInput } from 'taxUI';
import { deleteUserAccount } from 'libraries/api-v2/user-service';
import {
  Button, Message, Modal, Icon,
} from 'semantic-ui-react';
import { clearUserDidLogin, setIsLoggingOut } from 'store/actions/session';
import { clearTransactions } from 'store/actions/transactions';
import { clearOnboarding } from 'store/actions/onboarding';
import { clearPlaidEnvironment } from 'store/actions/plaid';
import { clearPnlData } from 'store/actions/profitAndLoss';
import Routes from 'routes';

import Card from 'components/ui/Card';

class DeactivateAccount extends Component {
  state = { isModalOpen: false, deactivating: false };

  onOpenModal = () => this.setState({ isModalOpen: true, txtUsername: '' });

  onCloseModal = () => this.setState({ isModalOpen: false });

  handleInputChange = (event, { name, value }) => this.setState({ [name]: value });

  onDeactivate = () => {
    const { username, token } = this.props;
    this.props.setIsLoggingOut(true);
    this.setState({ deactivating: true }, () => {
      deleteUserAccount(username, token)
        .then(() => localStorage.clear())
        .then(() => {
          this.props.clearTransactions();
          this.props.clearUserDidLogin();
          this.props.clearOnboarding();
          this.props.clearPlaidEnvironment();
          this.props.clearPnlData();
          this.props.setIsLoggingOut(false);
          this.props.history.push(Routes.Index);
        })
        .catch(() => {
          this.setState(
            {
              deactivating: false,
              errorMsg:
                'An error ocurred while trying to deactivate your account. Please try again.',
            },
            () => setTimeout(() => this.setState({ errorMsg: undefined }), 5000),
          );
        });
    });
  };

  render() {
    const {
      isModalOpen, deactivating, txtUsername, errorMsg,
    } = this.state;
    const { username } = this.props;
    const isDisabled = txtUsername !== username;
    return (
      <Fragment>
        <Modal
          centered={false}
          open={isModalOpen}
          onClose={this.onCloseModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Delete Account</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                By deleting this account you understand that you will not be able to retrieve this
                account or any data associated with this account. If you want to save any
                information, please download all data before you delete account. This action is not
                reversible.
              </p>
              <TaxInput
                fluid
                name="txtUsername"
                label="Username"
                placeholder="Username"
                value={txtUsername}
                onChange={this.handleInputChange}
              />
              {errorMsg && (
                <div>
                  <Message negative>
                    <Message.Content>{errorMsg}</Message.Content>
                  </Message>
                </div>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {deactivating && (
              <Message icon negative>
                <Icon name="circle notched" loading />
                <Message.Content style={{ textAlign: 'left' }}>
                  <Message.Header>Just one second</Message.Header>
                  We are deactivating your account for you.
                </Message.Content>
              </Message>
            )}
            {!deactivating && (
              <Fragment>
                <Button onClick={this.onCloseModal}>Cancel</Button>
                <Button
                  onClick={this.onDeactivate}
                  primary
                  labelPosition="right"
                  icon="user delete"
                  content="Delete"
                  disabled={isDisabled}
                />
              </Fragment>
            )}
          </Modal.Actions>
        </Modal>
        <Card header="Delete account">
          <div className="margin">
            <div style={{ textAlign: 'center' }}>
              <Button primary size="small" onClick={this.onOpenModal}>
                Delete
              </Button>
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  session: {
    userProfile: { email: username },
    token,
  },
}) => ({ username, token });

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    clearUserDidLogin,
    clearOnboarding,
    clearPlaidEnvironment,
    clearTransactions,
    clearPnlData,
    setIsLoggingOut,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DeactivateAccount));
