import React from 'react';
import { ProfitCustomSummaryv2 } from "components/containers/TransactionsCustomTablev2/CustomProfitSummary";
import { TransactionsCustomv2 } from "components/containers/TransactionsCustomTablev2";

const CustomDashboardv2 = () => (
  <>
    <ProfitCustomSummaryv2 />
    <TransactionsCustomv2 />
  </>
);

export default CustomDashboardv2;
