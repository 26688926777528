import React from 'react';
import {
  Table, Checkbox, Pagination, Container,
  Segment, Message, Header, Loader, Icon, Image,
} from 'semantic-ui-react';
import cs from 'classnames';
import './styles.scss';
import { slashFormatDate } from 'libraries/utils';

const BTransactionsTable = props => (
  <Segment className="Transactions__Container">
    {!props.hasError && (
      <Container textAlign="center" className="Transactions__Pagination-container">
        <Pagination
          activePage={props.currentPage}
          totalPages={props.totalPages}
          firstItem={false}
          lastItem={false}
          onPageChange={props.handlePaginationChange}
        />
      </Container>
    )}
    <Table basic stackable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            onClick={() => props.orderBy('date')}
            className="Transactions__Header--HeaderCell"
            textAlign="center"
            width={1}
          >
            Date
            <Icon name={cs('sort', props.order.COLUMN === 'date' && props.order.DIRECTION)} />
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            className="Transactions__Header--HeaderCell"
            onClick={() => props.orderBy('description')}
            width={4}
          >
            Transaction
            <Icon name={cs('sort', props.order.COLUMN === 'description' && props.order.DIRECTION)} />
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            className="Transactions__Header--HeaderCell"
            onClick={() => props.orderBy('category')}
            width={3}
          >
            Category
            <Icon name={cs('sort', props.order.COLUMN === 'category' && props.order.DIRECTION)} />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            textAlign="center"
            className="Transactions__Header--HeaderCell"
            onClick={() => props.orderBy('subcategory')}
          >
            Subcategory
            <Icon name={cs('sort', props.order.COLUMN === 'subcategory' && props.order.DIRECTION)} />
          </Table.HeaderCell>
          <Table.HeaderCell
            className="Transactions__Header--HeaderCell"
            onClick={() => props.orderBy('income')}
            textAlign="center"
            width={2}
          >
            Income
            <Icon name={cs('sort', props.order.COLUMN === 'income' && props.order.DIRECTION)} />
          </Table.HeaderCell>
          <Table.HeaderCell
            className="Transactions__Header--HeaderCell"
            onClick={() => props.orderBy('expense')}
            textAlign="center"
            width={2}
          >
            Expense
            <Icon name={cs('sort', props.order.COLUMN === 'expense' && props.order.DIRECTION)} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.transactions.map(transaction => (
          <Table.Row
            key={transaction.transaction_id}
            seen={transaction.seen}
            className={cs(
              "Transactions__Row",
              { '--Income': transaction.amount < 0 },
            )}
          >
            <Table.Cell content={slashFormatDate(transaction.date)} />
            <Table.Cell
              className="Transaction__Description"
              content={transaction.description} />
            <Table.Cell
              content={transaction.category}
              className={cs({
                Transaction__Category: false,
              })}
            />
            <Table.Cell content={transaction.subcategory} />
            {transaction.amount < 0 ? (
              <>
                <Table.Cell content={`$${transaction.amount * -1}`} />
                <Table.Cell />
              </>
            ) : (
              <>
                <Table.Cell />
                <Table.Cell content={`$${transaction.amount}`} />
              </>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
    {props.isLoading && (
      <Segment textAlign="center">
        <Loader
          active
          size="medium"
          inline
          content={<Header icon>Loading transactions...</Header>}
        />
      </Segment>
    )}
    <Message
      hidden={!props.hasError}
      error
      icon="database"
      header="Failed to get your transactions"
      content="Please try again"
    />
    {!props.hasError && (
      <Container textAlign="center" className="Transactions__Pagination-container">
        <Pagination
          activePage={props.currentPage}
          totalPages={props.totalPages}
          firstItem={false}
          lastItem={false}
          onPageChange={props.handlePaginationChange}
        />
      </Container>
    )}
  </Segment>
);

export default BTransactionsTable;
