import React from "react";
import { Button, Dropdown, Icon, Popup, Segment } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsMonthPNLView,
  setIsTaxFormView,
  togglePnlIsSelectingDate,
  updatePnlDateRange
} from "store/actions/profitAndLoss";
import {
  exportPNLPdfAsProfessional,
  getTransactionTagListAsProfessional
} from "libraries/api-v2/transactions-service";
import WithNotifications from "components/hocs/WithNotifications";
import { splitDateRangeInput } from "libraries/utils";
import jsCookie from "js-cookie";
import save from "save-file";
import { addFilter } from "store/actions/filters";
import "./styles.scss";

export class PNLHeader extends React.Component {
  state = {
    isDownloading: false,
    isXLSXDownloading: false,
    includingRemainingBalance: false
  };

  handleRemainingBalances = () => {
    this.setState(prev => ({
      includingRemainingBalance: !prev.includingRemainingBalance
    }));
  };
  download = () => {
    this.setState({ isDownloading: true });
    const clientEmail = jsCookie.get("active_client_email");
    const {
      username,
      token,
      profitAndLoss: { dateRange }
    } = this.props;
    const [startDate, endDate] = splitDateRangeInput(dateRange);
    exportPNLPdfAsProfessional(
      username,
      token,
      clientEmail,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    )
      .then(pdf => {
        this.setState({ isDownloading: false });

        const fileName = `Synkbooks-${dateRange}-PNL.pdf`;
        // TODO: It would be better if we didn't have to call data twice
        // Fix the API endpoint so it returns the raw binary data
        const binary = Buffer.from(pdf.data.data, "base64");
        save(binary, fileName);
      })
      .catch(() => {
        this.setState({ isDownloading: false });
        this.props.pushNotification(
          "NOT_PNL_MAIN_DOWNLOAD_FAIL",
          "Download failed",
          "An error ocurred while trying to download the pdf.",
          "error",
          7000
        );
      });
  };

  getTransactionTags() {
    const clientEmail = jsCookie.get("active_client_email");
    getTransactionTagListAsProfessional(clientEmail)
      .then(({ tagList }) => {
        const tags = tagList.map(tag => ({
          key: tag,
          text: tag,
          value: tag
        }));
        this.setState({
          tags,
          isLoadingTags: false
        });
      })
      .catch(() => {
        this.setState({
          isLoadingTags: false
        });
      });
  }

  onTagChange = (event, { value }) => this.props.addFilter("TAG", value);

  onDateElementClick = () => {
    const {
      profitAndLoss: { isSelectingDate }
    } = this.props;
    this.props.togglePnlIsSelectingDate(!isSelectingDate);
  };

  onTogglePnlView = () => {
    const {
      profitAndLoss: { isTaxFormView }
    } = this.props;
    this.props.setIsTaxFormView(!isTaxFormView);
  };

  onKeyEvent = event => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.triggerUpdate();
    }
  };

  triggerUpdate = (dateRange = this.props.profitAndLoss.dateRange) => {
    if (this.isValidDate(dateRange)) {
      this.props.hideTable();
      this.props.updatePnl(dateRange, this.props.TAG);
    }
  };

  isValidDate = value => {
    const [startDate, endDate] = splitDateRangeInput(value);
    return startDate.isValid() && endDate.isValid();
  };

  onDateChange = (event, {value}) => {
    this.props.updatePnlDateRange(value);
    if (event.currentTarget.tagName !== 'INPUT') {
      this.triggerUpdate(value);
    }
  }

  render() {
    const today = moment().format("MM/DD/YYYY");
    const { profitAndLoss } = this.props;
    const thisMonth = moment().format("MM/YYYY");
    const formName = jsCookie.get("active_client_form_name");
    const clientFullName = jsCookie.get("active_client_full_name");

    return (
      <Segment attached className="Profit-And-Loss__Top">
        <div className="Profit-And-Loss__FlexBox">
          <div className="Profit-And-Loss__User-Info">
            <span className="Profit-And-Loss__Title">PROFIT AND LOSS</span>
            <span className="Profit-And-Loss__Name">{clientFullName}</span>
            <span className="Profit-And-Loss__User-Info__Selected-Form">
              {formName}
            </span>
            {profitAndLoss.isMonthPNLView ? null : !profitAndLoss.isSelectingDate ? (
              <div className="Profit-And-Loss__Date-Label">
                <span
                  onClick={this.onDateElementClick}
                  onFocus={this.onDateElementClick}
                  onKeyPress={this.onDateElementClick}
                  role="button"
                  tabIndex="0"
                >
                  {profitAndLoss.dateRange}
                  <Popup
                    size="tiny"
                    trigger={<Icon name="edit" size="small" />}
                    content="Click to select a date"
                  />
                </span>
              </div>
            ) : (
              <DatesRangeInput
                id="profit-loss-date"
                className="Profit-And-Loss__Date-Label"
                fluid
                placeholder="---"
                dateFormat="MM/DD/YYYY"
                popupPosition="bottom left"
                maxDate={today}
                iconPosition="left"
                value={profitAndLoss.dateRange}
                onChange={this.onDateChange}
                onKeyDown={this.onKeyEvent}
              />
            )}
          </div>
          {!profitAndLoss.isMonthPNLView && (
            <div className="Profit-And-Loss__Export HideForPrint">
              <span
                onClick={this.onTogglePnlView}
                onKeyPress={this.onTogglePnlView}
                tabIndex={0}
                role="button"
              >
                {profitAndLoss.isTaxFormView
                  ? "Profit and Loss View"
                  : "Tax Form View"}
                <Icon name="file alternate outline" />
              </span>
              {!profitAndLoss.isTaxFormView && (
                <Button
                  primary
                  icon
                  labelPosition="left"
                  onClick={this.download}
                  loading={this.state.isDownloading}
                  disabled={this.state.isDownloading}
                  size="mini"
                >
                  <Icon name="file pdf" />
                  Export
                </Button>
              )}
              <Button
                primary
                labelPosition="left"
                onClick={() => window.print()}
                style={{
                  fontSize: !profitAndLoss.isTaxFormView ? ".75em" : "1em"
                }}
              >
                <Icon name="print" />
                Print
              </Button>
            </div>
          )}
        </div>
        <div className="Profit-And-Loss__Month_to_Month">
          <div className="Expand__Button__Container">
            <Dropdown
              id="tagName"
              onChange={this.onTagChange}
              value={this.props.TAG}
              onOpen={() => {
                this.setState({ isLoadingTags: true }, () => {
                  this.getTransactionTags();
                });
              }}
              size="small"
              placeholder="Select tags"
              options={this.state.tags}
              loading={this.state.isLoadingTags}
              multiple
              search
              selection
              scrolling
              deburr
            />
            <Button onClick={() => this.triggerUpdate()}>VIEW</Button>
          </div>
        </div>
      </Segment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { userProfile, username, token },
  profitAndLoss,
  filters: { TAG }
}) => ({
  userProfile,
  profitAndLoss,
  username,
  token,
  TAG
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      togglePnlIsSelectingDate,
      updatePnlDateRange,
      setIsTaxFormView,
      setIsMonthPNLView,
      addFilter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithNotifications(PNLHeader));
