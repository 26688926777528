import React from 'react';
import { Label, Segment, Popup, Image } from 'semantic-ui-react';

import './styles.scss';

const ProfileDetailsReadOnly = (props) => {
  const {
    email, fullName, accountType, form = 'Pending', businessType = 'Pending', imgName, avatar
  } = props;
  return (
    <div className="ProfileDetailsReadOnly">
      <div className="profile-detail">
        <Segment
          circular
          className="ProfileDetailsReadOnly-ImgName"
          textAlign="center"
        >
          {avatar ? <Image src={avatar} size='tiny' circular /> : 
          <div>{imgName}</div>
        }
        </Segment>
        
        <div className="ProfileDetailsReadOnly-Content">
          <div>
            <Label horizontal>{accountType}</Label>
          </div>
          <Popup content={fullName} trigger={<div className="ProfileDetailsReadOnly__FullName">{fullName}</div>} />
          <Popup content={email} trigger={<div className="ProfileDetailsReadOnly__Email">{email}</div>} />
        </div>
      </div>
      <h6>Business information</h6>
      <div className="ProfileDetailsReadOnly__footer">
        <div className="margin">
          <div>{businessType}</div>
          <div>{form}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsReadOnly;
