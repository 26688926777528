import React, { Component, Fragment } from 'react';
import {
  Button, Message, Modal, Segment, Grid, Icon, Form,
} from 'semantic-ui-react';
import { injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setIsPaymentModalOpen, setFutureSubscription } from 'store/actions/subscription';

import { TaxCardElement, TaxInput } from 'taxUI';
import {addPaymentMethod} from 'libraries/api-v2/stripe-service';
import { validatePromotionCode } from 'libraries/api-v2/stripe-service';
import withNotifications from 'components/hocs/WithNotifications';
import Card from 'components/ui/Card';
import './styles.scss';

class Payment extends Component {
  state = {
    processing: false,
    txtName: this.props.paymentItem ? this.props.paymentItem.name : '',
    promoCode: '',
    isPromoValidating: false,
    promotionCode: null,
  };

  submit = () => {
    const {
      state: { txtName },
      props: {
        stripe, paymentItem, updatePaymentMethod, onPaymentAdded,
      },
    } = this;
    const editMode = !!paymentItem;
    this.setState({ processing: true }, () => stripe
      .createToken({ name: txtName })
      .then(({ token }) => {
        if (token) return token.id;
        throw Error('Verify your card information is correct.');
      })
      .then(tokenId => addPaymentMethod(tokenId)
        .then(async () => {
          this.setState({ processing: false, error: undefined },
            () => {
              this.props.pushNotification(
                'PAYMENT_METHOD_ADDED_SUCCESSFULLY',
                `Payment method ${editMode ? 'updated' : 'added'} successfully`,
                '',
                'info',
                5000,
              );
              this.props.setIsPaymentModalOpen(false);
            });
          await updatePaymentMethod();
          if (this.props.futurePlan.planId) {
            const { name, planId } = this.props.futurePlan;
            let couponId = '';
            if (this.state.promotionCode && this.state.promotionCode.status) {
              couponId = this.state.promotionCode.data.coupon.id
            }
            onPaymentAdded(name, planId, couponId);
          }
        }))
      .catch((err) => {
        this.setState({
          error: err.message,
          processing: false,
        }, () => {
          setTimeout(() => this.setState({
            error: undefined,
          }), 5000);
        });
      }));
  };

  handleInputChange = (event, { name, value }) => this.setState({ [name]: value });

  handleModalClose = () => {
    this.props.setIsPaymentModalOpen(false);
    this.props.setFutureSubscription(null, null);
  }

  validatePromotionCode = () => {
    this.setState({isPromoValidating: true},
      async () => {
        const promotion = await validatePromotionCode(this.state.promoCode);
        this.setState({
          promotionCode: promotion,
          isPromoValidating: false
        });
      }
    )
  }

  render() {
    const {name} = this.props.futurePlan;
    const {
      props: {
        isloading, paymentItem, isPaymentModalOpen,
      },
      state: {
        txtName, processing, error, promoCode, isPromoValidating, promotionCode
      },
    } = this;
    const editMode = !!paymentItem;
    return (
      <Fragment>
        <Modal open={isPaymentModalOpen} onClose={() => this.props.setIsPaymentModalOpen(false)} size="small">
          <Modal.Header>{`${editMode ? 'Update' : 'Add'} method payment`}</Modal.Header>
          <Modal.Content>
            <TaxInput
              fluid
              name="txtName"
              label="Name on card"
              placeholder="Name on card"
              value={txtName}
              onChange={this.handleInputChange}
            />
            <TaxCardElement className="PaymentMethod-CardElement" />
            {error && (
              <Message negative>
                <Message.Header>There was an error</Message.Header>
                <p>{error}</p>
              </Message>
            )}
            {
              // name && name.includes('Premium annually') &&
              <Form
                success={promotionCode && promotionCode.status}
                error={promotionCode && !promotionCode.status}>
                <br />
                <Form.Group>
                  <Form.Input
                    placeholder='Do you have a promo code? Enter code'
                    name="promoCode"
                    defaultValue={promoCode}
                    width={13}
                    onChange={this.handleInputChange}
                  />
                  <Form.Button
                    content='Apply'
                    width={2}
                    className="Payment__Promo__Button"
                    loading={isPromoValidating}
                    disabled={isPromoValidating || !promoCode}
                    onClick={this.validatePromotionCode}
                  />
                </Form.Group>
                {
                  promotionCode &&
                  <Message
                    success={promotionCode && promotionCode.status}
                    error={promotionCode && !promotionCode.status}
                    content={
                      promotionCode && promotionCode.status ?
                        'Promo Code success. You will receive a 50% discount for your first year and will only be charged $60. Thank you for using SynkBooks.'
                        :'Invalid promo code. Please verify your code and try again.'
                    }
                  />
                }
              </Form>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={processing}
              className="Payment__Change"
              onClick={this.handleModalClose}
              content="Cancel"
            />
            <Button
              disabled={processing}
              className="Payment__Change"
              content={`${editMode ? 'Update' : 'Add'}`}
              onClick={this.submit}
            />
          </Modal.Actions>
        </Modal>
        <Card loading={isloading}>
          {!paymentItem ? (
            <Segment basic textAlign="center">
              <Button
                className="Payment__Change"
                content="Add a payment method"
                onClick={() => this.props.setIsPaymentModalOpen(true)}
              />
            </Segment>
          ) : (
            <Grid columns="3">
              <Grid.Column
                mobile="5"
                tablet="2"
                computer="2"
                textAlign="center"
              >
                <Icon name={`cc ${paymentItem.brand.toLowerCase()}`} size="huge" />
              </Grid.Column>
              <Grid.Column mobile="11" tablet="9" computer="9">
                <div>{paymentItem.name}</div>
                <div>{`${paymentItem.brand} **** **** **** ${paymentItem.last4}`}</div>
                <div>
                  {`Expires the ${paymentItem.expiration_month}/${paymentItem.expiration_year}`}
                </div>
              </Grid.Column>
              <Grid.Column
                mobile="16"
                tablet="5"
                computer="5"
                textAlign="center"
                verticalAlign="middle"
              >
                <Button className="Payment__Change" onClick={() => this.props.setIsPaymentModalOpen(true)} content="Modify" />
              </Grid.Column>
            </Grid>
          )}
        </Card>
      </Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  subscription: {
    isModalOpen,
    future,
  },
}) => ({
  isPaymentModalOpen: isModalOpen,
  futurePlan: future,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  setIsPaymentModalOpen,
  setFutureSubscription,
}, dispatch);

const withStripe = injectStripe(withNotifications(Payment));
export default connect(mapStateToProps, mapDispatchToProps)(withStripe);
