import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addSelectedPage,
  addSelectedTransaction,
  markAsSeenTransactions,
  removeSelectedPage,
  removeSelectedTransaction,
  resetSelectedPages,
  resetSelectedTransactions,
  setTransactions,
} from "store/actions/clientTransactions";
import { isManualTransaction, splitDateRangeInput } from "libraries/utils";
import {
  filterByCustomDateRange,
  filterByPage,
} from "libraries/transactionsFilterManager";
import getFilteredTransactions from "store/selectors/clientTransactions";
import { addFilter } from "store/actions/filters";
import Modal from "components/presentationals/Modal";
import SyncMessage from "components/presentationals/SyncMessagev2";
import TransactionModalContent from "components/containers/TransactionModalContentv2";
import BulkEditingBar from "components/containers/BulkEditv2";
import NewTransactionsNotificator from "components/containers/NewTransactionsNotificator";
import withNotifications from "components/hocs/WithNotifications";
import moment from "moment";
import {
  getTransactionsAsProfessional,
  markAsSeenAsProfessional,
  getTransactionsWithFilter,
} from "libraries/api-v2/transactions-service";
import { CustomTransactionsFiltersv2 } from "components/containers/TransactionsCustomFiltersv2";
import { CustomTransactionsTable } from "components/presentationals/TransactionsCustomTable";
import jsCookie from "js-cookie";
import _, { debounce } from "lodash-es";

export class CustomTransactionsv2 extends React.Component {
  state = {
    currentPage: 1,
    isLoading: true,
    hasError: false,
    modalShow: false,
    transaction: undefined,
    editionMode: true,
    contentType: undefined,
    offset: 0,
    editableTransactions: [],
    MAX_COUNT_PER_FETCH: 10000,
    CUSTOM_HEADER: {
      CATEGORY: true,
      SUBCATEGORY: true,
      TAG: true,
      MEMO: true,
      ACCOUNT: true,
      INCOME: true,
      EXPENSE: true,
    },

    pageTransactions: [],
    totalCount: 0,
    uncheckedCount: 0,
    allUncheckedCount: 0,
    pageSize: 25,
  };

  componentDidMount() {
    // const { username, token } = this.props.session;
    // if (!this.props.transactions.allTransactions.length) {
    //   this.updateTransactions(username, token);
    // } else {
    //   this.setState({ isLoading: false });
    // }
    this.updateTransactionsWithFilter();
    this.debouncedUpdateTransactionsWithFilter = debounce(this.updateTransactionsWithFilter, 1000);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.filteredTransactions.length !== this.props.filteredTransactions.length) {
  //     this.setState({ currentPage: 1 });
  //     this.props.resetSelectedTransactions();
  //     this.props.resetSelectedPages();
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filters, this.props.filters)) {
      this.debouncedUpdateTransactionsWithFilter();
    }
    if (prevState.currentPage !== this.state.currentPage) {
      this.updateTransactionsWithFilter(false);
    }
    if (prevState.pageSize !== this.state.pageSize) {
      this.updateTransactionsWithFilter();
    }
  }

  updateTransactionsWithFilter = (isReset = true) => {
    const { filters } = this.props;
    console.log({ filters });

    let transactionFilter = {};

    if (filters.TAB) {
      transactionFilter.tab = filters.TAB;
    }

    if (filters.AMOUNT) {
      if (filters.AMOUNT.MIN) {
        transactionFilter.minAmount = Number(filters.AMOUNT.MIN);
      }

      if (filters.AMOUNT.MAX) {
        transactionFilter.maxAmount = Number(filters.AMOUNT.MAX);
      }
    }

    if (filters.IS_SELECTING_CUSTOM_DATE) {
      const [startDate, endDate] = splitDateRangeInput(filters.CUSTOM_DATE);
      if (startDate && endDate && startDate.isValid && endDate.isValid()) {
        transactionFilter.startDate = startDate;
        transactionFilter.endDate = endDate;
      } else {
        return;
      }
    } else if (filters.MONTH === -2) {
      // year to date
      const yearStart = moment()
        .startOf("year")
        .format("MM/DD/YYYY");
      const today = moment().format("MM/DD/YYYY");
      const [startDate, endDate] = splitDateRangeInput(
        `${yearStart} - ${today}`
      );
      if (startDate && endDate && startDate.isValid && endDate.isValid()) {
        transactionFilter.startDate = startDate;
        transactionFilter.endDate = endDate;
      } else {
        return;
      }
    } else if (filters.MONTH === -3) { // Previous Year
      const previousYearStart = moment().subtract(1, 'years').startOf('year').format('MM/DD/YYYY');
      const previousYearEnd = moment().subtract(1, 'years').endOf('year').format('MM/DD/YYYY');
      const [startDate, endDate] = splitDateRangeInput(`${previousYearStart} - ${previousYearEnd}`);
      if (startDate && endDate && startDate.isValid && endDate.isValid()) {
        transactionFilter.startDate = startDate;
        transactionFilter.endDate = endDate;
      } else {
        return;
      }
    } else if (filters.MONTH === -1) {
    } else if (filters.MONTH >= 0 && filters.MONTH <= 12) {
      const year = moment().year();
      const firstDayOfMonth = moment([year, filters.MONTH]).startOf("month").format("YYYY-MM-DD");
      const lastDayOfMonth = moment([year, filters.MONTH]).endOf("month").format("YYYY-MM-DD");
      transactionFilter.startDate = firstDayOfMonth;
      transactionFilter.endDate = lastDayOfMonth;
    }
    if (filters.SEARCH && filters.SEARCH !== "") {
      transactionFilter.search = filters.SEARCH;
    }
    if (filters.ORDER && filters.ORDER.DIRECTION && filters.ORDER.COLUMN) {
      // TODO: Need to update backend API at first
      transactionFilter.orderColumn = filters.ORDER.COLUMN;
      transactionFilter.orderDirection =
        filters.ORDER.DIRECTION === "descending" ? "DESC" : "ASC";
    }
    if (filters.CATEGORIES) {
      transactionFilter.categories = filters.CATEGORIES;
    }
    if (filters.ACCOUNTNAME) {
      transactionFilter.accountNames = filters.ACCOUNTNAME;
    }
    if (filters.TAG) {
      transactionFilter.tags = filters.TAG;
    }

    transactionFilter.showRemoved = filters.DUPLICATE_STATUS; // false

    this.setState({ isLoading: true });
    if (isReset) {
      this.setState({
        currentPage: 1,
        pageTransactions: [],
        totalCount: 0,
        uncheckedCount: 0,
        allUncheckedCount: 0,
      });
    }

    const clientEmail = jsCookie.get("active_client_email");

    getTransactionsWithFilter(
      transactionFilter,
      this.state.pageSize,
      (this.state.currentPage - 1) * this.state.pageSize,
      clientEmail
    )
      .then(
        ({
          transactions,
          total_count,
          unchecked_count,
          all_unchecked_count,
        }) => {
          const pageTransactions = transactions.map((transaction) => {
            return {
              ...transaction,
              iseditable: isManualTransaction(transaction.transaction_id),
              mask: transaction.mask === "None" ? "" : transaction.mask,
              // subcategory: transaction.subcategory || "",
              // memo: transaction.plaid_memo || "", // null value from plaid
              // tags: transaction.plaid_tag || "", // null value from plaid
              // seen: transaction.status,
            };
          });

          this.setState({
            pageTransactions: pageTransactions,
            totalCount: total_count,
            uncheckedCount: unchecked_count,
            allUncheckedCount: all_unchecked_count,
            isLoading: false,
          });

          this.props.setTransactions(pageTransactions);

          console.log({
            transactions,
            total_count,
            unchecked_count,
            all_unchecked_count,
            pageTransactions,
          });
        }
      )
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  getTypeOfContent = (isEditable) => {
    const { editionMode } = this.state;
    if (!editionMode) return "creation";
    return isEditable === false ? "editcategory" : "edition";
  };

  handleCheckboxSelect = (event, { value, checked }) => {
    event.stopPropagation();
    if (checked) {
      this.props.addSelectedTransaction(value);
    } else {
      this.props.removeSelectedTransaction(value);
    }
  };

  handleSelectAll = (event, { checked }) => {
    // const currentPageTransactions = filterByPage(
    //   this.state.currentPage,
    //   this.props.filteredTransactions,
    //   this.state.pageSize
    // );
    // const transactionsIds = currentPageTransactions.map(
    //   (transaction) => transaction.transaction_id
    // );
    const transactionsIds = this.props.transactions.allTransactions.map(
      (transaction) => transaction.transaction_id
    );
    if (checked) {
      this.props.addSelectedPage(this.state.currentPage, transactionsIds);
    } else {
      this.props.removeSelectedPage(this.state.currentPage, transactionsIds);
    }
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  handlePageSizeChange = (size) => {
    this.setState({ pageSize: size });
  };

  handleShowModal = () => {
    this.setState({
      modalShow: true,
    });
  };

  handleCreateTransaction = () => {
    this.setState(
      {
        contentType: "creation",
        editionMode: false,
        transaction: undefined,
      },
      () => {
        this.handleShowModal();
      }
    );
  };

  handleCustomHeader = (name) => {
    this.setState((prev) => ({
      CUSTOM_HEADER: {
        ...prev.CUSTOM_HEADER,
        [name]: !prev.CUSTOM_HEADER[name],
      },
    }));
  };

  handleRowClick = (key) => {
    const type = this.getTypeOfContent(key.iseditable);
    if (type === "edition" || type === "editcategory") {
      this.setState({
        transaction: key,
      });
    }
    this.setState(
      {
        contentType: type,
      },
      () => {
        this.handleShowModal();
      }
    );
  };

  handleMark = (transaction) => {
    const { contentType } = this.state;
    let obj;
    let statefulTransactions = [];
    const { selected } = this.props.clientTransactions;
    if (selected.length) {
      statefulTransactions = selected;
    }

    switch (contentType) {
      case "bulk":
        this.callMarkAsSeen(
          statefulTransactions,
          selected,
          this.updateTransactionsWithFilter
        );
        break;
      default:
        this.callMarkAsSeen(
          [transaction],
          [transaction],
          this.updateTransactionsWithFilter
        );
        break;
    }
  };

  handleMarkAllAsSeen = () => {
    const clientEmail = jsCookie.get("active_client_email");
    const { username, token } = this.props.session;

    const newTransactions = this.props.filteredTransactions
      .filter((item) => ["new", "initial"].includes(item.seen))
      .map(({ transaction_id: id }) => id);
    markAsSeenAsProfessional(username, token, clientEmail, newTransactions)
      .then(() => this.props.markAsSeenTransactions(newTransactions))
      .then(() => this.props.resetSelectedTransactions())
      .then(() => this.props.resetSelectedPages());
  };

  handleMarkAsSeen = () => {
    this.setState(
      {
        contentType: "markaseen",
        isLoading: true,
      },
      () => {
        let statefulTransactions = [];
        const { selected } = this.props.clientTransactions;
        if (selected.length) {
          statefulTransactions = selected;
        }
        this.callMarkAsSeen(
          statefulTransactions,
          selected,
          this.updateTransactionsWithFilter
        );
      }
    );
  };

  callMarkAsSeen = (statefulTransactions, selected, executeAfter) => {
    const { username, token } = this.props.session;
    const { contentType } = this.state;
    const clientEmail = jsCookie.get("active_client_email");

    markAsSeenAsProfessional(username, token, clientEmail, statefulTransactions)
      .then(() => {
        switch (contentType) {
          case "markaseen":
            // executeAfter(selected);
            executeAfter();
            this.props.resetSelectedTransactions();
            this.props.resetSelectedPages();
            break;
          default:
            // executeAfter(username, token);
            executeAfter();
            this.props.resetSelectedTransactions();
            this.props.resetSelectedPages();
            break;
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.props.pushNotification(
          "NOT_MARKED_AS_SEEN",
          "Operation failed",
          "An error ocurred while trying to mark as seen your transaction.Please try again.",
          "error",
          8000
        );
        this.setState({ isLoading: false });
      });
  };

  handleClose = () => {
    this.setState({ modalShow: false, isLoading: false, editionMode: true });
  };

  showBulkEdition = () => {
    this.setState({
      modalShow: true,
      editionMode: true,
      contentType: "bulk",
      transaction: undefined,
    });
  };

  getTotalPages = (transactions) =>
    Math.ceil(transactions.length / this.state.pageSize);

  getTotalSelectedTransactions = () =>
    this.props.clientTransactions.selected.length;

  orderBy = (column) => {
    const { ORDER } = this.props.filters;
    let direction = "descending";
    if (ORDER.COLUMN === column) {
      direction = ORDER.DIRECTION === "descending" ? "ascending" : "descending";
    }
    this.props.addFilter("ORDER", { DIRECTION: direction, COLUMN: column });
  };

  /**
   This function populates the dashboard and polls new transactions,
   to distinguish between the manual created transactions from the ones returned by plaid the
   iseditable flag is added to every transaction.
   * */
  updateTransactionsAfterReset = (username, token) => {
    const clientEmail = jsCookie.get("active_client_email");

    this.setState({ isLoading: true }, () =>
      getTransactionsAsProfessional(
        username,
        token,
        clientEmail,
        this.state.offset
      ) // startDate, endDate)
        .then(async (response) => {
          let editableTransactions = response.transactions.map((item) => {
            let transaction = item;
            const isEditable = isManualTransaction(transaction.transaction_id);
            transaction = {
              ...transaction,
              iseditable: isEditable,
              mask: item.mask === "None" ? "" : item.mask,
            };
            return transaction;
          });
          this.setState((prevState) => ({
            editableTransactions: [
              ...prevState.editableTransactions,
              ...editableTransactions,
            ],
          }));
          if (
            response.total > this.state.MAX_COUNT_PER_FETCH &&
            response.total !== this.state.editableTransactions.length
          ) {
            this.setState(
              (prevState) => ({
                offset: prevState.offset + 1,
              }),
              () =>
                this.updateTransactionsAfterReset(
                  this.props.session.username,
                  this.props.session.token
                )
            );
          } else {
            this.props.resetSelectedTransactions();
            this.props.setTransactions(this.state.editableTransactions);
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            hasError: true,
          });
        })
    );
  };

  updateTransactions = (username, token) => {
    this.setState({
      offset: 0,
      editableTransactions: [],
    });
    this.updateTransactionsAfterReset(username, token);
  };

  reloadTransactionsTable = (username, token) => {
    this.props.setTransactions([]);
    this.updateTransactions(username, token);
  };

  render() {
    // const newTransactions = this.props.filteredTransactions.filter((item) =>
    //   ["new", "initial"].includes(item.seen)
    // ).length;
    // let priorYearTransactions = 0;
    // if (this.props.filters.MONTH === -2) {
    //   // year to date
    //   const yearStart = moment()
    //     .subtract(4, "year")
    //     .startOf("year")
    //     .format("MM/DD/YYYY"); // 4: ideally, getting transactions during 2 years from plaid.
    //   const yearEnd = moment()
    //     .subtract(1, "year")
    //     .endOf("year")
    //     .format("MM/DD/YYYY");
    //   priorYearTransactions = filterByCustomDateRange(
    //     yearStart,
    //     yearEnd,
    //     this.props.clientTransactions.allTransactions
    //   ).filter((item) => ["new", "initial"].includes(item.seen)).length;
    // }
    const withSelectedTransactions =
      this.props.clientTransactions.selected.length > 0;

    return (
      <>
        {this.state.modalShow && (
          <Modal show={this.state.modalShow} handleClose={this.handleClose}>
            <TransactionModalContent
              {...this.state.transaction}
              handleClose={this.handleClose}
              contentType={this.state.contentType}
              updateTransactions={this.updateTransactions}
              editionMode={this.state.editionMode}
              totalTransactionSelected={this.getTotalSelectedTransactions()}
              transactions={this.props.clientTransactions}
              resetSelectedTransactions={this.props.resetSelectedTransactions}
              resetSelectedPages={this.props.resetSelectedPages}
              handleMark={this.handleMark}
            />
          </Modal>
        )}
        <CustomTransactionsFiltersv2
          handleCreateTransaction={this.handleCreateTransaction}
          handleCustomHeader={this.handleCustomHeader}
          customHeaders={this.state.CUSTOM_HEADER}
        />
        {this.props.clientTransactions.selected.length > 0 && (
          <BulkEditingBar
            isLoading={this.state.isLoading}
            itemsSelected={this.getTotalSelectedTransactions()}
            showBulkEdition={this.showBulkEdition}
            handleMarkAsSeen={this.handleMarkAsSeen}
          />
        )}
        {/* {!withSelectedTransactions &&
          newTransactions > 0 && (
            <NewTransactionsNotificator
              num={newTransactions}
              priorNum={priorYearTransactions}
              isYTD={this.props.filters.MONTH === -2}
              onMarkAll={this.handleMarkAllAsSeen}
            />
          )} */}
        {!withSelectedTransactions &&
          this.state.uncheckedCount > 0 && (
            <NewTransactionsNotificator
              num={this.state.uncheckedCount}
              priorNum={
                this.state.allUncheckedCount - this.state.uncheckedCount
              }
              isYTD={
                this.props.filters.MONTH === -2 &&
                !this.props.filters.IS_SELECTING_CUSTOM_DATE
              }
              onMarkAll={this.handleMarkAllAsSeen}
            />
          )}
        <SyncMessage
          section="dashboard"
          session={this.props.session}
          updateTransactions={this.reloadTransactionsTable}
        />
        {/* <CustomTransactionsTable
          hasError={this.state.hasError}
          handleRowClick={this.handleRowClick}
          isLoading={this.state.isLoading}
          currentPage={this.state.currentPage}
          transactions={filterByPage(
            this.state.currentPage,
            this.props.filteredTransactions,
            this.state.pageSize
          )}
          totalPages={this.getTotalPages(this.props.filteredTransactions)}
          handlePaginationChange={this.handlePaginationChange}
          handleCheckboxSelect={this.handleCheckboxSelect}
          pagesSelected={this.props.clientTransactions.pagesSelected}
          selected={this.props.clientTransactions.selected}
          handleSelectAll={this.handleSelectAll}
          orderBy={this.orderBy}
          order={this.props.filters.ORDER}
          customHeaders={this.state.CUSTOM_HEADER}
        /> */}
        <CustomTransactionsTable
          hasError={this.state.hasError}
          handleRowClick={this.handleRowClick}
          isLoading={this.state.isLoading}
          currentPage={this.state.currentPage}
          transactions={this.state.pageTransactions}
          totalPages={Math.ceil(this.state.totalCount / this.state.pageSize)}
          handlePaginationChange={this.handlePaginationChange}
          pageSize={this.state.pageSize}
          handlePageSizeChange={this.handlePageSizeChange}
          handleCheckboxSelect={this.handleCheckboxSelect}
          pagesSelected={this.props.clientTransactions.pagesSelected}
          selected={this.props.clientTransactions.selected}
          handleSelectAll={this.handleSelectAll}
          orderBy={this.orderBy}
          order={this.props.filters.ORDER}
          customHeaders={this.state.CUSTOM_HEADER}
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  session: state.session,
  onboarding: state.onboarding,
  transactions: state.transactions,
  filters: state.filters,
  filteredTransactions: getFilteredTransactions(state),
  clientTransactions: state.clientTransactions,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSelectedTransaction,
      removeSelectedTransaction,
      resetSelectedTransactions,
      addSelectedPage,
      removeSelectedPage,
      resetSelectedPages,
      setTransactions,
      addFilter,
      markAsSeenTransactions,
    },
    dispatch
  );

export const TransactionsCustomv2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(CustomTransactionsv2));
