import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from '@aws-amplify/auth';
import {
  Container, Grid, Form, Message,
} from 'semantic-ui-react';
import { setUserSession } from 'store/actions/session';
import Routes from 'routes';
import { TaxFormInput } from 'taxUI';
import { getProfile } from 'libraries/api-v2/user-service';
import {
  createProfessionalCustomer,
  getProfessionalProfile,
} from 'libraries/api-v2/customer-service';

import withNotifications from 'components/hocs/WithNotifications';
import * as ReactGA from "react-ga";

export class Login extends Component {
  state = {
    username: '',
    password: '',
    hasError: false,
    errorReason: '',
    isLoading: false,
  };

  componentDidMount() {
    const { history } = this.props;
    Auth.currentAuthenticatedUser()
      .then(({ username, attributes }) => {
        console.log(`current authenticated user: ${username}, ${JSON.stringify(attributes)}`);

        if (attributes['custom:account_type'] === 'professional' && attributes['custom:onboardingDone'] === 'False') {
          console.error('user has not yet onboarded');
        } else if (attributes['custom:account_type'] === 'professional' && attributes['custom:onboardingDone'] === 'True') {
          history.push(Routes.ClientDashboardClients);
        } else {
          history.push(Routes.DashboardTransactions);
        }
      })
      .catch((err) => {
        console.error('could not get current authenticated user on component did mount');
        console.error(`${JSON.stringify(err)}`);
      });
  }

  handleLogin = () => {
    const {
      state: { username, password },
      props: { history },
    } = this;
    this.setState({ isLoading: true }, () => {
      Auth.signIn(username.trim(), password)
        .then(() => Auth.currentAuthenticatedUser())
        .then(({ attributes }) => {
          ReactGA.set({
            user: username,
          });
          const session = {
            username,
            userInfo: {
              onboarding: attributes['custom:onboardingDone'],
              fullName: attributes['custom:fullName'],
              accountType: attributes['custom:account_type'],
              accountStatus: attributes['custom:accountStatus'],
            },
            userProfile: {
              fullName: attributes['custom:fullName'],
              email: attributes.email,
              businessDetails: '',
              form: '',
              businessType: '',
              businessCode: 0,
              accounts: [],
            },
            isLoggingOut: false,
          };

          if (attributes['custom:onboardingDone'] === 'True' && attributes['custom:account_type'] === 'client') {
            return getProfile().then(userProfile => ({ ...session, userProfile }));
          }

          if (attributes['custom:onboardingDone'] === 'True' && attributes['custom:account_type'] === 'professional') {
            return getProfessionalProfile().then((res) => {
              const { userProfile } = res;

              return {
                ...session,
                userProfile: { email: userProfile.email, fullName: userProfile.full_name },
              };
            });
          }

          if (attributes['custom:account_type'] === 'professional') {
            return createProfessionalCustomer({
              customer_profile: {
                tax_form_name: 'Schedule C', // HACK: tax_document = Schedule C
                business_type_code: '541219', // HACK: business_type = Other Accounting Services
                business_type_details: session.userProfile.businessDetails,
                onboarding_plan_id: null,
              },
            }).then(() => (
              getProfessionalProfile().then((res) => {
                const { userProfile } = res;

                return {
                  ...session,
                  userProfile: { email: userProfile.email, fullName: userProfile.full_name },
                };
              })
            ))
              .catch((err) => {
                console.error(`There was a problem creating the professional customer: ${err}`);
              });
          }

          return session;
        })
        .then((res) => {
          const { userProfile, userInfo } = res;
          const { pushNotification } = this.props;

          if (userInfo.accountType == 'professional') {
            return res;
          }

          if (userProfile.subscriptionStatus === 'trial_will_end') {
            const content = (
              <div>
                Your trial period is about to end, Upgrade your subscription by choosing a plan
                under the
                <Link to={Routes.Subscription}> subscription </Link>
                page.
              </div>
            );
            pushNotification('TRIAL_WILL_END', 'Your free trial will expire soon', content, 'info', 7000);
          }
          if (userProfile.subscriptionStatus === 'canceled' && userProfile.paymentStatus) {
            pushNotification('SUBSCRIPTION_WILL_END', 'Your subscription will expire soon', 'Please select one of our plans to continue using SynkBooks.', 'info', 7000);
          }
          return res;
        })
        .then(this.props.setUserSession)
        .then(() => {
          const { session: { userInfo: { accountType } } } = this.props;

          if (accountType === 'professional') {
            history.push(Routes.ClientDashboardClients);
          } else {
            history.push(Routes.DashboardTransactions);
          }
        })
        .catch(this.handleLoginError);
    });
  };

  stripSpacesFromTargetValue = (evt) => {
    const evtCopy = Object.assign({}, evt);
    evtCopy.target.value = evtCopy.target.value.replace(/\s+/g, '');
    return evtCopy;
  }

  handleUsernameInputChange = (evt) => {
    this.handleInputChange(
      this.stripSpacesFromTargetValue(evt),
    );
  }

  handleInputChange = ({ target }) => this.setState({ [target.id]: target.value });

  handleLoginError = (err) => {
    console.log(`login error: ${err.message}`);
    this.setState({ isLoading: false, hasError: true, errorReason: err.message });
  };

  render() {
    return (
      <Grid.Column>
        <Form
          onSubmit={this.handleLogin}
          error={this.state.hasError}
          loading={this.state.isLoading}
        >
          <Form.Field>
            <TaxFormInput
              className="UserName-Input"
              fluid
              id="username"
              label="Email"
              placeholder="Email"
              value={this.state.username}
              onChange={this.handleUsernameInputChange}
              autoCapitalize="off"
              required
            />
          </Form.Field>
          <Form.Field>
            <TaxFormInput
              className="Password-Input"
              fluid
              id="password"
              label="Password"
              placeholder="Password"
              type="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              required
            />
          </Form.Field>
          <Form.Field>
            <Message error header="Login error" content={this.state.errorReason} />
          </Form.Field>
          <Form.Field>
            <Grid columns={2}>
              <Grid.Column>
                <Container
                  textAlign="left"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Link to="/password-recovery">Forgot my password</Link>
                  <Link style={{ marginTop: '0.5em' }} to="/confirm-email">Resend verification email</Link>
                </Container>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Link to="/">Back to SynkBooks</Link>
              </Grid.Column>
            </Grid>
          </Form.Field>
          <div style={{ textAlign: 'center' }}>
            <Form.Button type="submit" className="button" primary>
              LOG IN
            </Form.Button>
          </div>
        </Form>
      </Grid.Column>
    );
  }
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({ setUserSession }, dispatch);
/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withNotifications(Login)),
);
