import React from "react";
import Routes from "routes";
import { Button, Divider, Grid, Icon, Input, Loader, Modal, Segment, } from "semantic-ui-react";
import "./styles.scss";
import Moment from "moment";
import moment from "moment";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import InvoiceTable from "../InvoiceTablev2";
import {
  cancelInvoiceAsProfessional,
  deleteDraftInvoiceAsProfessional,
  markInvoiceAsPaidAsProfessional,
  getInvoicesAsProfessional,
  getInvoiceStatsAsProfessional,
  getTaxesAsProfessional,
  remindInvoiceAsProfessional,
} from "libraries/api-v2/invoicing-service";
import { bindActionCreators } from "redux";
import { copyInvoice, defaultInvoice } from "store/actions/invoice";
import { connect } from "react-redux";
import InvoiceStatistics from "components/containers/ManageInvoice/InvoiceStatistics";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import { splitDateRangeInput } from "libraries/utils";
import jsCookie from "js-cookie";

const getDefaultInvoiceDate = () => {
  const yearStart = moment().startOf('year').format('MM/DD/YYYY');
  const today = moment().format('MM/DD/YYYY');
  return `${yearStart} - ${today}`;
};

class ManageInvoice extends React.Component {
  _isMounted = false;
  state = {
    search: "",
    status: "all",
    selectedType: "",
    data: [],
    selectedId: 0,
    isLoading: true,
    isSignUp: false,
    open: false,
    isReminding: false,
    isCanceling: false,
    isUpdating: false,
    isDeleting: false,
    show: false,
    currentPage: 1,
    totalPages: 1,
    isInvoiceStatsUpdating: false,
    invoiceDate: getDefaultInvoiceDate(),
    invoiceStats: null,
    invoicePaid: 0,
    invoiceOpen: 0,
    invoiceOpenedCount: 0,
    isMarkingAsPaid: false
  };

  printPdf(id) {
    window.location.href = this.state.data.find(
      (el) => el.id == id
    ).invoice_pdf;
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value });
  };
  onDetail = (id) => {
    const selectedData = this.state.data.find((el) => el.id == id);
    if (selectedData.status === "draft") {
      this.props.history.push(Routes.ClientDashboardInvoicingCreate);
      localStorage.setItem("selectedId", id);
    } else {
      localStorage.setItem("selectedData", JSON.stringify(selectedData));
      this.props.history.push(`/client-dashboard/invoicing/detail/${id}`);
    }

    this.setState({
      selectedId: id,
    });
  };
  onCopy = (id) => {
    this.props.copyInvoice('duplicate');
    this.props.history.push(Routes.ClientDashboardInvoicingCreate);
    localStorage.setItem("selectedId", id);
    this.setState({
      selectedId: id,
    });
  };
  onAction = (id, action) => {
    const clientEmail = jsCookie.get('active_client_email');
    if (action === "edit") this.onDetail(id);
    if (action === "copy") this.onCopy(id);
    if (action === "remind") {
      this.setState({ isReminding: true });
      remindInvoiceAsProfessional(clientEmail, id)
        .then((res) => {
          this.getInvoiceList();
          this.setState({ isReminding: false });
          toast({
            type: "success",
            icon: "check circle outline",
            title: "Remind invoice",
            time: 2000,
            description: <p>Invoice was sent successfully</p>,
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ isReminding: false });
          toast({
            type: "error",
            icon: "warning circle",
            title: "Remind invoice",
            time: 2000,
            description: <p>Reminding invoice was failed.</p>,
          });
        });
    }
    if (action == "print") this.printPdf(id);
    if (action == "cancel") {
      this.setState({ isCanceling: true });
      cancelInvoiceAsProfessional(clientEmail, id)
        .then((res) => {
          this.getInvoiceList();
          this.setState({ isCanceling: false });
          toast({
            type: "info",
            icon: "alarm",
            title: "Cancel invoice",
            time: 2000,
            description: <p>Invoice was canceled successfully.</p>,
          });
        })
        .catch((err) => {
          this.setState({ isCanceling: false });
          console.log(err.response);
          toast({
            type: "error",
            icon: "warning circle",
            title: "Cancel invoice",
            time: 2000,
            description: <p>Canceling invoice was failed.</p>,
          });
        });
    }
    if (action === "delete") {
      this.setState({ isDeleting: true });
      deleteDraftInvoiceAsProfessional(clientEmail, id)
        .then((res) => {
          this.getInvoiceList();
          this.setState({ isDeleting: false });
          toast({
            type: "success",
            icon: "check circle outline",
            title: "Remind invoice",
            time: 2000,
            description: <p>The draft invoice has been deleted successfully</p>,
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ isDeleting: false });
          toast({
            type: "error",
            icon: "warning circle",
            title: "Delete draft invoice",
            time: 2000,
            description: <p>Deleting draft invoice failed.</p>,
          });
        });
    }

    if (action === "markAsPaid") {
      this.setState({ isMarkingAsPaid: true })
      markInvoiceAsPaidAsProfessional(clientEmail, id)
      .then((res) => {
        this.getInvoiceList();
        this.setState({ isMarkingAsPaid: false});
        toast({
          type: "success",
          icon: "check circle outline",
          title: "Mark invoice as paid",
          time: 2000,
          description: <p>The invoice has been marked as paid successfully.</p>,
        });
      })
      .catch((err) => {
        this.setState({ isMarkingAsPaid: false});
        toast({
          type: "error",
          icon: "warning circle",
          title: "Mark invoice as paid",
          time: 2000,
          description: <p>Marking invoice as paid was failed.</p>,
        });
      })

    }
  };

  createInvoice = () => {
    this.props.history.push(Routes.ClientDashboardInvoicingCreate);
  };
  setOpen = (open) => {
    this.setState({ open: open });
  };
  setShow = (show) => {
    this.setShow(show);
  };
  onBack = () => {
    this.props.history.goBack();
    // this.props.history.push(Routes.ClientDashboardClients);
  };
  getInvoiceList = () => {
    const clientEmail = jsCookie.get('active_client_email');
    getInvoicesAsProfessional(clientEmail)
      .then((res) => {
        let data = [];
        res.data.forEach((el) => {
          data.push({
            id: el.id,
            date: Moment(new Date(el.created_at)).format("MMMM DD, YYYY"),
            invoice: el.invoice_number == null ? "" : el.invoice_number,
            recipient: el.receiver_email == null ? "" : el.receiver_email,
            status: el.status == null ? "" : el.status,
            amount: el.amount_total == null ? 0 : el.amount_total,
            amount_due: el.amount_due == null ? 0 : el.amount_due,
            currency: "USD",
            invoice_pdf: el.invoice_pdf,
            internal_number: el.internal_number ? el.internal_number : ""
          });
        });
        if (this._isMounted)
          this.setState({
            data: data,
            isLoading: false,
          });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ isLoading: false });
        if (err.response.status) {
          const status = err.response.status;
          if (status == 500) this.setState({ data: [] });
          else if (status == 400) this.setOpen(true);
        }
      });
  };

  getTaxRateList() {
    const clientEmail = jsCookie.get('active_client_email');
    getTaxesAsProfessional(clientEmail)
      .then((res) => {
        localStorage.setItem("taxRateList", JSON.stringify(res.data));
      })
      .catch((err) => {
      });
  }

  handleDateSelection = (event, { value }) => {
    this.setState({ invoiceDate: value });
    this.updateInvoiceStatsData(value);
  }

  updateInvoiceStatsData = (dateRange = this.state.invoiceDate) => {
    const clientEmail = jsCookie.get('active_client_email');
    const [startDate, endDate] = splitDateRangeInput(dateRange);
    if (startDate.isValid() && endDate.isValid()) {
      this.setState({ isInvoiceStatsUpdating: true });
      getInvoiceStatsAsProfessional(clientEmail, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
        .then((res) => {
          this.setState({
            isInvoiceStatsUpdating: false,
            invoiceStats: res.data
          });
          const paid = res.data.paidData.reduce((acc, val) => acc + val, 0)
          const open = res.data.openData.reduce((acc, val) => acc + val, 0)
          this.setState({
            invoicePaid: paid,
            invoiceOpen: open,
            invoiceOpenedCount: res.data.openedCount
          })

        })
        .catch(() => {
          this.setState({ apiError: true, isInvoiceStatsUpdating: false });
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateInvoiceStatsData();
    localStorage.setItem("selectedData", null);
    localStorage.setItem("selectedId", 0);
    localStorage.setItem("taxRateList", null);
    this.getInvoiceList();
    this.getTaxRateList();
    this.props.defaultInvoice();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      status,
      search,
      data,
      open,
      isSignUp,
      isLoading,
      isReminding,
      isCanceling,
      isUpdating,
      isDeleting,
      isMarkingAsPaid
    } = this.state;
    const clientFullName = jsCookie.get('active_client_full_name');

    return (
      <>
        <Segment loading={this.state.isInvoiceStatsUpdating}>
          <Grid>
            <Grid.Column width={4} className="Invoice_Stats__Content">
              <div className="Invoice_Stats__Content Invoice_Stats__Content__Filters">
                <h3 className="Invoice_Stats__Content__Filters--Header">Invoices Statistics</h3>
                <div className="Invoice_Stats__Content__Filters--Content">
                  <table>
                    <tr>
                      <td style={{ textAlign: 'right' }}>Unpaid Invoices:</td>
                      <td>{`$${this.state.invoiceOpen}${this.state.invoiceOpenedCount ? `(${this.state.invoiceOpenedCount})` : ''}`}</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right' }}>Paid Invoices:</td>
                      <td>{`$${this.state.invoicePaid}`}</td>
                    </tr>
                  </table>
                </div>
                <div className="Invoice_Stats__Content__Filters--Select">
                  <DatesRangeInput
                    id="custom_date"
                    type="text"
                    placeholder="Select a date"
                    dateFormat="MM/DD/YYYY"
                    iconPosition="right"
                    popupPosition="bottom left"
                    fluid
                    maxDate={moment().format('MM/DD/YYYY')}
                    value={this.state.invoiceDate}
                    onChange={this.handleDateSelection}
                    className="HideForPrint"
                  />
                </div>

              </div>
            </Grid.Column>
            <Grid.Column width={12} className="Invoice_Stats__Content Invoice_Stats__Content__Graph">
              {
                this.state.invoiceStats && !this.state.isInvoiceStatsUpdating &&
                <InvoiceStatistics data={this.state.invoiceStats} />
              }
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment className="Invoice">
          {(isReminding || isCanceling || isUpdating) && (
            <div className="all-opacity-pan" />
          )}
          {isReminding && (
            <Loader
              active

              size="large"
              content="Reminding invoice..."
              style={{ position: "fixed" }}
            />
          )}
          {isCanceling && (
            <Loader
              active
              size="large"
              content="Canceling invoice..."
              style={{ position: "fixed" }}
            />
          )}
          {isUpdating && (
            <Loader
              active
              size="large"
              content="Connecting to stripe account..."
              style={{ position: "fixed" }}
            />
          )}
          {isDeleting && (
            <Loader
              active
              size="large"
              content="Deleting draft invoice..."
              style={{ position: "fixed" }}
            />
          )}
          {isMarkingAsPaid && (
            <Loader
              active
              size="large"
              content="Marking invoice as paid..."
              style={{ position: "fixed" }}
            />
          )}
          <SemanticToastContainer />
          <Grid className="Invoice--manage">
            <Grid.Column floated="left" width={8}>
              <p className="Invoice--title"><span className="client-name">{clientFullName}</span> Invoice List</p>
            </Grid.Column>
            <Grid.Column floated="right" width={8}>
              <Grid.Column style={{ float: "right" }}>
                <Button primary onClick={this.createInvoice}>
                  <Icon link name="plus" />
                  Create Invoice
                </Button>
              </Grid.Column>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              <Divider className="Invoice--divider" />
            </Grid.Column>
          </Grid>
          <Grid centered>
            <Grid.Column width={4} style={{ textAlign: "center" }}>
              <Input icon="search" iconPosition="left" placeholder="Search here" fluid onKeyUp={this.onSearch} />
              {/* <p href="#" className="Invoice--advanced-search">
                Advanced Search
              </p> */}
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={16}>
              <Button.Group widths={5}>
                <Button
                  className={`${
                    status != "all" ? "Invoice--tab" : "Invoice--tab1"
                  }`}
                  onClick={() => this.setState({ status: "all" })}
                >
                  All
                </Button>
                <Button
                  className={`${
                    status != "draft" ? "Invoice--tab" : "Invoice--tab1"
                  }`}
                  onClick={() => this.setState({ status: "draft" })}
                >
                  Draft
                </Button>
                <Button
                  className={`${
                    status != "outstanding" ? "Invoice--tab" : "Invoice--tab1"
                  }`}
                  onClick={() => this.setState({ status: "outstanding" })}
                >
                  Open
                </Button>
                <Button
                  className={`${
                    status != "canceled" ? "Invoice--tab" : "Invoice--tab1"
                  }`}
                  onClick={() => this.setState({ status: "canceled" })}
                >
                  Cancel
                </Button>
                <Button
                  className={`${
                    status != "paid" ? "Invoice--tab" : "Invoice--tab1"
                  }`}
                  onClick={() => this.setState({ status: "paid" })}
                >
                  Paid
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={16}>
                <InvoiceTable
                  data={data}
                  search={search}
                  status={status}
                  onDetail={this.onDetail}
                  isLoading={isLoading}
                  onAction={this.onAction}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Modal
            size="tiny"
            open={open}
            // onClose={() => this.setOpen(false)}
          >
            <Modal.Header>Connect account</Modal.Header>
            <Modal.Content>
              <h3 style={{ fontWeight: "normal" }}>
                Oops, You client has not been registered yet!
              </h3>
              <h5 style={{ fontWeight: "normal" }}>
                <p>Let your client know that it is the easy and good way to send an invoice via stripe on synkbooks. we don't charge any fee from there.</p>
              </h5>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.onBack}>Back</Button>
            </Modal.Actions>
          </Modal>
        </Segment>
      </>
    );
  }
}

const mapStateToProps = state => ({
  editableStatus: state.invoice.editableStatus,
  username: state.session.username,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  copyInvoice,
  defaultInvoice
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoice);
