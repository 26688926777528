import React from "react";
import {
  Table,
  Checkbox,
  Icon,
  Loader,
  Header,
  Container,
  Pagination,
  Label,
} from "semantic-ui-react";
import cs from "classnames";
import _ from 'lodash';
import SelectAction from "components/ui/SelectAction/actionv2";
import "./styles.scss";
import { currencyFormat } from "libraries/utils";

const MAX_ITEMS_PER_PAGE = 20;

export class InvoiceTable extends React.Component {
  state = {
    currentPage: 1,
    selectAll: false,
    selected: [],
    order: {
      DIRECTION: "",
      COLUMN: "",
    },
  };

  handeSelect = (e, { index }) => {
    let selected = this.state.selected;
    if (selected.indexOf(index) > -1)
      selected.splice(selected.indexOf(index), 1);
    else selected.push(index);
    this.setState({ selected: selected });
  };

  onPageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  orderBy = (key) => {
    let data = this.props.data;
    let order = this.state.order;
    order.COLUMN = key;
    order.DIRECTION =
      order.DIRECTION === "descending" ? "ascending" : "descending";
    this.setState({ order: order });
    if (key === "date" || key === "invoice")
      this.setState({
        data: data.sort(
          (a, b) =>
            order.DIRECTION === "descending"
              ? a[key].localeCompare(b[key])
              : b[key].localeCompare(a[key])
        ),
      });
    else
      this.setState({
        data: data.sort(
          (a, b) =>
            order.DIRECTION === "descending" ? a[key] - b[key] : b[key] - a[key]
        ),
      });
  };

  render() {
    const { data, search, status, onDetail, isLoading, onAction } = this.props;
    console.log('onDetailonDetail', onDetail)
    const { currentPage } = this.state;
    const invoices = data
      .filter(
        (el) =>
          el.date.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          el.invoice.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          el.recipient.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          el.status.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
      .filter((el) => {
        if (status === "all") return true;
        else if (status === "outstanding") return el.status === "open";
        else return el.status === status;
      });
    const pageData = invoices.slice(
      (currentPage - 1) * MAX_ITEMS_PER_PAGE,
      currentPage * MAX_ITEMS_PER_PAGE
    );
    
    const totalPages = Math.ceil(invoices.length / MAX_ITEMS_PER_PAGE);
    return (
      <>
        <Table basic stackable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                onClick={() => this.orderBy("date")}
                width={2}
              >
                Date
                <Icon
                  name={cs(
                    "sort",
                    this.state.order.COLUMN === "date" &&
                      this.state.order.DIRECTION
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => this.orderBy("invoice")}
                width={2}
                className="Transactions__Checkbox-Cell"
              >
                Invoice Number
                <Icon
                  name={cs(
                    "sort",
                    this.state.order.COLUMN === "invoice" &&
                      this.state.order.DIRECTION
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                content="Internal Invoice Number"
                className="Transactions__Checkbox-Cell"
              />
              <Table.HeaderCell
                width={3}
                content="Recipient"
                className="Transactions__Checkbox-Cell"
              />
              <Table.HeaderCell
                width={1}
                content="Status"
                className="Transactions__Checkbox-Cell"
              />
              <Table.HeaderCell
                width={2}
                content="Action"
                className="Transactions__Checkbox-Cell"
              />
              <Table.HeaderCell
                width={2}
                onClick={() => this.orderBy("amount")}
                className="Transactions__Checkbox-Cell"
              >
                Amount
                <Icon
                  name={cs(
                    "sort",
                    this.state.order.COLUMN === "amount" &&
                      this.state.order.DIRECTION
                  )}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading ? (
              <Table.Row>
                <Table.Cell colSpan={7} style={{ textAlign: "center" }}>
                  <Loader
                    active
                    size="medium"
                    inline
                    content={<Header icon>Loading invoice data...</Header>}
                  />
                </Table.Cell>
              </Table.Row>
            ) : invoices.length > 0 ? (
              pageData.map((item, index) => (
                <Table.Row
                  key={index}
                  className={cs(
                    "Transactions__Row",
                    { "--Success": item.status === 'paid' },
                    { "--Info": item.status === 'open' },
                    { "--Error": item.status === 'canceled' },
                  )}
                >
                  <Table.Cell onClick={() => onDetail(item.id)}>
                    {item.date}
                  </Table.Cell>
                  <Table.Cell onClick={() => onDetail(item.id)}>
                    {item.invoice}
                  </Table.Cell>
                  <Table.Cell onClick={() => onDetail(item.id)}>
                    {item.internal_number}
                  </Table.Cell>
                  <Table.Cell onClick={() => onDetail(item.id)}>
                    {item.recipient}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => onDetail(item.id)}
                    className="status"
                  >
                    <Label basic size='medium'
                           className={cs(
                               { "--Success": item.status === 'paid' },
                               { "--Info": item.status === 'open' },
                               { "--Error": item.status === 'canceled' },
                           )}>
                      {_.capitalize(item.status)}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <SelectAction item={item} onAction={onAction} />
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => onDetail(item.id)}
                    textAlign="right"
                  >
                    ${currencyFormat(item.amount_due)} USD
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7} style={{ textAlign: "center" }}>
                  <Header>No data available in table.</Header>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {pageData.length > 0 && <Container
          textAlign="center"
          className="Transactions__Pagination-container"
        >
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            firstItem={false}
            lastItem={false}
            onPageChange={this.onPageChange}
          />
        </Container>}

      </>
    );
  }
}

export default InvoiceTable;
