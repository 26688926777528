import React from 'react';
import cs from 'classnames';
import { Dimmer, Loader, Segment, Grid, Header } from 'semantic-ui-react';

import './styles.scss';

const Card = ({
  children, header, actions, autoHide, hideActions, className, loading,
}) => (
  <Segment className={cs('Card', className)}>
    <Dimmer active={loading} inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
    {(header || (!hideActions && actions)) && (
      <Grid verticalAlign="middle">
        <Grid.Column mobile={16} tablet={16} computer={7}>
        {header && <Header as="h3" className="card-header">{header}</Header>}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={9} className="card-actions">
        {!hideActions && actions && <div className={autoHide ? 'autoHide card-actions' : 'card-actions'}>{actions}</div>}
        </Grid.Column>
      </Grid>
    )}
    <div className="card-content">
      {children}
    </div>
  </Segment>
);

Card.defaultProps = { loading: false };

export default Card;
