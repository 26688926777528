import React from 'react';

import {
  PlaidLink
} from 'react-plaid-link';
import { createLinkToken } from 'libraries/api-v2/plaid-service';

class UpdateBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: null };
  }

  async componentDidMount() {
    const response = await createLinkToken(this.props.itemId, true);
    this.setState({ token: response.data.linkToken });
  }

  onSuccess = (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log('onSuccess', publicToken, metadata);
    this.props.onSuccess(this.props.itemId, publicToken, metadata);
  };

  onEvent = (eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log('onEvent', eventName, metadata);
  };

  onExit = (error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log('onExit', error, metadata);
  };

  render() {
    return (
      <PlaidLink
        style={{ border: 'none', background: 'transparent', padding: 0 }}
        token={this.state.token}
        onSuccess={this.onSuccess}
        onEvent={this.onEvent}
        onExit={this.onExit}
      >
        {this.props.children}
      </PlaidLink>
    );
  }
}

export default UpdateBank;