import React from 'react';
import cs from 'classnames';
import isNull from 'lodash-es/isNull';
import { Icon, Image } from 'semantic-ui-react';
import ConnectBank from '../Plaid/ConnectBank';

import './styles.scss';

const SyncPlaidAccounts = ({
  data, onSuccess, onDelete, disableDelete,
}) => (
  <div className="SyncPlaidAccountsTable">
    {data.map(({
      id, name, logo, primaryColor,
    }) => (
      <div key={id} className="ui plaid-account bank-item">
        <div className={cs('bank-logo', { disableDelete })}>
          <div
            role="presentation"
            className="btn-delete"
            onClick={() => onDelete(id)}
          >
            <Icon name="delete" size="small" />
          </div>
          {!isNull(logo) ? <Image src={`data:image/jpeg;charset=utf-8;base64,${logo}`} size="tiny" /> : <i className="image icon large not-found" />}
        </div>
        <div className="bank-name" style={{ color: primaryColor }}>
          {name}
        </div>
      </div>
    ))}
    <ConnectBank onSuccess={onSuccess}>
      <button type="button" className="ui button plaid-account add-account">+</button>
    </ConnectBank>
  </div>
);

export default SyncPlaidAccounts;
