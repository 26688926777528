import React from 'react';
import { connect } from 'react-redux';
import { Segment, Table } from 'semantic-ui-react';

import PnlHeader from '../Header';
import { currencyFormat, splitMonthRangeInput } from "libraries/utils";
import { getProfitAndLossMonth, getProfitAndLossMonthResult } from "libraries/api-v2/transactions-service";
import moment from "moment";
import './styles.scss';
import cs from 'classnames';

const getDefaultYTDDate = () => {
    const fromMonth = moment()
        .startOf('year')
        .format('MM/YYYY');
    const today = moment().format('MM/YYYY');
    return `${fromMonth} - ${today}`;
};

export class MonthPNLView extends React.Component {
    state = {
        monthRange: getDefaultYTDDate(),
        isLoading: false,
        pnlData: null,
    };

    onMonthRangeDateChange = (event, { name, value }) => {
        this.setState({
            [name]: value,
        });
        if (event.currentTarget.tagName !== 'INPUT') {
            this.getProfitAndLoss(value);
        }
    };

    componentDidMount() {
        this.getProfitAndLoss();
    }

    async receiveProfitAndLossResult(taskId) {
        while (true) {
            try {
                const result = await getProfitAndLossMonthResult(taskId);
                if (result !== 'pending') {
                    const { status, data } = result;
                    if (status === 'success') {
                        this.setState({
                            isLoading: false,
                            pnlData: data,
                        });
                    } else if (status === 'error') {
                        this.setState({ isLoading: false });
                    }
                    break;
                }
            } catch (e) {
                this.setState({ isLoading: false });
                console.log('Error/receiveMessage', e);
                break;
            }
        }
    }

    getProfitAndLoss = (monthRange = this.state.monthRange) => {
        const [startMonth, endMonth] = splitMonthRangeInput(monthRange);
        if (startMonth.isValid() && endMonth.isValid()) {
            this.setState({ isLoading: true });
            getProfitAndLossMonth(startMonth.format('YYYY-MM-DD'), endMonth.format('YYYY-MM-DD'))
                .then(res => {
                    const { id } = res;
                    this.receiveProfitAndLossResult(id);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ isLoading: false });
                });
        }
    };

    render() {
        const { pnlData } = this.state;
        return (
            <>
                <PnlHeader
                    updatePnl={this.props.updatePnl}
                    hideTable={this.props.hideTable}
                    monthRange={this.state.monthRange}
                    onMonthRangeDateChange={this.onMonthRangeDateChange}
                />
                <Segment className="Profit-And-Loss__Segment" attached loading={this.state.isLoading}>
                    {pnlData && (
                        <Table className="Profit-And-Loss" columns={pnlData.months.length} basi="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    {pnlData.months.map((month, index) => (
                                        <Table.HeaderCell key={index} textAlign="right">
                                            {moment(month).format('MM/YYYY')}
                                        </Table.HeaderCell>
                                    ))}
                                    <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="Profit-And-Loss__Category-Title">
                                        INCOME
                                    </Table.Cell>
                                    {pnlData.months.map((month, index) => (
                                        <Table.Cell key={index} />
                                    ))}
                                    <Table.Cell />
                                </Table.Row>
                                {renderItem(pnlData.incomeData)}
                                <Table.Row>
                                    <Table.Cell className="Profit-And-Loss__Category-Title">
                                        EXPENSES
                                    </Table.Cell>
                                    {pnlData.months.map((month, index) => (
                                        <Table.Cell key={index} />
                                    ))}
                                    <Table.Cell />
                                </Table.Row>
                                {renderItem(pnlData.expensesData)}
                                {renderItem(pnlData.otherDeductions, true)}
                                {renderItem(pnlData.totalData, false, true)}
                                <Table.Row>
                                    {pnlData.months.map((month, index) => (
                                        <Table.Cell key={index} />
                                    ))}
                                    <Table.Cell />
                                    <Table.Cell />
                                </Table.Row>
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.Cell className="Profit-And-Loss__Category-Title">
                                        REMAINING BALANCES
                                    </Table.Cell>
                                    {pnlData.months.map((month, index) => (
                                        <Table.Cell key={index} />
                                    ))}
                                    <Table.Cell />
                                </Table.Row>
                                {renderItem(pnlData.balanceData)}
                            </Table.Footer>
                        </Table>
                    )}
                </Segment>
            </>
        );
    }
}

const renderItem = (data, isOther = false, isTotal = false) => {
    return (
        <>
            {Object.keys(data).map((key, kIndex) => {
                return (
                    <Table.Row key={kIndex}>
                        <Table.Cell
                            className={cs(
                                'Profit-And-Loss__Cell--Item',
                                { '--Other': isOther },
                                { '--Total': isTotal }
                            )}>
                            {key}
                        </Table.Cell>
                        {data[key].map((item, index) => (
                            <Table.Cell
                                className={cs('Profit-And-Loss__Cell--Item', { '--Total': isTotal })}
                                textAlign="right"
                                key={index}>
                                ${currencyFormat(item)}
                            </Table.Cell>
                        ))}
                        <Table.Cell
                            className={cs('Profit-And-Loss__Cell--Item', { '--Total': isTotal })}
                            textAlign="right">
                            ${currencyFormat(data[key].reduce((total, cur) => (total += cur), 0))}
                        </Table.Cell>
                    </Table.Row>
                );
            })}
        </>
    );
};

/* istanbul ignore next */
const mapStateToProps = ({
    session: {
        userProfile: { form },
    },
}) => ({
    userProfile,
    form,
});

export default connect(
    mapStateToProps,
    null
)(MonthPNLView);
