import React from "react";
import {
  Tab,
  Segment,
  Button,
  Input,
  Grid,
  Select,
  Icon,
  Dropdown,
  Container,
  Loader,
  Header,
  Dimmer,
  Checkbox,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { getCategories, getInstitutionById } from "libraries/api-service";
import { getInstitutions } from "libraries/api-v2/institution-service";
import { exportTransactionData, getExportTransactionResult } from "libraries/api-v2/transactions-service";
import { exportZip, calculateInputStep } from "libraries/utils";
import withNotifications from "components/hocs/WithNotifications";
import getFilteredTransactions from "store/selectors/transactions";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import { FILTERS } from "libraries/transactionsFilterManager";
import "./styles.scss";
import * as ReactGA from "react-ga";

const { REACT_APP_EXPORT_DATA_ENDPOINT } = process.env;

export class Filters extends React.Component {
  state = {
    moreFilters: false,
    panes: [
      { menuItem: FILTERS.TABS.ALL_TRANSACTIONS.replace("_", " ") },
      { menuItem: FILTERS.TABS.INCOME },
      { menuItem: FILTERS.TABS.EXPENSE },
    ],
    categories: [],
    isExporting: false,
    accountNames: [],
  };

  componentDidMount() {
    this.getCategories(this.props.selectedForm);
    this.fetchInstitutions();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.allTransactions.length &&
      this.props.allTransactions.length !== prevProps.allTransactions.length
    ) {
      this.fetchInstitutions();
    }
  }

  fetchInstitutions = () => {
    // Get connected bank account list
    getInstitutions()
      .then(this.addLogos)
      .then((linkedAccounts) => {
        let accounts = [];
        linkedAccounts.forEach((linkedAccount) => {
          for (const item of linkedAccount.items) {
            item.accounts
              .filter(
                (account) =>
                  account.included === "True" || account.included === true
              )
              .map((account) => {
                // const accountName = `${linkedAccount.institutionName} ${account.accountMask}`;
                const accountName = account.account;
                if (!accounts.some((acc) => acc.key === accountName)) {
                  accounts.push({
                    key: accountName,
                    value: accountName,
                    text: accountName,
                  });
                }
              });
          }
        });

        // Get account list included inside of transaction list
        // this.getAccountNames()
        //   .forEach((account) => {
        //     if (!accountNames.some(item => item.key === account.key)) {
        //       accountNames.push(account);
        //     }
        //   })

        let sortedAccounts = accounts.sort((acc1, acc2) => {
          if (acc1.key < acc2.key) return -1;
          if (acc1.key > acc2.key) return 1;
          return 0;
        });

        sortedAccounts.push({
          key: "Manual Transactions ",
          value: "Manual Transactions ",
          text: "Manual Transactions ",
        });
        this.setState({ accountNames: sortedAccounts });
      });
  };

  addLogos = (institutions) => {
    const logos = {};

    const promises = institutions
      .map((inst) => {
        if (Object.prototype.hasOwnProperty.call(logos, inst.institutionId))
          return null;
        logos[inst.institutionId] = "";
        return getInstitutionById(inst.institutionId).then((res) => {
          logos[inst.institutionId] = res;
        });
      })
      .filter(Boolean);

    return Promise.all(promises).then(() =>
      institutions.map((inst) => ({ ...inst, ...logos[inst.institutionId] }))
    );
  };

  getCategories = (formName = "Schedule C") => {
    const uncategorized = {
      key: "Uncategorized",
      text: "Uncategorized",
      value: "Uncategorized",
    };
    this.setState({ isLoadingCategories: true }, () => {
      getCategories(formName)
        .then((items) =>
          items.map(({ name, type }) => ({
            key: `${name}-${type}`,
            text: name,
            value: name,
            description: type,
          }))
        )
        .then((categories) => [...categories, uncategorized])
        .then((categories) =>
          this.setState({ categories, isLoadingCategories: false })
        );
    });
  };

  toggleMoreFilters = () =>
    this.setState((prevState) => ({ moreFilters: !prevState.moreFilters }));

  getMonthsOptions = () => {
    const months = moment.months();
    return [
      {
        key: -1,
        value: -1,
        text: "All",
      },
      {
        key: -2,
        value: -2,
        text: "Year to date",
      },
      {
        key: -3,
        value: -3,
        text: "Previous year",
      },
      ...months.map((month, idx) => ({
        key: idx,
        value: idx,
        text: month,
      })),
      {
        key: months.length,
        value: months.length,
        text: "Custom",
      },
    ];
  };

  async receiveExportDataResult(taskId) {
    while (true) {
      try {
        const result = await getExportTransactionResult(taskId);
        if (result !== "pending") {
          const { status, data } = result;
          if (status === "success") {
            ReactGA.event({
              category: "Download Transactions",
              action: "Client pressed the download button",
            });
            this.setState({ isExporting: false });
            const exportData = JSON.parse(data);
            exportZip(exportData);
          } else if (status === "error") {
            this.props.pushNotification(
              "NOT_TRANSACTIONS_DOWNLOAD",
              "Download failed",
              "An error occurred while trying to download your transactions data. Please try again.",
              "error",
              5000
            );
            this.setState({ isExporting: false });
          }
          break;
        }
      } catch (e) {
        this.setState({ isExporting: false });
        this.props.pushNotification(
          "NOT_TRANSACTIONS_DOWNLOAD",
          "Download failed",
          "An error occurred while trying to download your transactions data. Please try again.",
          "error",
          5000
        );
        console.log('Error/receiveMessage', e);
        break;
      }
    }
  }

  handleClick = () => {
    const self = this;
    this.setState({ isExporting: true });
    // const { filteredTransactions } = this.props;
    // const transactionsIds = filteredTransactions.map(
    //   ({ transaction_id: id }) => id
    // );
    const transactionsIds = this.props.transactionIds;
    const accountIds = this.state.accountNames.map(({ value: id }) => id);
    const params = {
      FunctionName: REACT_APP_EXPORT_DATA_ENDPOINT,
      Payload: JSON.stringify({
        email: this.props.username,
        transactionsIds,
        accountIds: this.props.filters.ACCOUNTNAME.length
          ? this.props.filters.ACCOUNTNAME
          : accountIds,
      }),
    };

    exportTransactionData(params)
      .then((res) => {
        const { id } = res;
        this.receiveExportDataResult(id);
      })
      .catch((err) => {
        self.props.pushNotification(
          "NOT_TRANSACTIONS_DOWNLOAD",
          "Download failed",
          "An error occurred while trying to download your transactions data. Please try again.",
          "error",
          5000
        );
        self.setState({ isExporting: false });
        console.log(err);
      });
  };

  // getAccountNames = () => {
  //   const accounts = {};
  //   return (
  //     this.props.allTransactions
  //       // get all the different accounts names (ins + mask)
  //       .reduce((options, { institution, mask }) => {
  //         const accountName = `${institution} ${mask}`;
  //         if (accounts[accountName] || institution === "Manual Transactions")
  //           return options;
  //         accounts[accountName] = true;
  //         const newOption = {
  //           key: accountName,
  //           value: accountName,
  //           text: accountName,
  //         };
  //         return [...options, newOption];
  //       }, [])
  //       // order them alfabetically
  //       .sort((acc1, acc2) => {
  //         if (acc1.key < acc2.key) return -1;
  //         if (acc1.key > acc2.key) return 1;
  //         return 0;
  //       })
  //   );
  // };

  render() {
    const today = moment().format("MM/DD/YYYY");
    return (
      <>
        <div className="Filters">
          <Dimmer active={this.state.isExporting} inverted>
            <Loader
              active
              size="large"
              content={<Header icon>Exporting data...</Header>}
              style={{ position: "fixed" }}
            />
          </Dimmer>
          <Tab
            className="Filters__Tabs"
            menu={{ secondary: true, pointing: true }}
            onTabChange={this.props.onTabChange}
            panes={this.state.panes}
          />
          <Container textAlign="right" className="HideForPrint">
            <Button
              onClick={this.props.handleCreateTransaction}
              size="medium"
              content="NEW TRANSACTION"
              icon="add"
              labelPosition="right"
              primary
            />
            <Button
              onClick={this.toggleMoreFilters}
              size="medium"
              content="FILTERS"
              icon="sliders horizontal"
              labelPosition="right"
              primary
            />
          </Container>
        </div>
        {this.state.moreFilters && (
          <Segment className="Filters__Segment">
            <div columns="equal" className="Filters__Segment__Grid">
              <Grid columns={5}>
                <Grid.Column mobile={8} tablet={5} computer={4}>
                  <div>Category</div>
                  <Dropdown
                    id="category"
                    onChange={this.props.onCategoryChange}
                    value={this.props.filters.CATEGORIES}
                    placeholder="Select"
                    options={this.state.categories}
                    fluid
                    multiple
                    search
                    selection
                    scrolling
                    deburr
                    loading={this.state.isLoadingCategories}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={5} computer={4}>
                  <div>Date</div>
                  {this.props.filters.IS_SELECTING_CUSTOM_DATE ? (
                    <DatesRangeInput
                      value={this.props.filters.CUSTOM_DATE}
                      placeholder="Select a date range"
                      onChange={this.props.onCustomDateChange}
                      closable
                      id="filters-custom-date"
                      dateFormat="MM/DD/YYYY"
                      popupPosition="bottom right"
                      maxDate={today}
                      onKeyDown={this.onKeyEvent}
                      onClear={this.props.hideDatePicker}
                      clearable
                      clearIcon={<Icon name="remove" color="red" />}
                    />
                  ) : (
                    <Select
                      id="month"
                      type="number"
                      onChange={this.props.onMonthChange}
                      value={this.props.filters.MONTH}
                      size="small"
                      placeholder="Select a month"
                      options={this.getMonthsOptions()}
                    />
                  )}
                </Grid.Column>
                <Grid.Column mobile={8} tablet={5} computer={4}>
                  <div>
                    <div>Amount</div>
                    <Input
                      id="amountMin"
                      type="number"
                      icon="dollar"
                      iconPosition="left"
                      onChange={this.props.onAmountChange}
                      onBlur={this.props.onAmountBlur}
                      step={calculateInputStep(this.props.filters.AMOUNT.MIN)}
                      value={this.props.filters.AMOUNT.MIN}
                      style={{ width: "8em" }}
                    />
                    <span className="Filters__Range-Divider" />
                    <Input
                      id="amountMax"
                      type="number"
                      icon="dollar"
                      iconPosition="left"
                      onBlur={this.props.onAmountBlur}
                      onChange={this.props.onAmountChange}
                      step={calculateInputStep(this.props.filters.AMOUNT.MAX)}
                      value={this.props.filters.AMOUNT.MAX}
                      style={{ width: "8em" }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={5} computer={4}>
                  <div>Account name</div>
                  <div style={{ width: "206px" }}>
                    <Dropdown
                      id="accountName"
                      onChange={this.props.onAccountNameChange}
                      value={this.props.filters.ACCOUNTNAME}
                      size="small"
                      placeholder="Select an account name"
                      options={this.state.accountNames}
                      multiple
                      search
                      selection
                      scrolling
                      deburr
                      fluid
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={5} tablet={3} computer={2}>
                  <div>Export</div>
                  <Button
                    primary
                    icon
                    labelPosition="right"
                    onClick={this.handleClick}
                  >
                    Export
                    <Icon name="download" />
                  </Button>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={5} computer={10}>
                  <div>Find a transaction</div>
                  <Input
                    id="search"
                    type="text"
                    onChange={this.props.onSearchChange}
                    value={this.props.filters.SEARCH}
                    placeholder="Search here"
                    icon="search"
                    iconPosition="left"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={5} computer={4}>
                  <div style={{ marginBottom: "25px" }} />
                  <Checkbox
                    label="Show Removed Transactions"
                    onChange={this.props.onDuplicateStatusChange}
                  />
                </Grid.Column>
              </Grid>
            </div>
          </Segment>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  token: state.session.token,
  username: state.session.username,
  selectedForm: state.session.userProfile.form,
  allTransactions: state.transactions.allTransactions,
  transactionIds: state.transactions.transactionIds,
  filteredTransactions: getFilteredTransactions(state),
});

export default connect(
  mapStateToProps,
  null
)(withNotifications(Filters));
