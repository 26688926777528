import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Icon, Loader, Dimmer,
} from 'semantic-ui-react';

import Card from 'components/ui/Card';
import { getProfile } from 'libraries/api-v2/user-service';

import hasSubscription from 'store/selectors/subscription';
import ProfileDetailsEdit from './ProfileDetailsEdit';
import ProfileDetailsReadOnly from './ProfileDetailsReadOnly';
import { DeactivateAccount, Unsubscribe } from '..';

class ProfileDetails extends Component {
  state = { editMode: false };

  componentDidMount() {
    const { username } = this.props;
    getProfile(username).then(user => this.setState({ user }));
  }

  toggleEdit = () => this.setState(prevState => ({ editMode: !prevState.editMode }));

  onsuccessfulySaved = (user) => {
    this.setState({ user }, () => this.toggleEdit());
  };

  getProfileImgName = (fullName) => {
    const splitName = fullName.split(' ');
    let imgName = null;
    if (splitName.length > 1) imgName = `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    else imgName = `${splitName[0].charAt(0)}${splitName[0].charAt(1)}`;
    return imgName.toUpperCase();
  };

  render() {
    const { editMode, user } = this.state;
    const { accountType, isSubscribed } = this.props;
    const actions = [
      <button type="button" key="ProfileDetails-btnEdit" onClick={this.toggleEdit}>
        <Icon name="edit" />
      </button>,
    ];
    if (!user) return <Card header="Profile Details" actions={actions} autoHide loading/>;
    if (!editMode) {
      return (
        <Card header="Profile Details" actions={actions} autoHide hideActions={editMode}>
          {!isSubscribed && <Dimmer active inverted />}
          <br />
          <ProfileDetailsReadOnly
            {...user}
            accountType={accountType}
            imgName={this.getProfileImgName(user.fullName)}
          />
        </Card>
      );
    }
    return (
      <Fragment>
        <Card header="Profile Details" actions={actions} autoHide hideActions={editMode}>
          <br />
          <ProfileDetailsEdit
            {...user}
            imgName={this.getProfileImgName(user.fullName)}
            onCancel={this.toggleEdit}
            onSuccess={this.onsuccessfulySaved}
          />
        </Card>
        <DeactivateAccount />
        <Unsubscribe />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  username: state.session.username,
  accountType: state.session.userInfo.accountType,
  isSubscribed: hasSubscription(state),
});

export default connect(
  mapStateToProps,
  null,
)(ProfileDetails);
