import React from "react";
import { Grid, Header, Loader, Modal, Button } from "semantic-ui-react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import size from "lodash.size";

import CurrentPlan from "components/presentationals/Subscription/Plan/Current";
import AvailablePlan from "components/presentationals/Subscription/Plan/Available";
import Payment from "components/containers/Subscription/Payment";
import withNotifications from "components/hocs/WithNotifications";

import {
  setCurrentSubscription,
  setStripePlans,
  setHasPaymentMethod,
  setCancelSubscription,
  setIsPaymentModalOpen,
  setFutureSubscription,
} from "store/actions/subscription";
import { setUserProfile } from "store/actions/session";
import {
  getSubscriptionPlans,
  cancelSubscriptionPlan,
  getSubscription,
} from "libraries/api-v2/stripe-service";
import { getPaymentMethod } from "libraries/api-v2/stripe-service";
import { changeSubscription } from "libraries/api-v2/stripe-service";

import "./styles.scss";

export class Subscription extends React.Component {
  state = {
    isExpired: false,
    isLoadingCurrentPlan: false,
    isLoadingAvailablePlans: false,
    isLoadingCancelPlan: false,
    isLoadingPaymentMethod: true,
    isSubscribing: false,
    isModalOpen: false,
    status: null,
  };

  componentDidMount() {
    const { subscriptionStatus, createdAt, subscription } = this.props;
    console.log({ createdAt, subscription });
    subscriptionStatus === "canceled" && this.setState({ isExpired: true });
    this.setState({ isLoadingCurrentPlan: true }, () =>
      getSubscription()
        .then(this.changeCurrentPlan)
        .catch((err) => {
          this.setState({ isLoadingCurrentPlan: false });
        })
    );

    this.setState({ isLoadingAvailablePlans: true }, () =>
      getSubscriptionPlans()
        .then((res) => {
          this.setState({ isLoadingAvailablePlans: false });
          this.props.setStripePlans(res);
        })
        .catch((err) => {
          this.setState({ isLoadingAvailablePlans: false });
          this.props.pushNotification(
            "NOT_AVAILABLE_PLANS",
            "Operation failed",
            "An error ocurred while trying to get your available plans.Please try again.",
            "error",
            8000
          );
        })
    );

    this.fetchPaymentMethod();
  }

  isValidSubscriptionChange = (newPlanName) => {
    const oldPlan = this.props.subscription.current;
    if (!size(oldPlan)) return true; // user doesnt have subscription or free trial has ended
    const isDowngrade = this.isDowngrade(oldPlan, newPlanName);
    return (isDowngrade && this.canDowngrade(oldPlan)) || !isDowngrade;
  };

  changeCurrentPlan = (newPlan) => {
    this.props.setCurrentSubscription(newPlan);
    this.props.setCancelSubscription(newPlan.cancel_at_period_end);
    this.props.setUserProfile({ subscriptionStatus: "active" });
    this.setState({
      isLoadingCurrentPlan: false,
      status: newPlan.status,
      isExpired: false,
    });
  };

  isDowngrade = (oldPlan, newPlanName) =>
    oldPlan.nickname.includes("Premium annually") &&
    newPlanName.includes("Premium monthly");

  canDowngrade = (oldPlan) => {
    const secondsLeft = oldPlan.endDate - moment().unix();
    const daysLeft = Math.floor(secondsLeft / (3600 * 24));
    return daysLeft < 31;
  };

  fetchPaymentMethod = () =>
    getPaymentMethod()
      .then((payment) => {
        this.props.setHasPaymentMethod(true);
        this.setState({
          isLoadingPaymentMethod: false,
          payment,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingPaymentMethod: false,
        });
        this.props.setHasPaymentMethod(false);
      });

  cancelSubscription = () => {
    this.setState({ isLoadingCancelPlan: true }, () =>
      cancelSubscriptionPlan()
        .then((res) => {
          this.props.setCancelSubscription(true);
          this.setState({ isLoadingCancelPlan: false, isModalOpen: false });
        })
        .catch((err) => {
          this.setState({ isLoadingCancelPlan: false });
          this.props.pushNotification(
            "NOT_CANCELLED_PLAN",
            "Operation failed",
            "An error ocurred while trying to cancel your subscription. Please try again.",
            "error",
            7000
          );
        })
    );
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  isTrialing = () => this.state.status === "trialing";

  isPremiumActive = () => this.state.status === "active";

  onChangeSubscription = async (name, planId, couponId = "") => {
    const { pushNotification, subscription } = this.props;
    // if (this.isValidSubscriptionChange(name)) {
    if (subscription.hasPaymentMethod) {
      await changeSubscription(planId, couponId)
        .then((newSubscription) => {
          this.setState({ isSubscribing: false });
          pushNotification(
            "SUBSCRIPTION_CHANGE_SUCCESS",
            "Subscription changed",
            `You have changed your subscription to ${name} successfully.`,
            "success",
            8000
          );
          this.changeCurrentPlan(newSubscription);
          if (this.props.subscription.future)
            this.props.setFutureSubscription(null, null);
        })
        .catch((err) => {
          this.setState({ isSubscribing: false });
          if (this.props.subscription.future.planId)
            this.props.setFutureSubscription(null, null);
          pushNotification(
            "SUBSCRIPTION_CHANGE_FAIL",
            "Subscription change failed",
            `An error ocurred while changing your subscription to ${name}`,
            "error",
            8000
          );
        });
    } else {
      this.props.setIsPaymentModalOpen(true);
      this.props.setFutureSubscription(name, planId);
    }
    // } else {
    //   this.props.pushNotification('SUBSCRIPTION_DOWNGRADE_FAIL',
    //     'Unable to downgrade plan',
    //     'You are only able to downgrade your plan in the last 30 days of your annual plan.',
    //     'error', 7000);
    // }
  };

  render() {
    const { REACT_APP_STRIPE_APIKEY } = process.env;
    const { isLoadingPaymentMethod, payment } = this.state;
    let isPlanAvailable = false;
    const { subscription, createdAt } = this.props;
    isPlanAvailable = Object.keys(subscription.current).length;
    const subscriptionPlan = isPlanAvailable && subscription.current;

    return (
      <Grid centered>
        {this.state.isModalOpen && (
          <Modal
            open
            size="mini"
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header
              content="CANCEL SUBSCRIPTION"
              style={{ backgroundColor: "#e06665", color: "white" }}
            />
            <Modal.Content>
              <p>
                By cancelling your plan
                <b> you won&lsquo;t be charged </b>
                when your free trial ends, but keep in mind that
                <b> you won&lsquo;t be able to keep using Synkbooks.</b>
              </p>
              <br />
              Are you sure you want to
              <b> CANCEL </b>
              your subscription?
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                content="Continue"
                onClick={this.cancelSubscription}
                loading={this.state.isLoadingCancelPlan}
              />
              <Button content="Cancel" onClick={this.closeModal} />
            </Modal.Actions>
          </Modal>
        )}
        <Grid.Column computer="14" tablet="14" mobile="16">
          <Header
            as="h3"
            textAlign="center"
            className="Current-Plan__Card-Title"
            content="SUBSCRIPTION"
          />
          {subscription.current && (
            <CurrentPlan
              isLoading={this.state.isLoadingCurrentPlan}
              plan={subscriptionPlan}
              isExpired={this.state.isExpired}
              openModal={this.openModal}
              isLoadingCancel={this.state.isLoadingCancelPlan}
              isTrialing={this.isTrialing()}
              isPremiumActive={this.isPremiumActive()}
            />
          )}
        </Grid.Column>
        <Grid.Column computer="14" tablet="14" mobile="16">
          <Header
            as="h3"
            textAlign="center"
            className="Current-Plan__Card-Title"
            content="UPGRADE TO A PREMIUM PLAN"
          />
          {// eslint-disable-next-line no-nested-ternary
          size(subscription.stripePlans) > 0 ? (
            subscription.stripePlans.map(
              (item, i) =>
                !item.name.includes("Free trial") && (
                  <AvailablePlan
                    planItem={item}
                    isloading={this.state.isLoadingAvailablePlans}
                    key={item.plan_id}
                    isSubscribing={this.state.isSubscribing}
                    onChangeSubscription={this.onChangeSubscription}
                  />
                )
            )
          ) : this.state.isLoadingAvailablePlans ? (
            <Loader active inline="centered" />
          ) : (
            <div className="margin No-Available-Plans">
              <Header as="h3">No plans available</Header>
            </div>
          )}
        </Grid.Column>
        <Grid.Column computer="14" tablet="14" mobile="16">
          <Header
            as="h3"
            textAlign="center"
            className="Current-Plan__Card-Title"
            content="PAYMENT"
          />
          <StripeProvider apiKey={REACT_APP_STRIPE_APIKEY}>
            <Elements>
              <Payment
                isloading={isLoadingPaymentMethod}
                paymentItem={payment}
                updatePaymentMethod={this.fetchPaymentMethod}
                onPaymentAdded={this.onChangeSubscription}
              />
            </Elements>
          </StripeProvider>
        </Grid.Column>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: {
    userProfile: { subscriptionStatus, paymentsStatus, createdAt },
  },
  subscription,
}) => ({
  subscriptionStatus,
  paymentsStatus,
  subscription,
  createdAt,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentSubscription,
      setStripePlans,
      setHasPaymentMethod,
      setCancelSubscription,
      setIsPaymentModalOpen,
      setFutureSubscription,
      setUserProfile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(Subscription));
