import apiRequestV2, { baseApiV2Path } from "./api";
import uuidv4 from 'uuid/v4';

export const getSubscriptionPlans = () =>
    apiRequestV2('get', `${baseApiV2Path}/subscription/get_plans`).then(res =>
        res.data
            .filter(plan => !plan.name.includes("Free trial"))
            .sort((a, b) => {
                if (a.name < b.name) return 1;
                if (a.name > b.name) return -1;
                return 0;
            })
            .map(plan => ({ ...plan, amount: plan.amount, id: uuidv4() }))
    );

export const getSubscription = () => apiRequestV2('get', `${baseApiV2Path}/subscription/get_subscription`).then(res => res.data);

export const cancelSubscriptionPlan = () => apiRequestV2('post', `${baseApiV2Path}/subscription/cancel_subscription`).then(res => res.data);

export const validatePromotionCode = promoCode =>
    apiRequestV2('post', `${baseApiV2Path}/subscription/validate_promotion_code`, { body: {promoCode: promoCode } }).then(res => res.data);

export const changeSubscription = (planId, couponId) =>
    apiRequestV2('post', `${baseApiV2Path}/subscription/change_subscription`, {
        body: {
            plan_id: planId,
            coupon_id: couponId,
        },
    }).then(res => res.data);

export const addPaymentMethod = tokenId =>
    apiRequestV2('post', `${baseApiV2Path}/subscription/add_payment_method`, {
        body: {
            card_token_id: tokenId,
        },
    }).then(res => res.data);

export const getPaymentMethod = () => apiRequestV2('get', `${baseApiV2Path}/subscription/get_payment_method`).then(res => res.data.card);
