import apiRequestV2, { baseApiV2Path } from "./api";

export const getKeys = () => apiRequestV2('get', `${baseApiV2Path}/plaid/get_keys`);

export const getPublicToken = (itemId) => {
    return apiRequestV2('post', `${baseApiV2Path}/plaid/get_public_token`, { body: { item_id: itemId } });
}

export const createLinkToken = (itemId = null, updateMode = false) => {
    return apiRequestV2('post', `${baseApiV2Path}/plaid/create_link_token`, { body: { item_id: itemId, update_mode: updateMode  } });
}

export const clearItemErrors = (itemId) => {
    return apiRequestV2('post', `${baseApiV2Path}/plaid/clear_item_error`, { body: { item_id: itemId } });
}

export const removeInstitutions = id => apiRequestV2('del', `${baseApiV2Path}/plaid/unlink_institution`, { body: { id } });

export const markAsRemoved = id => apiRequestV2('post', `${baseApiV2Path}/plaid/mark_as_removed`, { body: { id } });

export const resetLogin = itemId => {
    return apiRequestV2('post', `${baseApiV2Path}/plaid/reset_login`, { body: { item_id: itemId } });
}
