import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Responsive, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { logout } from 'libraries/api-service';

import { clearUserDidLogin, setIsLoggingOut } from 'store/actions/session';
import { clearTransactions } from 'store/actions/transactions';
import { clearOnboarding } from 'store/actions/onboarding';
import { clearPlaidEnvironment } from 'store/actions/plaid';
import { clearSubscription } from 'store/actions/subscription';
import { clearPnlData } from 'store/actions/profitAndLoss';

import './styles.scss';
import Routes from 'routes';
import withNotifications from 'components/hocs/WithNotifications';
import LoggedOutMenu from './LoggedOutMenu';

class HeaderProfileMenu extends React.Component {
  state = {
    isMobile: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile(window.innerWidth));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile(window.innerWidth));
  }

  updateIsMobile = (width) => {
    this.setState({ isMobile: width < Responsive.onlyTablet.minWidth });
  }

  handleSignOut = () => {
    const {
      history,
      pushNotification,
    } = this.props;
    setIsLoggingOut(true);
    logout()
      .then(() => {
        this.props.clearTransactions();
        this.props.clearUserDidLogin();
        this.props.clearOnboarding();
        this.props.clearPlaidEnvironment();
        this.props.clearPnlData();
        this.props.clearSubscription();
        history.push(Routes.Index);
      })
      .catch((err) => {
        pushNotification(
          'ERROR_LOGGING_OUT',
          'Error logging out',
          err.message,
          'error',
          5000,
        );
      })
      .finally(() => {
        this.props.setIsLoggingOut(false);
      });
  };

  isActive = (key) => {
    const { location: { pathname } } = this.props;
    return !!pathname.match(key);
  };

  getOptions = () => {
    const {
      history,
      location,
      onboarding,
      userProfile: { fullName },
    } = this.props;

    const userNameOption = [{
      key: 'User_mobile',
      text: '',
      content: (
        <>
          Logged as
          <br />
          <i>{this.getShortedName(fullName)}</i>
        </>),
      disabled: true,
    }];

    const loggedInOptions = [
      {
        key: 'Header_MyProfile',
        text: 'My profile',
        onClick: () => history.push(Routes.MyProfile),
        selected: this.isActive('/my-profile'),
        active: this.isActive('/my-profile'),
      },
      {
        key: 'Header_Subscription',
        text: 'Subscription',
        onClick: () => history.push(Routes.Subscription),
        selected: this.isActive('/subscription'),
        active: this.isActive('/subscription'),
      },
      {
        key: 'Header_Invoicing',
        text: 'Invoicing',
        onClick: () => history.push(Routes.DashboardInvoicing),
        selected: this.isActive('/invoicing'),
        active: this.isActive('/invoicing'),
      },
    ];
    const commonOptions = [
      {
        key: 'Header_Logout',
        text: 'Log out',
        onClick: () => this.handleSignOut(),
      },
    ];
    const mobileOptions = [
      {
        key: 'mobile_reports',
        text: 'Reports',
        onClick: () => history.push(Routes.DashboardReports),
        selected: this.isActive('/reports'),
        active: this.isActive('/reports'),
      },
    ];
    const dashboardOption = [{
      key: 'lp_dashboard',
      text: 'Dashboard',
      onClick: () => history.push(Routes.DashboardTransactions),
      selected: this.isActive('/dashboard/transactions'),
      active: this.isActive('/dashboard/transactions'),
    }];

    const { isMobile } = this.state;

    return [
      ...(isMobile ? userNameOption : []),
      ...((location.pathname === Routes.Index || isMobile) ? dashboardOption : []),
      ...(isMobile ? mobileOptions : []),
      ...(onboarding === 'True') ? loggedInOptions : [],
      ...commonOptions,
    ];
  }

  getShortedName = (fullName) => {
    // if username has its text separated by spaces it means it has a lastname
    // if user has lastname we return name + lastname
    // if it's just 1 word, return first 10 chars.
    const splittedName = fullName.split(/\s/);
    const hasLastname = splittedName.length >= 2;
    if (hasLastname) {
      return `${splittedName[0]} ${splittedName[1]}`;
    }
    return fullName.slice(0, 10);
  };

  getMenuTrigger = () => {
    if (this.state.isMobile) return (<Icon name="bars" />);
    const { userProfile: { fullName } } = this.props;
    return this.getShortedName(fullName);
  }

  render() {
    const { userProfile: { fullName } } = this.props;
    const { isMobile } = this.state;
    return (
      fullName
        ? (
          <Dropdown
            item
            trigger={this.getMenuTrigger()}
            selectOnNavigation={false}
            selectOnBlur={false}
            options={this.getOptions()}
            icon={null}
            pointing={isMobile ? 'top right' : 'top'}
            className="Dashboard__Avatar--menu"
          />
        )
        : null
          // <LoggedOutMenu />
        
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  onboarding: state.session.userInfo.onboarding,
  userProfile: state.session.userProfile,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  clearUserDidLogin,
  clearOnboarding,
  clearPlaidEnvironment,
  clearTransactions,
  clearPnlData,
  setIsLoggingOut,
  clearSubscription,
}, dispatch);

const wrapperComponent = withRouter(withNotifications(HeaderProfileMenu));
export default connect(mapStateToProps, mapDispatchToProps)(wrapperComponent);
