import React, { Component } from 'react';
import Filters from 'components/containers/Filters';
import { FILTERS } from 'libraries/transactionsFilterManager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFilter, setIsSelectingCustomDate } from 'store/actions/filters';

export class TransactionsFilters extends Component {
  onTabChange = (event, data) => {
    const tabs = Object.keys(FILTERS.TABS);
    const selectedTab = tabs[data.activeIndex];
    const hasFilterApplied = this.props.filters.TAB === selectedTab;

    if (!hasFilterApplied) {
      this.props.addFilter('TAB', selectedTab);
    }
  };

  onAmountBlur = () => {
    const { filters } = this.props;
    const newAmountRange = {
      MIN: Number(filters.AMOUNT.MIN),
      MAX: Number(filters.AMOUNT.MAX),
    };
    this.props.addFilter('AMOUNT', newAmountRange);
  }

  onAmountChange = (event, { id, value }) => {
    const newAmountRange = { MIN: 0, MAX: 0 };
    if (id === 'amountMin') {
      newAmountRange.MIN = value;
      newAmountRange.MAX = this.props.filters.AMOUNT.MAX;
    } else {
      newAmountRange.MIN = this.props.filters.AMOUNT.MIN;
      newAmountRange.MAX = value;
    }
    this.props.addFilter('AMOUNT', newAmountRange);
  };

  onMonthChange = (event, { value }) => {
    if (value === 12) {
      this.props.setIsSelectingCustomDate(true);
    } else {
      this.props.addFilter('MONTH', value);
    }
  }

  hideDatePicker = () => {
    this.props.addFilter('CUSTOM_DATE', '');
    this.props.setIsSelectingCustomDate(false);
  }

  onCustomDateChange = (event, { value }) => {
    this.props.addFilter('CUSTOM_DATE', value);
  }

  onSearchChange = (event, { value }) => {
    this.props.addFilter('SEARCH', value);
  }

  onCategoryChange = (event, { value }) => this.props.addFilter('CATEGORIES', value);

  onAccountNameChange = (event, { value }) => this.props.addFilter('ACCOUNTNAME', value);

  onDuplicateStatusChange = (event, { checked }) => this.props.addFilter('DUPLICATE_STATUS', checked);

  render() {
    return (
      <Filters
        onTabChange={this.onTabChange}
        onAmountChange={this.onAmountChange}
        onAmountBlur={this.onAmountBlur}
        onMonthChange={this.onMonthChange}
        onCustomDateChange={this.onCustomDateChange}
        hideDatePicker={this.hideDatePicker}
        onSearchChange={this.onSearchChange}
        onCategoryChange={this.onCategoryChange}
        onAccountNameChange={this.onAccountNameChange}
        onDuplicateStatusChange={this.onDuplicateStatusChange}
        handleCreateTransaction={this.props.handleCreateTransaction}
        filters={this.props.filters}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  filters: state.filters,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  addFilter, setIsSelectingCustomDate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsFilters);
