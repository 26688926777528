import uuidv4 from "uuid/v4";
import apiRequestV2, { baseApiV2Path } from "libraries/api-v2/api";

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

const postClassifications = (classificationUpdate) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/update-classifications`, {
    body: classificationUpdate,
  });

export const getTransactionsAsProfessional = (
  username,
  token,
  clientEmail,
  offset = 0
) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/as-professional`, {
    body: {
      clientEmail,
      offset,
    },
  }).then((res) => res.data.transactions);

export const getTransactions = (username, offset = 0) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/get_transactions`, {
    body: {
      username,
      offset,
    },
  }).then((res) => res.data.transactions);

export const getTransactionsWithFilter = (
  filter,
  limit = 20,
  offset = 0,
  clientEmail = null
) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/list`, {
    body: {
      filter,
      limit,
      offset,
      clientEmail,
    },
  }).then((res) => res.data);

export const getTransactionIdsWithFilter = (filter, clientEmail) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/ids`, {
    body: {
      filter,
      clientEmail,
    },
  }).then((res) => res.data);

/**
 * Marks transactions as seen by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {string} clientEmail The email of the client
 * @param {string[]} transactionIds A list of transaction ids to be marked as seen.
 */
export const markAsSeenAsProfessional = (
  username,
  token,
  clientEmail,
  transactionIds
) =>
  apiRequestV2(
    "put",
    `${baseApiV2Path}/transactions/mark-as-seen/as-professional`,
    {
      body: {
        clientEmail,
        transactionIds,
      },
    }
  );

export const updateDuplicateStatusAsProfessional = (
  clientEmail,
  transactionIds,
  duplicateStatus
) =>
  apiRequestV2(
    "put",
    `${baseApiV2Path}/transactions/update-duplicate-status/as-professional`,
    {
      body: {
        clientEmail,
        transactionIds,
        duplicateStatus,
      },
    }
  );

export const getProfitAndLossAsProfessional = (
  username,
  token,
  clientEmail,
  startDate,
  endDate,
  tags = []
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/get-profit-and-loss/as-professional`,
    {
      body: {
        clientEmail,
        startDate,
        endDate,
        tags,
      },
    }
  );

/**
 * Creates a manual transaction by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const createTransactionAsProfessional = (username, token, payload) => {
  const {
    clientEmail,
    transactionType,
    name,
    category,
    subcategory,
    amount,
    date,
    selectedTags,
    memo,
  } = payload;
  return apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/create-transaction/as-professional`,
    {
      body: {
        clientEmail,
        transaction_type: transactionType.toLowerCase(),
        transaction_name: name,
        category,
        subcategory,
        amount,
        date,
        selectedTags,
        memo,
      },
    }
  );
};

/**
 * Updates a manual transaction by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const updateTransactionAsProfessional = (username, token, payload) => {
  const {
    clientEmail,
    transactionID,
    customDate,
    name,
    transactionType,
    amount,
    category,
    subcategory,
    selectedTags,
    memo,
  } = payload;

  return apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/edit-manual-transaction/as-professional`,
    {
      body: {
        clientEmail,
        transaction_id: transactionID,
        date: customDate,
        description: name,
        transaction_type: transactionType.toLowerCase(),
        amount,
        category,
        subcategory,
        selectedTags,
        memo,
      },
    }
  );
};

/**
 * Deletes a manual transaction
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const deleteTransactionAsProfessional = (username, token, payload) => {
  const { clientEmail, transactionId } = payload;

  return apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/delete-transaction/as-professional`,
    {
      body: {
        clientEmail,
        transaction_id: transactionId,
      },
    }
  );
};

export const updateTransactionClassificationsAsProfessional = (
  username,
  token,
  { clientEmail, transactionIds, category, subcategory, selectedTags, memo }
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/update-classifications/as-professional`,
    {
      body: {
        clientEmail,
        transactionIds,
        category,
        subcategory,
        selectedTags,
        memo,
      },
    }
  );

/**
 * V2 wrapper for backwards compatibility
 * Note: username and token are not needed
 */
export const updateClassificationsV2 = (
  username,
  token,
  transactionIds,
  category,
  subcategory,
  selectedTags,
  memo
) => {
  const classificationUpdate = {
    transaction_ids: transactionIds,
    category,
    subcategory,
    selectedTags,
    memo,
  };

  return postClassifications(classificationUpdate);
};

export const exportPNLTransactionsAsProfessional = (
  username,
  token,
  clientEmail,
  transactionIds,
  transactionType
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/export-as-xlsx/as-professional`,
    {
      body: {
        clientEmail,
        transactionIds,
        transactionType,
      },
    }
  );

export const exportPNLPdfAsProfessional = (
  username,
  token,
  clientEmail,
  startDate,
  endDate
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/profit-and-loss/export-as-pdf/as-professional`,
    {
      body: {
        clientEmail,
        startDate,
        endDate,
      },
    }
  );

export const markAsSeen = (transactionIds) =>
  apiRequestV2("put", `${baseApiV2Path}/transactions/mark-as-seen`, {
    body: {
      transactionIds,
    },
  });

export const updateDuplicateStatus = (transactionIds, duplicateStatus) =>
  apiRequestV2("put", `${baseApiV2Path}/transactions/update-duplicate-status`, {
    body: {
      transactionIds,
      duplicateStatus,
    },
  });

export const exportPnlMonthXLSX = (
  startDate,
  endDate,
  includingRemainingBalance
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/export-profit-and-loss/month-to-month`,
    {
      body: {
        startDate,
        endDate,
        remainBalance: includingRemainingBalance,
      },
    }
  ).then((res) => res.data);

export const getProfitAndLossMonth = (startDate, endDate) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/profit-and-loss/month-to-month`,
    {
      body: {
        startDate,
        endDate,
      },
    }
  ).then((res) => res.data);

export const getProfitAndLossMonthResult = (id) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/profit-and-loss/month-to-month/result`,
    {
      body: {
        id,
      },
    }
  ).then((res) => res.data);

export const exportPnlMonthXLSXProfessional = (
  clientEmail,
  clientFullName,
  startDate,
  endDate,
  includingRemainingBalance
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/export-profit-and-loss/month-to-month/as-professional`,
    {
      body: {
        clientEmail,
        clientFullName,
        startDate,
        endDate,
        remainBalance: includingRemainingBalance,
      },
    }
  ).then((res) => res.data);

export const getProfitAndLossMonthProfessional = (
  clientEmail,
  startDate,
  endDate
) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/profit-and-loss/month-to-month/as-professional`,
    {
      body: {
        clientEmail,
        startDate,
        endDate,
      },
    }
  ).then((res) => res.data);

export const getTransactionTagList = () =>
  apiRequestV2(
    "get",
    `${baseApiV2Path}/transactions/transaction_tag_list`
  ).then((res) => res.data);

export const getTransactionTagListAsProfessional = (clientEmail) =>
  apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/transaction_tag_list/as-professional`,
    {
      body: { clientEmail },
    }
  ).then((res) => res.data);

export const getTransactionSubcategories = (email) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/subcategories`, {
    body: {
      email,
    },
  }).then((res) => res.data);

export const createTransaction = (username, token, information) => {
  const {
    transactionType,
    name,
    category,
    subcategory,
    amount,
    customDate,
    selectedTags,
    memo,
  } = information;
  return apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/create_transaction`,
    {
      body: {
        transaction_type: transactionType.toLowerCase(),
        transaction_name: name,
        category,
        subcategory,
        amount,
        date: customDate,
        selectedTags,
        memo,
      },
    }
  );
};

export const deleteTransaction = (username, token, transactionID) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/delete_transaction`, {
    body: { transaction_id: transactionID },
  });

export const editTransaction = (username, token, information) => {
  const {
    transactionID,
    customDate,
    name,
    transactionType,
    amount,
    category,
    subcategory,
    selectedTags,
    memo,
  } = information;
  return apiRequestV2(
    "post",
    `${baseApiV2Path}/transactions/edit_manual_transaction`,
    {
      body: {
        transaction_id: transactionID,
        date: customDate,
        description: name,
        transaction_type: transactionType.toLowerCase(),
        amount,
        category,
        subcategory,
        selectedTags,
        memo,
      },
    }
  );
};

export const getProfitAndLoss = (
  username,
  token,
  startDate,
  endDate,
  tags = []
) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/get_profit_and_loss`, {
    body: { startDate, endDate, tags },
  }).then((response) => {
    const transactions = response.data.profit_and_loss.transactions.map(
      (transaction) => ({
        ...transaction,
        items: transaction.items.map((item) => ({ ...item, id: uuidv4() })),
      })
    );
    return {
      net_income: response.data.profit_and_loss.net_income,
      transactions,
    };
  });

export const exportTransactionData = (params) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/export-data`, {
    body: { params },
  }).then((res) => res.data);

export const getExportTransactionResult = (id) =>
  apiRequestV2("post", `${baseApiV2Path}/transactions/export-data/result`, {
    body: { id },
  }).then((res) => res.data);

export default {
  getTransactionsAsProfessional,
  getTransactions,
  getTransactionsWithFilter,
  updateClassificationsV2,
  markAsSeenAsProfessional,
  markAsSeen,
  createTransactionAsProfessional,
  deleteTransactionAsProfessional,
  updateTransactionAsProfessional,
  updateTransactionClassificationsAsProfessional,
  exportPNLTransactionsAsProfessional,
  exportPNLPdfAsProfessional,
  exportPnlMonthXLSX,
  exportPnlMonthXLSXProfessional,
  getProfitAndLossMonthProfessional,
  getTransactionTagList,
  getTransactionTagListAsProfessional,
  getTransactionSubcategories,
  createTransaction,
  editTransaction,
  exportTransactionData,
  getExportTransactionResult,
  updateDuplicateStatus,
  updateDuplicateStatusAsProfessional,
};
