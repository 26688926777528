import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

export const getProfile = () => apiRequestV2('get', `${basePath}/users/get_profile`).then(res => res.data);

export const updateProfile = (body) => apiRequestV2('post', `${basePath}/users/update_profile`, { body }).then(res => res.data);

export const deleteUserAccount = () => apiRequestV2('del', `${basePath}/users/delete_user_account`);

export const getAvatarUploadUrl = (body) => apiRequestV2('post', `${basePath}/users/avatar/upload_url`, { body }).then(res => res.data);

export const deleteAvatar = (body) => apiRequestV2('del', `${basePath}/users/avatar`, { body }).then(res => res.data);
